import React from "react";

export default class ShapesToolBox extends React.Component {
  render() {
    return <category name="shapes">
      <block type="svg_simple_square">
        <value name="size">
          <block type="math_number">
            <field name="NUM">40</field>
          </block>
        </value>
        <value name="color">
          <block type="deprecated_color_picker">
            <field name="field_colour">#1570d6</field>
          </block>
        </value>
      </block>
      <block type="svg_simple_circle">
        <value name="radius">
          <block type="math_number">
            <field name="NUM">40</field>
          </block>
        </value>
        <value name="color">
          <block type="deprecated_color_picker">
            <field name="field_colour">#ec4e62</field>
          </block>
        </value>
      </block>
      <block type="svg_simple_rect">
        <value name="width">
          <block type="math_number">
            <field name="NUM">80</field>
          </block>
        </value>
        <value name="height">
          <block type="math_number">
            <field name="NUM">40</field>
          </block>
        </value>
        <value name="color">
          <block type="deprecated_color_picker">
            <field name="field_colour">#038541</field>
          </block>
        </value>
      </block>
      <block type="svg_simple_ellipse">
        <value name="rx">
          <block type="math_number">
            <field name="NUM">40</field>
          </block>
        </value>
        <value name="ry">
          <block type="math_number">
            <field name="NUM">16</field>
          </block>
        </value>
        <value name="color">
          <block type="deprecated_color_picker">
            <field name="field_colour">#d654c2</field>
          </block>
        </value>
      </block>

      <block type="svg_square_with_style">
        <value name="size">
          <block type="math_number">
            <field name="NUM">40</field>
          </block>
        </value>
        <value name="color">
          <block type="deprecated_color_picker">
            <field name="field_colour">#ec4e62</field>
          </block>
        </value>
      </block>
      <block type="svg_circle_with_style">
        <value name="radius">
          <block type="math_number">
            <field name="NUM">40</field>
          </block>
        </value>
        <value name="color">
          <block type="deprecated_color_picker">
            <field name="field_colour">#ec4e62</field>
          </block>
        </value>
      </block>
      <block type="svg_rect_with_style">
        <value name="width">
          <block type="math_number">
            <field name="NUM">40</field>
          </block>
        </value>
        <value name="height">
          <block type="math_number">
            <field name="NUM">80</field>
          </block>
        </value>
        <value name="color">
          <block type="deprecated_color_picker">
            <field name="field_colour">#048079</field>
          </block>
        </value>
      </block>
      <block type="svg_ellipse_with_style">
        <value name="rx">
          <block type="math_number">
            <field name="NUM">40</field>
          </block>
        </value>
        <value name="ry">
          <block type="math_number">
            <field name="NUM">64</field>
          </block>
        </value>
        <value name="color">
          <block type="deprecated_color_picker">
            <field name="field_colour">#6927b8</field>
          </block>
        </value>
      </block>
      <block type="html_div_with_style">
        <statement name="body">
          <block type="svg_simple_square">
            <value name="size">
              <block type="math_number">
                <field name="NUM">40</field>
              </block>
            </value>
            <value name="color">
              <block type="deprecated_color_picker">
                <field name="field_colour">#1570d6</field>
              </block>
            </value>
          </block>
        </statement>
        <statement name="style">
        </statement>
      </block>
    </category>
  }
}

import * as Blockly from "blockly/core";
import generateStyleCode from "../../generateStyleCode";

export function muiPaperImplementation(block) {
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  const properties = Blockly.JavaScript.statementToCode(block, 'properties');
  let code = `class PaperImplementation extends BaseComponent {`;
  code += `render() {`;
  code += `const self = this;`;
  code += `this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';`;
    code += generateStyleCode(block);
  code += `const StyledPaper = withStyles({root: style})(Paper);`;
  code += `return <StyledPaper ref={self.componentReference}`;
  code += ` display="flex" ${properties}>`;  // TODO(odedf) Add properties for Paper
  code += `${content}`;
  code += `</StyledPaper>`;
  code += `}}`;
  return code;
}

export function paperOutlined() {
  return ` variant="outlined"`;
}

export function paperSquare() {
  return ` square`;
}

export function paperElevation(block) {
  let elevation = Blockly.JavaScript.statementToCode(block, 'elevation');
  elevation = elevation ? elevation : 0;
  return ` elevation={${elevation}}`;
}

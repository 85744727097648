export default function customizedConsoleError() {
  // Bypass blockly tag warnings.
  const realError = console.error;
  console.error = (...x) => {
    const BLOCKLY_TAGS = ['block', 'category', 'field', 'mutation', 'next', 'sep', 'statement',
      'value'];
    if (x.length > 0 && typeof x[0] === 'string' &&
        x[0].startsWith('Warning: The tag <%s> is unrecognized in this browser.') &&
        BLOCKLY_TAGS.includes(x[1])) {
      return;
    }
    realError(...x);
  };
}
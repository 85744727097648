import Snippet from "../../common/Snippet";
import buildSnippet from "../../common/buildSnippet";
import getSnippet from "./getSnippet";
import generateSnippetUUID from "../../common/generateSnippetUUID";

export default function shallowCloneSnippet(srcSnippetUUID: string): Snippet {
  const srcSnippet: Snippet = getSnippet(srcSnippetUUID);
  const clone: Snippet = buildSnippet(generateSnippetUUID(),
      srcSnippet.type,
      srcSnippet.isContentGeneratedFromXml,
      srcSnippet.xml,
      srcSnippet.content,
      srcSnippet.name,
      srcSnippet.isPublic,
      srcSnippet.prerequisites);
  return clone;
}


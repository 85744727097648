import * as Blockly from "blockly/core";

export function styleImplementation(block) {
  let currentBlock = this.getInputTargetBlock('all_styles');
  let code = ``;
  while (currentBlock) {
    code += Blockly.JavaScript[currentBlock.type].call(currentBlock, currentBlock);
    currentBlock = currentBlock.getNextBlock();
  }
  return code;
}

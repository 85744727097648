import store from "../../store";
import Project from "../../common/Project";

export default function getProject(projectUUID: string): Project | null {
  if (projectUUID in store.getState().persistentState.userProjects)
    return store.getState().persistentState.userProjects[projectUUID];
  if (projectUUID in store.getState().persistentState.publicProjects)
    return store.getState().persistentState.publicProjects[projectUUID];
  return null;
}

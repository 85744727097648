const {v4: uuidv4} = require('uuid');

function isUpperCase(ch: string) {
  return (ch === ch.toUpperCase()) && (ch !== ch.toLowerCase());
}

export default function generateSnippetUUID() {
  let res = uuidv4().replace(/[^0-9a-z]/gi, '').toUpperCase();
  while (!isUpperCase(res.charAt(0)))
    res = uuidv4().replace(/[^0-9a-z]/gi, '').toUpperCase();
  return res;
}

import SnippetTypes from "./SnippetTypes";
import Snippet from "./Snippet";

export default function buildSnippet(snippetUUID: string,
                                     type: SnippetTypes,
                                     isContentGeneratedFromXml: boolean,
                                     xml: string,
                                     content: string,
                                     name: string,
                                     isPublic: boolean,
                                     prerequisites: Array<string>):
    Snippet {
  return {
    snippetUUID,
    type,
    isContentGeneratedFromXml,
    xml,
    content,
    prerequisites,
    isPublic,
    name,
  };
}

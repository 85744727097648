import getSnippet from "./getSnippet";
import Snippet from "../../common/Snippet";
import UTILS from "../../common/utils";

/** @function
 * @name getAllSnippetsTransitively
 * @param {string} rootSnippetUuid.
 * @description return all decedent snippetUUID sorted topologically.<br>
 *   Note - this functions assumes that the Snippet graph is a DAG.
 */
export default function getAllSnippetsTransitively(rootSnippetUuid: string) :
  Array<Snippet> {
  // Assumes that the graph is a DAG!!
  let sortedSnippets: Array<Snippet> = [];
  let visitedSnippetsUUID: Set<string> = new Set();
  let queue = [];
  queue.push(rootSnippetUuid);
  visitedSnippetsUUID.add(rootSnippetUuid);
  while (queue.length > 0) {
    let snippetUUID = queue[queue.length - 1];
    const snippet : Snippet = getSnippet(snippetUUID);
    UTILS.assert(snippet, snippetUUID);
    let neighbors: Set<string> = new Set(snippet.prerequisites);
    const unvisitedNeighbors: Set<string> = new Set(Array.from(neighbors).filter(
        x => !visitedSnippetsUUID.has(x)));
    if (unvisitedNeighbors.size > 0) {
      queue = [...queue, ...Array.from(unvisitedNeighbors)];
    } else {
      sortedSnippets.push(snippet);
      visitedSnippetsUUID.add(snippetUUID);
      queue.pop();
    }
  }
  sortedSnippets.reverse();
  return sortedSnippets;
}


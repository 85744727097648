import * as Blockly from "blockly/core";
import extractSnippetUUIDFromCode from "../../extractSnippetUUIDFromCode";

export function switchImplementation(block) {
  const label = Blockly.JavaScript.statementToCode(block, 'label');
  const properties = Blockly.JavaScript.statementToCode(block, 'properties');
  const varSnippetUUID = extractSnippetUUIDFromCode(
      Blockly.JavaScript.statementToCode(block, 'variable'));
  let code = "class SwitchImplementation extends BaseComponent {";
  code += "render() {";
  code += `const StyledSwitch = withStyles({
 colorPrimary: {color: "yellow"},
 colorSecondary: {
   color: 'green',
   '&$checked': {
          color: 'blue',
    },
    'track': {
      backgroundColor: 'blue'
    },
  },
  checked: {}})(Switch);`;
  code += `let label = context.evaluateFJSONExpressionWithRedux(${label});`;
  code += `let checked = true;`;
  code +=
      `if ("${varSnippetUUID}") {checked = context.evaluateDataSnippet("${varSnippetUUID}");};`;
  code += `return <FormControlLabel`;
  code += ` control={<StyledSwitch ${properties}/>} label={label} checked={checked}`;
  code +=
      ` onChange={(event) => {context.setDataSnippet("${varSnippetUUID}", event.target.checked);}}`;
  code += `/>`;
  code += `}}`;
  return code;
}

export function switchSmall(block) {
  return `size="small"`;
}

export function switchDisabled(block) {
  return `disabled `;
}

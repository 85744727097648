import {combineReducers} from "redux";
import alertReducer from "./alertReducer";
import controllerReducer from "./controllerReducer";
import maskerStateReducer from "./maskerStateReducer";
import persistentStateReducer from "./persistentStateReducer";
// @ts-ignore
import sessionStateReducer from "./sessionStateReducer.ts";
import setVariableValueReducer from "./setVariableValueReducer";
// @ts-ignore
import workAreaPageStateReducer from "./workAreaPageStateReducer.ts";
import aboutFormReducer from "./forms/aboutFormReducer";
import createProjectFormReducer from "./forms/createProjectFormReducer";
import modifyProjectFormReducer from "./forms/modifyProjectFormReducer";
import createVariableFormReducer from "./forms/createVariableFormReducer";
import loginFormReducer from "./forms/loginFormReducer";
import unlockSnippetReducer from "./forms/unlockSnippetReducer";
import helpFormReducer from "./forms/helpFormReducer";

/**
 * @namespace ReduxReducers
 */

/** @function
 * @memberof ReduxReducers
 * @name combinedReducer
 *
 * The single reduced function combining all child reducers.
 */
const combinedReducer = combineReducers({
  aboutForm: aboutFormReducer,
  helpForm: helpFormReducer,
  allVariables: setVariableValueReducer,
  arenaAlertOptions: alertReducer,
  arenaController: controllerReducer,
  createProjectForm: createProjectFormReducer,
  modifyProjectForm: modifyProjectFormReducer,
  createVariableForm: createVariableFormReducer,
  unlockSnippetState: unlockSnippetReducer,
  loginForm: loginFormReducer,
  maskerState: maskerStateReducer,
  persistentState: persistentStateReducer,
  sessionState: sessionStateReducer,
  workAreaPageState: workAreaPageStateReducer,
});

export default combinedReducer;

import store from "../../store";
import {updateWorkAreaPageStateFromSnippet} from "../actions/workAreaPageStateActions";
import UTILS from "../../common/utils";

// eslint-disable-next-line
export function* pushSnippetUUIDSaga(action : any) {
  const workspaceInitialized = store.getState().workAreaPageState.workspaceInitialized;
  if (workspaceInitialized) {
    const snippet = store.getState().persistentState.allSnippets[action.payload.snippetUUID];
    UTILS.assert(snippet, `UUID: ${action.payload.snippetUUID}`);
    store.dispatch(updateWorkAreaPageStateFromSnippet(snippet));
  }
}

// eslint-disable-next-line
export function* updateLastSnippetInStackSaga() {
  const snippetUUIDStack = store.getState().workAreaPageState.snippetUUIDStack;
  const snippetUUIDStackEnd = store.getState().workAreaPageState.snippetUUIDStackEnd;
  if (snippetUUIDStackEnd === 0)
    return;
  UTILS.assert(snippetUUIDStackEnd > 0, snippetUUIDStackEnd);
  const snippetUUID = snippetUUIDStack[snippetUUIDStackEnd - 1];
  const snippet = store.getState().persistentState.allSnippets[snippetUUID];
  store.dispatch(updateWorkAreaPageStateFromSnippet(snippet));
}

// eslint-disable-next-line
export function* updateWorkArea() {
  const snippetUUIDStack = store.getState().workAreaPageState.snippetUUIDStack;
  const snippetUUIDStackEnd = store.getState().workAreaPageState.snippetUUIDStackEnd;
  const workspaceInitialized = store.getState().workAreaPageState.workspaceInitialized;
  if (workspaceInitialized && snippetUUIDStackEnd > 0) {
    const snippetUUID = snippetUUIDStack[snippetUUIDStackEnd - 1];
    const snippet = store.getState().persistentState.allSnippets[snippetUUID];
    store.dispatch(updateWorkAreaPageStateFromSnippet(snippet));
  }
}

import ColorPalette from "./ColorPalette";

const CLASSIC_COLOR: ColorPalette = {
  UI: "#CB323F",
  DATA: "#3453E2",
  ACTION: "#218F68",
  HTML: "#BDBDBD",
  STYLE: "#FFD423",
  LAYOUT_PROPERTIES: '#C44E01',
  EXAMPLES: "#D754C2",
  GREY: "#757575",
  DISABLED: "#E0E0E0"
};

const CONSTANTS = {
  BACKEND_URL: 'http://ec2-54-149-55-81.us-west-2.compute.amazonaws.com',
  BLOCK_ENDPOINT: 'declaration_snippet',

  // MISC
  DEVELOPMENT: true,
  ENABLE_BLOCK_SELECTION: true,
  JS_BEAUTIFY: false,
  JS_PRETTIER: true,
  FILTER_IDS: true,
  BLOCKLY_LOADING_TIMEOUT_MS: 300,
  SNIPPET_EDITOR_WIDTH_PERCENT: 50,
  DRWAER_WIDTH: 340,
  // WORDING
  MESSAGES: {
    ENTER_BLOCK_NAME: "Enter block name",
    EMPTY_PROJECT_NAME: "Empty project name",
    ENTER_PROJECT_NAME: "Enter project name",
  },
  TEMPLATE_SNIPPET_UUID: [
    'ACTION_SNIPPET_TEMPLATE',
    'ALERT_TEMPLATE',
    'BACKUP_DATA_TEMPLATE',
    'BOX_TEMPLATE',
    'BUTTON_TEMPLATE',
    'CHECKBOX_TEMPLATE',
    'CARD_TEMPLATE',
    'CLEAR_ARRAY_TEMPLATE',
    'DATA_GRID_TEMPLATE',
    'DATA ARRAY_TEMPLATE',
    'DATA_BOOL_TEMPLATE',
    'DATA_NUMBER_TEMPLATE',
    'DATA OBJECT_TEMPLATE',
    'EF2CDE10FF9E4A8B8D5C3A1980EE30C6',
    'EF2CDE10FF9E4A8B8D5C3A1980EE30C7',
    'EF2CDE10FF9E4A8B8D5C3A1980EE30C8',
    'EF2CDE10FF9E4A8B8D5C3A1980EE30C9',
    'EF2CDE10FF9E4A8B8D5C3A1980EE30CA',
    'FAB_TEMPLATE',
    'FILTER_ARRAY_TEMPLATE',
    'GENERATE_UUID_TEMPLATE',
    'GMAIL_SEND_TEMPLATE',
    'GRID_TEMPLATE',
    'HTTP_GET_TEMPLATE',
    'HTTP_RETRIEVE_ALERT',
    'IMAGE_TEMPLATE',
    'LINK_TEMPLATE',
    'PAPER_TEMPLATE',
    'PUSH_TO_ARRAY_TEMPLATE',
    'RADIO_TEMPLATE',
    'SLIDER_TEMPLATE',
    'SWITCH_TEMPLATE',
    'SET_VAR_TEMPLATE',
    'STYLE_SNIPPET_TEMPLATE',
    'TABS_TEMPLATE',
    'TEXT_FIELD_TEMPLATE',
    'TEXT_EDITOR_TEMPLATE',
    'TYPOGRAPHY_TEMPLATE',
    'STYLED_TYPOGRAPHY_TEMPLATE',
    // ACTIONS
    'PARSE_CODEX_CSV_TEMPLATE',
    'CREATE_ARRAY_OF_MAPS_TEMPLATE',
  ],

  CLASSIC_COLOR,
  // SCOPES
  SCOPES: {
    DOCUMENTS_READONLY: "https://www.googleapis.com/auth/documents.readonly",
    GMAIL_SEND: "https://www.googleapis.com/auth/gmail.send",
    GMAIL_COMPOSE: "https://www.googleapis.com/auth/gmail.compose",
    DRIVE: "https://www.googleapis.com/auth/drive",
    DRIVE_FILE: "https://www.googleapis.com/auth/drive.file",
    FAKE: "FAKE"
  }
};

export default CONSTANTS;

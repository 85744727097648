import BlocklyJS from "blockly/javascript";
import * as Blockly from "blockly/core";
import UTILS from "../../common/utils";

const ROOT_SUFFIX = '_IMPL';
const DEPRECATED_ROOT_SUFFIX = '_implementation';

export default function generateContentFromWorkspace(workspace) {
  UTILS.assert(typeof workspace == 'object');
  let xml = Blockly.Xml.workspaceToDom(workspace);
  // Remove floating blocks that are not children of a root.
  for (var i = xml.childNodes.length - 1; i >= 0; i--) {
    const block = xml.childNodes[i];
    const blockType = block.getAttribute('type');
    // TODO(odedf): figure out a better way to do this!!!
    if (!blockType.endsWith(ROOT_SUFFIX) && !blockType.endsWith(DEPRECATED_ROOT_SUFFIX)) {
      xml.removeChild(block);
    }
  }
  let newWs = new Blockly.Workspace();
  Blockly.Xml.domToWorkspace(xml, newWs);
  return BlocklyJS.workspaceToCode(newWs);
}

import React from "react";
import ToolboxCategory from "../../../common/ToolboxCategory";
import store from "../../../store";
import { Block } from "../../../Blockly";
import UTILS from "../../../common/utils";
import FlexBoxToolBox from "../style/FelxBoxToolBox";

export default class MaterialUIToolBox extends React.Component {
  render() {
    const sessionState = store.getState().sessionState;
    const isProductionSession = sessionState.isProductionSession;
    return <category name="UI" colour={UTILS.getColorPalette().UI}>
      <category name="Layout" toolboxitemid={ToolboxCategory.LAYOUT}>
        <category name="Grid properties">
          <block type="mui_grid_item_lean" />
          <block type="mui_grid_item_detailed" />
          <block type="flex_direction" />
          <block type="flex_grow">
            <statement name="value">
              <block type="data_primitive_number">
                <field name="value">2</field>
              </block>
            </statement>
          </block>
          <block type="flex_justify_content" />
          <block type="flex_align_content" />
          <block type="flex_align_items" />
          <block type="flex_align_self" />
        </category>
        <FlexBoxToolBox />
      </category>
      <category name="Inputs" toolboxitemid={ToolboxCategory.MUI_INPUTS}>
        <category name="Button">
          <block type="start_icon_property" />
          <block type="end_icon_property" />
          <block type="mui_button_variant_property" />
        </category>
        <category name="Radio">
          <block type="radio_button_label" />
        </category>
        <category name="Switch">
          <block type="switch_disabled" />
          <block type="switch_small" />
        </category>
        <category name="Slider">
          <block type="slider_disabled" />
          <block type="slider_marks" />
          <block type="slider_range" />
          <block type="slider_step" />
          <block type="slider_defaultValue" />
        </category>
        <category name="Text Field" toolboxitemid={ToolboxCategory.MUI_TEXT_FIELD}>
          <block type="mui_textfield_property_variant" />
          <block type="mui_textfield_property_size" />
        </category>
        <category name="Check Box">
          <block type="mui_checkbox_size_property" />
          <block type="mui_checkbox_size_property_value" />
          <block type="mui_checkbox_disabled" />
        </category>
        {isProductionSession ? '' : (
          <category name="development">
            <category name="Text Editor" toolboxitemid={ToolboxCategory.MUI_TEXT_EDITOR}>
              <category name="Toggle Button Group">
                <block type="toggle_button_group_mutually_exclusive" />
                <block type="toggle_button_full_width" />
                <block type="toggle_button_disabled" />
              </category>
            </category>
          </category>)}
      </category>
      <category name="Navigation" toolboxitemid={ToolboxCategory.MUI_NAVIGATION}>
        <category name="Link" toolboxitemid={ToolboxCategory.MUI_LINK} />
        <category name="Tabs" toolboxitemid={ToolboxCategory.MUI_TABS}>
          <block type="mui_tab">
            <statement name="label">
              <block type="string_block">
                <field name="content">First Label</field>
              </block>
            </statement>
            <statement name="content">
              <block type="string_block">
                <field name="content">First Content</field>
              </block>
            </statement>
            <next>
              <block type="mui_tab">
                <statement name="label">
                  <block type="string_block">
                    <field name="content">Second Label</field>
                  </block>
                </statement>
                <statement name="content">
                  <block type="string_block">
                    <field name="content">Second Content</field>
                  </block>
                </statement>
              </block>
            </next>
          </block>
        </category>
      </category>
      <category name="Surfaces" toolboxitemid={ToolboxCategory.MUI_SURFACES}>
        {isProductionSession ? '' :
          <category name="development">
            <Block type="html_br" />
            <category name="App Bar" />
          </category>
        }
        <category name="Paper properties">
          <block type="paper_elevation">
            <statement name="elevation">
              <block type="data_primitive_number">
                <field name="value">20</field>
              </block>
            </statement>
          </block>
          <block type="paper_outlined" />
          <block type="paper_square" />
        </category>
      </category>
      {isProductionSession ? '' : (
        <category name="Feedback">
          <category name="Dialog">
            <category name="Properties">
              <block type="mui_dialog_property_is_open">
                <statement name="is_open">
                  <block type="data_primitive_true"></block>
                </statement>
              </block>
            </category>
          </category>
        </category>
      )}

      <category name="Data display" toolboxitemid={ToolboxCategory.MUI_DATA_DISPLAY}>
        {isProductionSession ? '' : (
          <category name="Styled Typography"
            toolboxitemid={ToolboxCategory.UI_STYLED_TYPOGRAPHY}>
          </category>
        )}
        {isProductionSession ? '' : (
          <category name="Image" toolboxitemid={ToolboxCategory.MUI_IMAGE} />
        )}
      </category>

      {isProductionSession ? '' : (
        <category name="Lab">
        </category>
      )}
      <category name="Data Grid" toolboxitemid={ToolboxCategory.DATA_GRID}>
        <category name="Properties">
          <block type="mui_data_grid_column">
            <statement name="name">
              <block type="string_block">
                <field name="content">First Name</field>
              </block>
            </statement>
          </block>
          <block type="mui_data_grid_column_detailed">
            <statement name="name">
              <block type="string_block">
                <field name="content">First Name</field>
              </block>
            </statement>
          </block>
          <block type="mui_data_grid_row_height_property"/>
        </category>
      </category>
    </category>
  }
};
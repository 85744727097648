import * as Blockly from "blockly/core";

const ContextMenuItems = {

  shallowCloneBlock(callback : any, preconditionFn: any) {
    return {
      displayText: 'Shallow Clone',
      preconditionFn: function (scope : any) {
        return preconditionFn(scope);
      },
      callback: function (scope : any) {
        callback(scope.block);
      },
      scopeType: Blockly.ContextMenuRegistry.ScopeType.BLOCK,
      id: 'shalow_clone_block',
      weight: 100,
    }
  },

  deepCloneBlock(callback : any, preconditionFn: any) {
    return {
      displayText: 'Deep Clone',
      preconditionFn: function (scope : any) {
        return preconditionFn(scope);
      },
      callback: function (scope : any) {
        callback(scope.block);
      },
      scopeType: Blockly.ContextMenuRegistry.ScopeType.BLOCK,
      id: 'deep_clone_block',
      weight: 100,
    }
  }
};

export default ContextMenuItems;
import Snippet from "../common/Snippet";

export default interface BackendSnippet {
  snippet_uuid: string,
  name: string,
  prerequisites: Array<string>,
  type: string,
  content: string,
  xml: string,
  is_content_generated_from_xml: boolean,
  is_public: boolean
}

export function snippetToData(snippet: Snippet): BackendSnippet {
  return {
    snippet_uuid: snippet.snippetUUID,
    name: snippet.name,
    prerequisites: snippet.prerequisites,
    type: snippet.type,
    content: snippet.content,
    xml: snippet.xml,
    is_content_generated_from_xml: snippet.isContentGeneratedFromXml,
    is_public: snippet.isPublic,
  }
}

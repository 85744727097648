import {AlertOptions} from '../reducers/alertReducer';
import ReduxAction from "./ReduxAction";

/**
 * @namespace ReduxActions
 */

export const setWorkAreaPageStateIsDrawerOpen = (isDrawerOpen : boolean) => ({
  type: "SET_WORK_AREA_PAGE_STATE_IS_DRAWER_OPEN",
  payload: {isDrawerOpen}
});

// @ts-ignore
export const setUserEditorState = (editorName : string, state) => {
  return {
    type: "SET_USER_EDITOR_STATE",
    payload: {editorName, state}
  }
};

interface ReduxAlertActionPayload {
  /** arenaAlertOptions */
  arenaAlertOptions: AlertOptions;
}

export interface ReduxAlertAction extends ReduxAction {
  payload: ReduxAlertActionPayload;
}

export const setAlert = (arenaAlertOptions : AlertOptions) => ({
  type: "SET_ARENA_ALERT_OPTIONS",
  payload: {arenaAlertOptions}
});

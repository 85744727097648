import React from 'react';

import { connect } from "react-redux";
import Box from '@material-ui/core/Box';
import { withStyles } from "@material-ui/core";
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography";
import { clearLoginForm, setLoginForm } from
  "../../redux/actions/forms/loginFormActions";
import { setIsDeveloper, setIsLoggedIn }
  from "../../redux/actions/sessionStateActions";
import loadAllProjectsFromBackend from "../../server_interface/loadAllProjectsFromBackend";
import { googleLogin } from "../../server_interface/login";
import store from "../../store";
import UTILS from "../../common/utils";

const useStyles = () => ({
  inline: {
    display: 'inline',
  },
});

// Responsible for setting the redux state that is associated with this form.
// On crate calls the callback this.props.createActionBlock.
class LoginForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      formError: false,
    };
    const keypressHandler = (e) => {
      if (this.props.isOpen) {
        this.props.setLoginForm({ isOpen: false });
      }
    };
    document.addEventListener('keydown', keypressHandler);
  }

  static mapStateToProps(reduxState) {
    return {
      loginForm: reduxState.loginForm,
      isOpen: reduxState.loginForm.isOpen,
      isLoggedIn: reduxState.sessionState.isLoggedIn
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      setLoginForm: (loginForm) => {
        dispatch(setLoginForm(loginForm));
      },
      clearLoginForm: () => {
        dispatch(clearLoginForm());
      },
      setIsDeveloper: (isDeveloper) => {
        dispatch(setIsDeveloper(isDeveloper));
      },
    }
  }

  async componentDidMount() {
    /* global google */
    UTILS.assert(google);
    UTILS.assert(process.env);
    UTILS.assert(process.env.REACT_APP_GGL_CLIENT_ID);
    google.accounts.id.initialize({
      client_id: process.env.REACT_APP_GGL_CLIENT_ID,
      callback: handleGoogleLogin
    });
    google.accounts.id.renderButton(document.getElementById('singInDiv'),
      { theme: 'outline', size: 'large' });
  }

  render() {
    let self = this;
    let containerStyle = this.props.isOpen ? {
      position: 'absolute',
      top: '0px',
      left: '0px',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: '#fffe',
      zIndex: 1,
    } :
      {
        width: '0%'
      };
    return <div
      onClick={() => self.props.setLoginForm({ isOpen: false })}
      hidden={!this.props.isOpen}
      onKeyPress={(e) => {
        console.log(e.key);
      }}
      style={containerStyle}>
      <Box sx={{
        mt: '200px',
        p: '40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#f8f8f8',
        border: '1px solid #999',
        borderRadius: 2,
        zIndex: 1,
      }}
        onClick={(event) => event.stopPropagation()}
      >
        <Typography variant='h4'>Sign in to Coding Arena</Typography>
        <Box sx={{
          mt: '20px',
          mb: '20px',
        }}>
          <Typography variant='h6'>Get a free account, no credit card required</Typography>
        </Box>
        <div id='singInDiv'></div>
        <Typography variant='caption'>
          <Box sx={{
            mt: '20px',
          }}>By continuing, you agree to the Coding Arena</Box>
        </Typography>
        <Box sx={{
          mt: '20px',
        }}>
          <Typography variant='caption'>
            <Link href="https://sites.google.com/thecodingarena.com/privacy-policy" target="_blank">
              {'Privacy Policy '}
            </Link>
            and
            <Link href="https://sites.google.com/thecodingarena.com/terms-of-usage" target="_blank">
              {' Terms Of Use'}
            </Link>
          </Typography>
        </Box>
      </Box>
    </div>
  }
}

async function handleGoogleLogin(response) {
  const loginResponse = await googleLogin(response.credential);  // The ID token
  localStorage.setItem('IDToken', response.credential);
  store.dispatch(setIsLoggedIn(loginResponse.is_logged_in));
  store.dispatch(setIsDeveloper(loginResponse.is_admin));
  loadAllProjectsFromBackend();
  store.dispatch(setLoginForm({ isOpen: false }));
}

export default connect(LoginForm.mapStateToProps,
  LoginForm.mapDispatchToProps)(withStyles(useStyles)(LoginForm));

import Snippet from "./Snippet";
import SnippetTypes from "./SnippetTypes";

/** @function
   * @name createContentBasedDataSnippet
   * @description create a Data Snippet where content is not generated from xml
   */
export default function createContentBasedDataSnippet(snippetUUID: string, content: string): Snippet {
    return {
        snippetUUID,
        type: SnippetTypes.DATA,
        isContentGeneratedFromXml: false,
        xml: '',
        content: content,  // content is either an empty string or a function
        prerequisites: [],
        isPublic: false,
        name: snippetUUID
    };
}

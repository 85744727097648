import * as Blockly from "blockly/core";
import extractSnippetUUIDFromCode from '../../extractSnippetUUIDFromCode';
import React from "react";
import generateStyleCode from "../../generateStyleCode";

export function checkboxImplementation(block) {
  let varName = Blockly.JavaScript.statementToCode(block, 'variable');
  const properties = Blockly.JavaScript.statementToCode(block, 'properties');
  const label = Blockly.JavaScript.statementToCode(block, 'label');
  const varSnippetUUID = extractSnippetUUIDFromCode(varName);
  let code = `class CheckboxImplementation extends BaseComponent {`;
  code += `render() {`;
  code += `let checked = true;`;
  code += `if ("${varSnippetUUID}") {`;
  code += `checked = context.evaluateDataSnippet("${varSnippetUUID}");`;
  code += `}`;
  code += generateStyleCode(block);
  code += `const color = style.color ||  '#3453E2';`;
  code += `const backgroundColor = style.backgroundColor ||  '#3453E2';`;
  code += `delete style["color"];`;
  code += `delete style["backgroundColor"];`;
  code += `const StyledCheckbox = withStyles({`;
  code += `colorSecondary: { color: backgroundColor, '&$checked': { color}},`;
  code += `checked: {},`;
  code += `root: style`;
  code += `  })(Checkbox);`;
  code += `return <FormControlLabel `;
  code += ` control={<StyledCheckbox `;
  if (varSnippetUUID) {
    code +=
        ` onChange={(event) => {context.setDataSnippet("${varSnippetUUID}", event.target.checked);}}`;
  }
  code += ` checked={checked}`;
  code += ` ${properties}`;
  code += ` />}`;
  code += `label={context.evaluateFJSONExpressionWithRedux(${label})} />`;
  code += `}}`;
  return code;
}

export function checkboxSizeProperty(block) {
  // TODO: Support evaluateFJSONExpressionWithRedux
  let size = Blockly.JavaScript.statementToCode(block, 'size');
  size = size ? size : 'medium';
  return size ? ` size={${size}}` : ``;
}

export function checkboxSizePropertyValue(block) {
  return `"${block.getFieldValue('size')}" `;
}

export function checkboxDisabled(block) {
  // TODO: Support evaluateFJSONExpressionWithRedux.
  let disabled = Blockly.JavaScript.statementToCode(block, 'disabled');
  disabled = disabled ? JSON.parse(disabled) : false;
  return ` disabled={${disabled}}`;
}



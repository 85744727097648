import React from "react";

export default class SizeToolBox extends React.Component {
  render() {
    return <category name="Fit and size">
      <block type="style_zIndex"/>
      <block type="style_width">
        <field name="units">%</field>
        <statement name="value">
          <block type="data_primitive_number">
            <field name="value">100</field>
          </block>
        </statement>
      </block>
      <block type="style_height">
        <field name="units">%</field>
        <statement name="value">
          <block type="data_primitive_number">
            <field name="value">100</field>
          </block>
        </statement>
      </block>
      <block type="style_min_width">
        <field name="units">%</field>
        <statement name="value">
          <block type="data_primitive_number">
            <field name="value">50</field>
          </block>
        </statement>
      </block>
      <block type="style_min_height">
        <field name="units">%</field>
        <statement name="value">
          <block type="data_primitive_number">
            <field name="value">50</field>
          </block>
        </statement>
      </block>
      <block type="style_overflow"/>
      <block type="style_text_align"/>
      <block type="style_object_fit"/>
    </category>;
  }
}
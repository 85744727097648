import React from "react";
import ToolboxCategory from "../../../common/ToolboxCategory";
import store from "../../../store";

class TextToolBox extends React.Component {
  render() {
    const sessionState = store.getState().sessionState;
    const isProductionSession = sessionState.isProductionSession;
    return <category name="Text" toolboxitemid={ToolboxCategory.TEXT_ACTION}>
      <block type="generate_uuid"/>
      {isProductionSession ? '' : (
          <block type="transform_text_action"/>
      )}
      {isProductionSession ? '' : (
          <block type="toString_action"/>
      )}
      {isProductionSession ? '' : (
          <block type="print"/>
      )}
    </category>
  }
}

export default TextToolBox;

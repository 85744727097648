import * as Blockly from "blockly/core";

const BlocklyUtils = {
  insertBlockInstanceIntoWorkspace(workspace, type)  {
    const xml = Blockly.Xml.textToDom(
        `<xml xmlns="https://developers.google.com/blockly/xml">` +
        `<block type="${type}">` +
        `</block></xml>`);
    return Blockly.Xml.domToWorkspace(xml, workspace);
  }
};

export default BlocklyUtils;
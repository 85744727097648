const _ = require('lodash');

const initMaskerState = {
  allMasks: {},
  displayMasks: false,
  highlightComponentSnippetUuid: null
};

const maskerStateReducer = (maskerState = initMaskerState, action) => {
  switch (action.type) {
    case 'UPSERT_MASK':
      const newMasks = _.cloneDeep(maskerState.allMasks);
      newMasks[action.payload.snippetUUID] = {
        boundingBox: _.cloneDeep(action.payload.boundingBox),
        depth: action.payload.depth
      };
      return {
        ...maskerState,
        ...{
          allMasks: newMasks
        }
      };
    case 'SET_DISPLAY_MASKS':
      return {
        ...maskerState,
        ...{
          displayMasks: action.payload.value,
        }
      };
    case 'HIGHLIGHT_COMPONENT':
      return {
        ...maskerState,
        ...{
          highlightComponentSnippetUuid: action.payload.snippetUUID,
        }
      };
    case 'CLEAR_ALL_MASKS':
      return initMaskerState;
    default:
      return maskerState;
  }
};

export default maskerStateReducer;


import getBlocklyXml from "../../../../../Blockly/BlocklyLogic/getBlocklyXml";
import deepClone from "./deepClone";
import UTILS from "../../../../../common/utils";

export default async function cloneTemplateBlock(workspace: any,
                                                 templateBlock: any):
    Promise<string | null> {
  UTILS.assert(templateBlock !== null);
  const xmlWithTemplateBlock = getBlocklyXml(workspace);
  const templateBlockID = templateBlock.id;
  const templateSnippetUUID = templateBlock.type;
  const blockUUIDArray: Array<string> =
      await deepClone(templateBlock, workspace);
  const clonedBlockID = blockUUIDArray[0];
  const clonedBlock = workspace.getBlockById(clonedBlockID);
  UTILS.assert(clonedBlock);
  const clonedSnippetUUID = clonedBlock.type;
  const xmlWithClonedBlock = xmlWithTemplateBlock.replace(
      templateBlockID, clonedBlockID).replace(
      templateSnippetUUID, clonedSnippetUUID);
  workspace.clear();
  UTILS.xmlToWorkspace(workspace, xmlWithClonedBlock);
  return clonedSnippetUUID;
}

import UTILS from "../../common/utils";
import BoundingBox from "../../common/BoundingBox";

export function clearAllMasks() {
  return {
    type: "CLEAR_ALL_MASKS",
  }
}

export function upsertMask(snippetUUID: string, boundingBox: BoundingBox,
                           depth: number) {
  UTILS.assert(snippetUUID);
  UTILS.assert(boundingBox);
  UTILS.assert(depth);
  return {
    type: "UPSERT_MASK",
    payload: {snippetUUID, boundingBox, depth}
  }
}

export function setHighlightComponent(snippetUUID: string | null) {
  return {
    type: "HIGHLIGHT_COMPONENT",
    payload: {snippetUUID}
  }
}

export function setDisplayMasks(value: boolean) {
  return {
    type: "SET_DISPLAY_MASKS",
    payload: {value}
  }
}
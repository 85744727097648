import Project from "../common/Project";

export default interface BackEndProject {
  project_uuid: string,
  image_url: string,
  is_public: boolean,
  name: string,
  root_snippet_uuid: string
}

export function projectToData(project: Project): BackEndProject {
  return {
    project_uuid: project.projectUUID,
    image_url: project.imageURL,
    is_public: project.isPublic,
    name: project.name,
    root_snippet_uuid: project.rootSnippetUUID
  }
}

import * as Blockly from "blockly/core";

export default function getSurroundParentChecks(block: Blockly.Block) : Array<string> {
  let res: Array<string> = [];
  const surroundParent: Blockly.Block | null = block.getSurroundParent();
  if (surroundParent !== null) {
    let parent: Blockly.Block | null = block.getParent();
    while (parent !== null && (parent as Blockly.Block).id !== surroundParent.id) {
      block = parent;
      parent = block.getParent();
    }
    if (parent) {
      if ((parent as Blockly.Block).id === surroundParent.id) {
        let allConnections = surroundParent.getConnections_(false);
        allConnections.forEach(connection => {
          if (connection.targetBlock()  && connection.targetBlock().id === block.id) {
            res = connection.getCheck() ? connection.getCheck() : res;
          }
        });
      }
    }
  }
  return res;
}

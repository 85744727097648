import BaseComponent from "../../common/BaseComponent.ts";
import React from "react";
import {Editor} from "react-draft-wysiwyg";
import {connect} from "react-redux";
import {ContentState, convertFromHTML, EditorState} from "draft-js";
import UTILS from "../../common/utils";
import draftToHtml from "draftjs-to-html";
import {convertToRaw} from "draft-js";

class WYSIWYGEditor extends BaseComponent {

  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
      wasInitialized: false
    };
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  render() {
    const self = this;
    const style = {
      ...{
        width: '100%',
        height: '400px',
        overflow: 'auto',
      },
      ...this.props.style
    };
    const editorState = (() => {
      const editorWasInitialized = Object.keys(this.props.allVariables.userEditorsState)
          .includes(this.props.name);
      if (editorWasInitialized) {
        return this.props.allVariables.userEditorsState[this.props.name];
      } else {
        const blocksFromHTML = convertFromHTML(`${this.props.initialValue}`);
        return EditorState.createWithContent(ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap));
      }
    })();
    return <div style={style}>
      <Editor
          editorState={editorState}
          toolbarStyle={{
            backgroundColor: "#DDDDDD", borderStyle: "solid", borderWidth: 1,
            borderColor: "#BBB"
          }}
          toolbar={{
            options: ['fontSize', 'fontFamily', 'history', 'inline', 'colorPicker'],
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace'],
            },
            history: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['undo', 'redo'],
              undo: {className: undefined},
              redo: {className: undefined},
            },
            colorPicker: {
              className: undefined,
              component: undefined,
              popupClassName: undefined,
              colors: [UTILS.getColorPalette().UI,
                UTILS.getColorPalette().STYLE,
                UTILS.getColorPalette().ACTION,
                UTILS.getColorPalette().DATA,
                'rgb(0,0,0)',
                'rgb(255,255,255)'],
            },
            fontSize: {
              options: [8, 9, 10, 11, 12, 14, 16, 18, 24],
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
            },
            fontFamily: {
              options: ['monospace', 'Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman',
                'Verdana'],
            },
            blockType: {
              className: 'bordered-option-classname',
            }
          }}
          onEditorStateChange={self.onEditorStateChange}
          onFocus={() => {
            if (self.getFocusedComponent() !== undefined)
              self.setFocusedComponent(undefined);
          }}
      />
    </div>
  }

  onEditorStateChange(WYSIWYGEditorState) {
    this.props.setUserEditorState(this.props.name, WYSIWYGEditorState);
    let content = draftToHtml(convertToRaw(
        WYSIWYGEditorState.getCurrentContent()));
    this.props.injectDataSnippet(this.props.name, content);
  }
}

export default connect(BaseComponent.mapStateToProps, BaseComponent.mapDispatchToProps)
(WYSIWYGEditor);

import * as Blockly from 'blockly/core';
import UTILS from "../common/utils";

function readJson(file: any, callback: any) {
  var rawFile: any = new XMLHttpRequest();
  rawFile.overrideMimeType("application/json");
  rawFile.open("GET", file, true);
  rawFile.onreadystatechange = function () {
    if (rawFile.readyState === 4 && parseInt(rawFile.status, 10) === 200) {
      callback(rawFile.responseText);
    }
  };
  rawFile.send(null);
}

function injectBlocksToBlockly(msg: string) {
  // @ts-ignore
  JSON.parse(msg).forEach((item) => {
    const colours = ["HTML", "UI", "ACTION", "STYLE", "DATA",
      "EXAMPLES", "LAYOUT_PROPERTIES"];
    UTILS.assert(colours.includes(item.colour), item.colour);
    item.colour = UTILS.getColorPalette()[item.colour];
    Blockly.Blocks[item['type']] =
        {
          init: function () {
            // @ts-ignore
            this.jsonInit(
                item,
            );
          }
        };
  });
}

/** @function
 * @memberof BlocklyLogic
 * @name injectAtomicBlocks
 * @description Inject into blockly all hard coded atomic blocks.
 * These are the atomic building blocks of all snippets.
 * Redux is not not updated regarding these atomic blocks.
 */
export default async function injectAtomicBlocks() {
  readJson("../blocks/actions/actions.json", injectBlocksToBlockly);
  readJson("../blocks/actions/web_actions.json", injectBlocksToBlockly);
  readJson("../blocks/actions/google_blocks.json", injectBlocksToBlockly);
  readJson("../blocks/actions/hello_mail_blocks.json", injectBlocksToBlockly);
  readJson("../blocks/actions/misc_actions.json", injectBlocksToBlockly);
  readJson("../blocks/data/data_blocks.json", injectBlocksToBlockly);
  readJson("../blocks/data/json_blocks.json", injectBlocksToBlockly);
  readJson("../blocks/data/resource_blocks.json", injectBlocksToBlockly);
  readJson("../blocks/projects/projects.json", injectBlocksToBlockly);
  readJson("../blocks/ui/html_blocks.json", injectBlocksToBlockly);
  readJson("../blocks/ui/mui_blocks.json", injectBlocksToBlockly);
  readJson("../blocks/ui/mui_checkbox.json", injectBlocksToBlockly);
  readJson("../blocks/ui/mui_text_field_blocks.json", injectBlocksToBlockly);
  readJson("../blocks/ui/react_router_blocks.json", injectBlocksToBlockly);
  readJson("../blocks/ui/recharts_blocks.json", injectBlocksToBlockly);
  readJson("../blocks/ui/properties/style_blocks.json", injectBlocksToBlockly);
  readJson("../blocks/ui/properties/flex_blocks.json", injectBlocksToBlockly);
  readJson("../blocks/ui/properties/unit_blocks.json", injectBlocksToBlockly);
  await (UTILS.asyncTimeout(200));
}

import * as Blockly from "blockly/core";

export function projectImplementation(block) {
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  let code = `class ProjectContainerBlock extends BaseComponent {`;
  code += `Render() {`;
  code += `const self = this;`;
  code += `this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';`;
  code += `const StyledBox = withStyles({`;
  code += `root:  {`;
  code += `position: "relative",`;
  code += `top: "4px",`;
  code += `left: "4px",`;
  code += `width: "100%",`;
  code += `height: "100%" }`;
  code += `})(Box);`;
  code += `return <StyledBox ref={self.componentReference} display="flex">`;
  code += `${content} </StyledBox>}}`;
  return code;
};

export function rootImplementation(block) {
  // Deprecated
  const browserView = Blockly.JavaScript.statementToCode(block, 'browser_view');
  const mobileView = Blockly.JavaScript.statementToCode(block, 'mobile_view');
  let currentBlock = this.getInputTargetBlock('init_actions');
  let actionCode = ``;
  while (currentBlock) {
    actionCode += `${Blockly.JavaScript[currentBlock.type].call(currentBlock, currentBlock)};`;
    currentBlock = currentBlock.getNextBlock();
  }
  let code = "class Root extends BaseComponent {";
  code += `componentDidMount() {`;
  code += `if (!context.wasProjectInitialized()) {`;
  code += `context.setWasProjectInitialized(true);`;
  code += `${actionCode}; }`;
  code += `}`;
  code += "Render() {";
  code += `return  <>`;
  code += `<BrowserView>${browserView}</BrowserView>`;
  code += `<MobileView>${mobileView}</MobileView>`;
  code += `</>}}`;
  return code;
};
import React from "react";

export default class FlexBoxToolBox extends React.Component {
  render() {
    return <category name="Box properties">
      <block type="flex_direction"/>
      <block type="flex_grow">
        <statement name="value">
          <block type="data_primitive_number">
            <field name="value">2</field>
          </block>
        </statement>
      </block>
      <block type="flex_justify_content"/>
      <block type="flex_align_content"/>
      <block type="flex_align_items"/>
      <block type="flex_align_self"/>
    </category>
  }
}

import * as Blockly from "blockly/core";

export default function generateStyleCode(block) {
  // See https://groups.google.com/g/blockly/c/uXewhtr-mvM
  let currentBlock = block.getInputTargetBlock('style');
  let res = `let styleArray = context.evaluateFJSONExpressionWithRedux([`;
  while (currentBlock) {
    res += Blockly.JavaScript[currentBlock.type].call(currentBlock, currentBlock);
    currentBlock = currentBlock.getNextBlock();
    res += currentBlock ? ', ' : '';
  }
  res += `]);`;
  res += `let style = Object.assign({}, ...styleArray);`;
  return res;
}

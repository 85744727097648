import { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ArenaBlockAPI, AppContextProvider } from "./api/ArenaBlockAPI.ts";
import WorkAreaPage from "./components/pages/work_area/WorkAreaPage.tsx";
import loadPackages from "./loadPackages";
import store from "./store";
import Masker from "./components/Masker";
import ProjectCreationForm from './components/forms/create_project/ProjectCreationForm.tsx';
import DashboardPage from "./components/pages/dashboard/DashboardPage"
import AboutForm from './components/forms/AboutForm';
import LoginForm from './components/forms/LoginForm';
import HomePage from "./components/pages/home/HomePage.tsx"
import loadAllProjectsFromBackend from "./server_interface/loadAllProjectsFromBackend";
import HelpForm from "./components/forms/help/HelpForm";
import ModifyProjectForm from "./components/forms/modify_project/ModifyProjectForm";
import { setIsDeployedSession, setIsNetlifySession } from "./redux/actions/sessionStateActions";

class Arena extends Component {

  constructor(props) {
    super(props);
    loadPackages();
    global.context = new ArenaBlockAPI();
  }

  async componentDidMount() {
    await loadAllProjectsFromBackend();
    console.log("JJJ0", new URL(window.location.href).searchParams.get('netlify'))
    store.dispatch(setIsNetlifySession(
      (new URL(window.location.href).searchParams.get('netlify') === 'true')));
    store.dispatch(setIsDeployedSession(
      !(new URL(window.location.href).searchParams.get('edit') === 'true')));
  }

  render() {
    return <AppContextProvider value={global.context}>
      <Router>
        <Masker />
        <AboutForm />
        <ProjectCreationForm />
        <ModifyProjectForm />
        <LoginForm />
        <HelpForm />
        <Switch>
          <Route path="/dashboard" component={DashboardPage} />
          <Route exact path="/main/" component={WorkAreaPage} />
          <Route exact path="/main/:projectUUID" component={WorkAreaPage} />
          <Route path="/" component={HomePage} />
        </Switch>
      </Router>
    </AppContextProvider>;
  }
}

export default Arena;

import React, {Component} from 'react';
import {connect} from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import AccountCircle from '@material-ui/icons/AccountCircle';
import UTILS from "../common/utils";
import store from "../store";
import {setLoginForm} from "../redux/actions/forms/loginFormActions";
import {setIsLoggedIn} from "../redux/actions/sessionStateActions";
import {logOut} from "../server_interface/login";

class AccountIcon extends Component {

  static mapStateToProps(reduxState) {
    return {
      isLoggedIn: reduxState.sessionState.isLoggedIn,
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      setIsLoggedIn: (value) => {
        store.dispatch(setIsLoggedIn(value));
      },
      setLoginForm: (form) => {
        dispatch(setLoginForm(form));
      },
    }
  }

  render() {
    const self = this;
    return (
        <Tooltip title={self.props.isLoggedIn ? "Sign Out" : "Sign In"}>
          <IconButton onClick={() => {
            if (self.props.isLoggedIn) {
              logOut();
              self.props.setIsLoggedIn(false);
              if (this.props.history)
                this.props.history.push('/dashboard');
            } else {
              self.props.setLoginForm({isOpen: true})
            }
          }
          }>
            <AccountCircle
                style={{
                  color: self.props.isLoggedIn ? UTILS.getColorPalette().EXAMPLES :
                      UTILS.getColorPalette().GREY
                }}/>
          </IconButton>
        </Tooltip>
    )
  }
}

export default connect(AccountIcon.mapStateToProps, AccountIcon.mapDispatchToProps)(AccountIcon);

import Snippet from "../common/Snippet";
import UTILS from "../common/utils";
import SnippetCRUD from "./SnippetCRUD";

const axios = require('axios').default;

/** @function
 * @name downloadProjectSnippets
 * @description
 * Given a projectUUID download all project snippets from backend.
 * No side effects on redux store.
 */
export default async function downloadProjectSnippets(projectUUID: string):
    Promise<Snippet[] | undefined> {
  const SERVER_URL: string = UTILS.getServerAPIURL() as string;
  try {
    const response = await axios.get(
        `${SERVER_URL}/projects/${projectUUID}`,
        {withCredentials: true});
    return await downloadSnippetChildrenOfRoot(response.data.root_snippet_uuid);
  } catch (error) {
    console.warn("Failed to download projects from server", SERVER_URL);
  }
};

export async function downloadSnippetChildrenOfRoot(rootSnippetUUID: string):
    Promise<Snippet[] | undefined> {
  const SERVER_URL: string = UTILS.getServerAPIURL() as string;
  try {
    const topSortSnippetsResponse = await axios.get(
        `${SERVER_URL}/snippets//get_transitively/${rootSnippetUUID}`,
        {withCredentials: true});
    return await SnippetCRUD.retrieveMultipleSnippet(topSortSnippetsResponse.data);
  } catch (error) {
    console.warn("Failed to download projects from server", SERVER_URL);
  }
}

import { setIsLoggedIn, setUserUUID } from "../redux/actions/sessionStateActions";
import store from "../store";
import UTILS from "../common/utils";
import { revokeToken } from "../services/google/revokeToken";

const axios = require('axios').default;

export async function googleLogin(tokenId) {
  try {
    const SERVER_URL = UTILS.getServerAPIURL();
    const response = await axios.post(`${SERVER_URL}/login/google_login`,
      { tokenId }, { withCredentials: true });
    store.dispatch(setUserUUID(response.data.user_uuid));
    return { is_logged_in: response.data.is_logged_in, is_admin: response.data.is_admin };
  } catch (err) {
    console.log("Login FAIL");
    console.error(err);
    return false;
  }
}

export async function isLoggedIn() {
  try {
    const SERVER_URL = UTILS.getServerAPIURL();
    const response = await axios.get(`${SERVER_URL}/login/is_logged_in`,
      { withCredentials: true });
    store.dispatch(setIsLoggedIn(response.data.is_logged_in));
    store.dispatch(setUserUUID(response.data.user_uuid));
    return response.data.is_logged_in;
  } catch (err) {
    // Handle Error Here
    console.error(err);
    return false;
  }
}

export async function logOut() {
  try {
    localStorage.removeItem('IDToken');
    localStorage.removeItem('tokenObject');
    revokeToken();
    const SERVER_URL = UTILS.getServerAPIURL();
    const response = await axios.post(`${SERVER_URL}/login/logout`,
      {}, { withCredentials: true });
    console.log("LOGOUT", response);
    store.dispatch(setIsLoggedIn(false));
  } catch (err) {
    console.log("Login FAIL");
    console.error(err);
  }
}

import UTILS from "../common/utils";
import {upsertProject} from "../redux/actions/persistentStateActions";
import store from "../store";
import buildProject from "../common/buildProject";

const axios = require('axios').default;

export default async function loadAllProjectsFromBackend() {
  const SERVER_URL = UTILS.getServerAPIURL();
  try {
    const response = await axios.get(`${SERVER_URL}/projects/`,
        {withCredentials: true});
    // @ts-ignore
    response.data.forEach((project) => {
          store.dispatch(upsertProject(
              buildProject(project.project_uuid, project.name,
                  project.root_snippet_uuid, project.image_url,
                  project.is_public, project.user_uuid)));
        }
    );
    console.log(`Loaded ${response.data.length} projects from server.`)
  } catch (error) {
    console.warn("Failed to connect to server", SERVER_URL);
    console.log(error);
    // TODO(odedf): Handle failure gracefully.
  }
}

export async function loadSingleProjectFromBackend(projectUUID: string) {
  const SERVER_URL = UTILS.getServerAPIURL();
  try {
    const response = await axios.get(
        `${SERVER_URL}/projects/${projectUUID}`,
        {withCredentials: true});
    const data = response.data;
    store.dispatch(upsertProject(
        buildProject(data.project_uuid, data.name, data.root_snippet_uuid,
            data.image_url, data.is_public, data.user_uuid)));
  } catch (error) {
    console.warn("Failed to connect to server", SERVER_URL);
    console.log(error);
    // TODO(odedf): Handle failure gracefully.
  }
}

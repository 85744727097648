import * as Blockly from "blockly/core";

/**
 * An object that handles creating and setting each of the SVG elements
 * used by the renderer.
 * @param {!SVGElement} root The root SVG element.
 * @param {!Blockly.Theme.BlockStyle} style The style object to use for
 *     colouring.
 * @param {!Blockly.geras.ConstantProvider} constants The renderer's constants.
 * @constructor
 * @extends {Blockly.blockRendering.PathObject}
 * @package
 */
var CustomPathObject = function (root: any, style: any, constants: any) {
  /**
   * The renderer's constant provider.
   * @type {!Blockly.geras.ConstantProvider}
   */
  // @ts-ignore
  this.constants = constants;
  // @ts-ignore
  this.svgRoot = root;

  // The order of creation for these next three matters, because that
  // effectively sets their z-indices.

  /**
   * The dark path of the block.
   * @type {SVGElement}
   * @package
   */
  // @ts-ignore
  this.svgPathDark = Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      {
        'class': 'blocklyPathDark',
        'transform': 'translate(1,1)'
        // @ts-ignore
      }, this.svgRoot);

  /**
   * The primary path of the block.
   * @type {!SVGElement}
   * @package
   */
  // @ts-ignore
  this.svgPath = Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      // @ts-ignore
      {'class': 'blocklyPath'}, this.svgRoot);

  /**
   * The light path of the block.
   * @type {SVGElement}
   * @package
   */
  // @ts-ignore
  this.svgPathLight = Blockly.utils.dom.createSvgElement(
      Blockly.utils.Svg.PATH,
      // @ts-ignore
      {'class': 'blocklyPathLight'}, this.svgRoot);

  /**
   * The colour of the dark path on the block in '#RRGGBB' format.
   * @type {string}
   * @package
   */
  // @ts-ignore
  this.colourDark = '#000000';

  /**
   * The style object to use when colouring block paths.
   * @type {!Blockly.Theme.BlockStyle}
   * @package
   */
  // @ts-ignore
  this.style = style;
};
Blockly.utils.object.inherits(CustomPathObject,
    Blockly.blockRendering.PathObject);

/**
 * @override
 */
CustomPathObject.prototype.setPath = function (mainPath: any) {
  this.svgPath.setAttribute('d', mainPath);
  this.svgPathDark.setAttribute('d', mainPath);
};

/**
 * Set the highlight path generated by the renderer onto the SVG element.
 * @param {string} highlightPath The highlight path.
 * @package
 */
CustomPathObject.prototype.setHighlightPath = function (highlightPath: any) {
  this.svgPathLight.setAttribute('d', highlightPath);
};

/**
 * @override
 */
CustomPathObject.prototype.flipRTL = function () {
  // Mirror the block's path.
  this.svgPath.setAttribute('transform', 'scale(-1 1)');
  this.svgPathLight.setAttribute('transform', 'scale(-1 1)');
  this.svgPathDark.setAttribute('transform', 'translate(1,1) scale(-1 1)');
};

/**
 * @override
 */
CustomPathObject.prototype.applyColour = function (block: any) {
  this.svgPathLight.style.display = '';
  this.svgPathDark.style.display = '';
  this.svgPathLight.setAttribute('stroke', this.style.colourTertiary);
  this.svgPathDark.setAttribute('fill', this.colourDark);
  // @ts-ignore
  CustomPathObject.superClass_.applyColour.call(this, block);

  this.svgPath.setAttribute('stroke', 'none');
};

/**
 * @override
 */
CustomPathObject.prototype.setStyle = function (blockStyle: any) {
  this.style = blockStyle;
  this.colourDark =
      Blockly.utils.colour.blend('#000', this.style.colourPrimary, 0.2) ||
      this.colourDark;
  this.colourVeryDark = Blockly.utils.colour.blend('#000', this.style.colourPrimary, 0.5) ||
      this.colourDark;
};

/**
 * @override
 */
CustomPathObject.prototype.updateHighlighted = function (highlighted: any) {
  if (highlighted) {
    this.svgPath.setAttribute("stroke", this.colourVeryDark);
    this.svgPath.setAttribute("stroke-width", "4");
    this.svgPathLight.style.display = 'inline';
  } else {
    this.svgPath.setAttribute('filter', 'none');
    this.svgPathLight.style.display = 'inline';
  }
};

/**
 * @override
 */
CustomPathObject.prototype.updateShadow_ = function (shadow: any) {
  if (shadow) {
    this.svgPathLight.style.display = 'none';
    this.svgPathDark.setAttribute('fill', this.style.colourSecondary);
    this.svgPath.setAttribute('stroke', 'none');
    this.svgPath.setAttribute('fill', this.style.colourSecondary);
  }
};

/**
 * @override
 */
CustomPathObject.prototype.updateDisabled_ = function (disabled: any) {
  // @ts-ignore
  CustomPathObject.superClass_.updateDisabled_.call(this, disabled);
  if (disabled) {
    this.svgPath.setAttribute('stroke', 'none');
  }
};

// @ts-ignore
class CustomRenderer extends Blockly.geras.Renderer {
  // Extend Blockly.blockRendering.Renderer for default behavior.

  constructor(name: string) {
    super(name);
  }

  /**
   * @override
   */
  makePathObject(root: any, style: any) {
    // @ts-ignore
    return new CustomPathObject(root, style, this.getConstants());
  }
}

export default CustomRenderer;

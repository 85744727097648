import jwt_decode from "jwt-decode";
import React from 'react';
import BaseComponent from "../common/BaseComponent";
import UTILS from "../common/utils";
import GoogleGMailApiV1 from "../services/google/gmail/GoogleGMailApiV1"
import SlackAPI from "../services/slack/SlackApi";
import { setAlert } from "../redux/actions/actions";
import getSnippet from "../redux/logic/getSnippet";
import store from "../store";
import {
  createJSONFunctionCall,
  evaluateJSONFunction,
  JSONFunctionCall
} from "../redux/logic/data_functions/DataFunction";
import evaluateSnippetImplementation
  from "../redux/logic/evaluateSnippetImplementation";
// @ts-ignore
import { HTTPMethod, httpRequest, HttpResult } from "./web_api/httpRequest.ts";
import { injectBlocksAndUpsertSnippet } from "../redux/actions/persistentStateActions";
import { setWasProjectInitialized } from "../redux/actions/sessionStateActions";
import SnippetTypes from "../common/SnippetTypes";
import Snippet from "../common/Snippet";
import storeHasSnippet from "../redux/logic/storeHasSnippet";
import CONSTANTS from "../common/Constants";
import createContentBasedDataSnippet from "../common/createContentBasedDataSnippet";

const { v4: uuidv4 } = require('uuid');

/** @module ArenaBlockAPI */

/**
 * API methods available for any Arena Block.
 * Note that in the interim, this class holds some non API methods.
 */
export class ArenaBlockAPI {

  constructor() {
    // @ts-ignore
    this.tempVar = "";
    // @ts-ignore
    this.setSelectedsnippetUUID = () => {
      return undefined
    };
    this.executeJsSnippet = this.executeJsSnippet.bind(this);
    UTILS.assert(process.env);
    // @ts-ignore
    if (global.gapi) {
      // @ts-ignore
      const DISCOVERY_DOC = 'https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest';
      // @ts-ignore
      global.gapi.load('client', async () => {
        // @ts-ignore
        await global.gapi.client.init({
          apiKey: process.env.REACT_APP_GAPI_API_KEY,
          discoveryDocs: [DISCOVERY_DOC],
        });
      });
    }
    // @ts-ignore
    BaseComponent.appContext = this;
    // @ts-ignore
    this.slackAPI = new SlackAPI();
    // @ts-ignore
    //this.slackAPI.postMessage("#general", "Hello there");
  }

  // Execute a JS code snippet and return it's value.
  executeJsSnippet(snippetUUID: string, params: any) {
    UTILS.assert(storeHasSnippet(snippetUUID));
    let snippet: Snippet = getSnippet(snippetUUID) as Snippet;
    return this.executeJSFunction(snippet.content, params);
  }

  reduceObject(obj: Object, keys: Array<string>) {
    let res = {};
    for (const key of keys) {
      // @ts-ignore
      res[key] = obj[key];
    }
    return res;
  }

  setDataSnippet(snippetUUID: string, value: any) {
    const existingSnippet = getSnippet(snippetUUID);
    const newContent = JSON.stringify(value);
    if (!existingSnippet || (existingSnippet.content !== newContent)) {
      const snippet = existingSnippet ? existingSnippet :
        createContentBasedDataSnippet(snippetUUID, '');
      snippet.content = newContent;
      snippet.isContentGeneratedFromXml = false;
      snippet.xml = '';
      store.dispatch(injectBlocksAndUpsertSnippet(snippet,
        false, SnippetTypes.DATA));
    }
  }

  backupDataSnippet(snippetUUID: string) {
    const snippet: Snippet = getSnippet(snippetUUID);
    if (snippet) {
      snippet.xml = '';
      snippet.isContentGeneratedFromXml = false;
      store.dispatch(injectBlocksAndUpsertSnippet(snippet, true,
        SnippetTypes.DATA));
    }
  }

  executeJSFunction(code: string, params: any) {
    try {
      return (new Function('params', 'context',
        "return ( " + code + ")(params, context)")(params, this));
    } catch (e) {
      console.log("Broken action snippet execution", code);
      console.log(e);
    }
  }

  evaluateDataSnippet(snippetUUID: string) {
    const evalSnippetCall: JSONFunctionCall = createJSONFunctionCall('evalSnippet',
      [JSON.stringify(snippetUUID)]);
    // @ts-ignore
    return this.evaluateFJSONExpressionWithRedux(evalSnippetCall);
  }

  generateUUID(): string {
    return uuidv4();
  }

  evaluateFJSONExpressionWithRedux(expression: any, functionImplementationArray: Array<any> = [],
    localStore: any = store) {
    return evaluateJSONFunction(expression,
      [...[evaluateSnippetImplementation(localStore)],
      ...functionImplementationArray]);
  }

  getDataGridRows(rows: any): any {
    if (!Array.isArray(rows))
      return [];
    rows = (rows as Array<any>).filter(item => {
      return item.hasOwnProperty('id') && typeof item.id;
    });
    return rows;
  }

  /** @function
   * @name loadComponentToGlobal
   * @param {string} snippetUUID The UUID of the snippet that is to be loaded into the global
   * context.
   * @description Given a snippet that describes a react component, generate the code it represents,
   * transform it and add it to the global context.
   */
  loadComponentToGlobal(snippetUUID: string) {
    UTILS.assert(false);
    UTILS.loadComponentToGlobal(snippetUUID);
  }

  /** @function
   * @name setWasProjectInitialized
   * @param {boolean} wasProjectInitialized.
   */
  setWasProjectInitialized(wasProjectInitialized: boolean) {
    store.dispatch(setWasProjectInitialized(wasProjectInitialized));
  }

  wasProjectInitialized(): boolean {
    return store.getState().sessionState.wasProjectInitialized;
  }

  // API functions
  getSnippet(snippetUUID: string) {
    return getSnippet(snippetUUID);
  }

  async sendGmail(to: string, subject: string, message: string) {
    const rawTokenObject = localStorage.getItem('tokenObject');
    let tokenObject = rawTokenObject ? JSON.parse(rawTokenObject as string) :
      null;
    // @ts-ignore
    if (!google.accounts.oauth2.hasGrantedAllScopes(
      tokenObject, CONSTANTS.SCOPES.GMAIL_SEND)) {
      let client = null;
      this.alert("Add GMAIL send Credentials",
        "In order to send an e-mail (from your account) you need " +
        "to grant TheCodingArena the appropriate credentials.", 'info',
        2500);
      try {
        UTILS.assert(process.env);
        // @ts-ignore
        client = await google.accounts.oauth2.initTokenClient({
          client_id: process.env.REACT_APP_GGL_CLIENT_ID,
          scope: CONSTANTS.SCOPES.GMAIL_SEND,
          callback: (response: any) => {
            localStorage.setItem('tokenObject', JSON.stringify(response));
          },
        });
      } catch (e) {
        console.log("error:", e)
      }
      client.requestAccessToken();
    } else {
      // @ts-ignore
      global.gapi.client.setToken(tokenObject);
      const IDToken = localStorage.getItem('IDToken');
      if (IDToken) {
        const userObject: any = jwt_decode(IDToken);
        // @ts-ignore
        GoogleGMailApiV1.send(global.gapi, to, userObject.email, subject, message);
        console.log("email sent", JSON.stringify({
          to,
          from: userObject.email, subject, message
        }));
      } else {
        this.alert("Send gmail failed",
          "Something went wrong", 'error',
          -1);
      }
    }
  }

  injectBlocksAndUpsertDataSnippet(content: string, snippetUUID: string) {
    let snippet = createContentBasedDataSnippet(snippetUUID,
      JSON.stringify(content));
    store.dispatch(injectBlocksAndUpsertSnippet(snippet, false,
      SnippetTypes.DATA));
  }

  alert(alertTitle: string, alertContent: string, alertSeverity: string, alertDurationMsc: number) {
    store.dispatch(setAlert({
      alertTitle, alertContent, alertSeverity, alertDurationMsc
    }));
  }

  httpGetRequest(url: URL) {
    return httpRequest(HTTPMethod.GET, url);
  }

  httpDeleteRequest(url: URL) {
    httpRequest(HTTPMethod.DELETE, url).then((res: any) => console.log(res));
  }

  httpPutRequest(url: URL, body: any) {
    return httpRequest(HTTPMethod.PUT, url, body);
  }

  httpPostRequest(url: URL, body: any) {
    return httpRequest(HTTPMethod.POST, url, body);
  }

  httpRequest(httpMethod: HTTPMethod, url: URL, body: any): Promise<HttpResult> {
    UTILS.assert(false);
    return httpRequest(httpMethod, url, body)
  }

  setTempVar(val: any) {
    // @ts-ignore
    this.tempVar = val;
  }
  getTempVar() {
    // @ts-ignore
    return this.tempVar;
  }
}

// @ts-ignore
export const AppContext = React.createContext();
export const AppContextProvider = AppContext.Provider;

import React from 'react';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import TextField from "@material-ui/core/TextField/TextField";
import CONSTANTS from "../../../common/Constants";
import UTILS from "../../../common/utils";
import { clearCreateProjectForm, setCreateProjectForm } from
  "../../../redux/actions/forms/createProjectFormActions";
import { displayUserProjects } from
  "../../../redux/actions/dashboardPageActions";
import { CreateProjectFormValue } from
  "../../../redux/reducers/forms/createProjectFormReducer";
import Project from "../../../common/Project";
import cloneProject from "./cloneProject";
import { PersistentState } from "../../../redux/reducers/persistentStateReducer";

const MESSAGES = CONSTANTS.MESSAGES;
const BLANK_PROJECT_UUID = "c1a27d62-c322-4715-9dec-281dcbba410d";
type MyProps = {
  clearCreateProjectForm: any,
  displayUserProjects: any,
  createProjectForm: CreateProjectFormValue,
  isOpen: boolean,
  setCreateProjectForm: any,
  publicProjects: object
};

type MyState = {
  formHelperText: string;
  formError: boolean;
  templateProjectUUID: string;
};

const useStyles = () => ({
  inline: {
    display: 'inline',
  },
});

// Responsible for setting the redux state that is associated with this form.
class ProjectCreationForm extends React.Component<MyProps, MyState> {

  constructor(props: MyProps) {
    super(props);
    this.state = {
      formHelperText: MESSAGES.ENTER_PROJECT_NAME,
      formError: false,
      templateProjectUUID: BLANK_PROJECT_UUID
    };
    this.onFormCreate = this.onFormCreate.bind(this);
    this.onFormCancel = this.onFormCancel.bind(this);
  }

  // @ts-ignore
  static mapStateToProps(reduxState) {
    const persistentState: PersistentState = reduxState.persistentState;
    return {
      publicProjects: persistentState.publicProjects,
      createProjectForm: reduxState.createProjectForm,
      isOpen: reduxState.createProjectForm.isOpen,
      name: reduxState.createProjectForm.name,
    }
  }

  // @ts-ignore
  static mapDispatchToProps(dispatch) {
    return {
      // @ts-ignore
      setCreateProjectForm: (createProjectForm) => {
        dispatch(setCreateProjectForm(createProjectForm));
      },
      clearCreateProjectForm: () => {
        dispatch(clearCreateProjectForm());
      },
      displayUserProjects: () => {
        dispatch(displayUserProjects());
      }
    }
  }

  onChange(field: any, event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    this.props.setCreateProjectForm({
      ...this.props.createProjectForm,
      ...{ 'name': event.target.value }
    });
    this.setState({
      formError: false,
      formHelperText: MESSAGES.ENTER_BLOCK_NAME
    });
  }

  updateSelected(templateProjectUUID: string) {
    this.setState({
      templateProjectUUID: templateProjectUUID,
    },
    );
    const initialProjectName =
      (templateProjectUUID === BLANK_PROJECT_UUID) ? "Blank project" :
        (this.props.publicProjects &&
          this.props.publicProjects.hasOwnProperty(templateProjectUUID) &&
          // @ts-ignore
          this.props.publicProjects[templateProjectUUID].name ?
          // @ts-ignore
          this.props.publicProjects[templateProjectUUID].name : '');
    // @ts-ignore
    this.props.setCreateProjectForm({ name: initialProjectName });
  }

  validateForm() {
    UTILS.assert(this.props.createProjectForm);
    if (this.props.createProjectForm.name.length === 0) {
      return false;
    }
    return true;
  }

  async onFormCreate() {
    if (!this.validateForm()) {
      this.setState({
        formError: true,
        formHelperText: MESSAGES.EMPTY_PROJECT_NAME
      });
    } else {
      await cloneProject(this.props.createProjectForm.name,
        this.state.templateProjectUUID);
      this.props.setCreateProjectForm({ isOpen: false });
      this.props.displayUserProjects();
    }
  }

  onFormCancel() {
    this.props.clearCreateProjectForm();
    this.props.setCreateProjectForm({ isOpen: false });
  }

  render() {
    const dialogTitle = 'Create project';
    let publicProjects = this.props.publicProjects;
    UTILS.assert(publicProjects);
    let items: Array<Project> =
      Array.from(Object.values(publicProjects)).filter(
        item => item.projectUUID !== BLANK_PROJECT_UUID);
    const self = this;
    return <Dialog open={this.props.isOpen}
      fullWidth
      onClose={self.onFormCancel}>
      <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            width: '48%',
            marginRight: '2%',
          }}>
            <TextField
              value={this.props.createProjectForm.name}
              error={this.state.formError}
              autoFocus
              margin="dense"
              id="name"
              label="Project Name"
              onKeyPress={(e) => {
                if (e.key === 'Enter')
                  self.onFormCreate();
              }}
              onChange={(event) => {
                self.onChange('blockName', event);
              }}
              helperText={this.state.formHelperText}
            />
          </div>
          <List
            component="nav"
          >
            <MenuItem button
              selected={this.state.templateProjectUUID === BLANK_PROJECT_UUID}
              onClick={() => {
                this.updateSelected(BLANK_PROJECT_UUID);
              }}>
              <ListItemAvatar>
                <Avatar alt="React"
                  src={'https://codingarena.s3.us-west-2.amazonaws.com/p3.png'} />
              </ListItemAvatar>
              <ListItemText primary={"Blank project"} />
            </MenuItem>
          </List>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Examples
              </ListSubheader>
            }
          >
            {items.map((item) => (
              <MenuItem button
                selected={this.state.templateProjectUUID === item.projectUUID}
                onClick={() => {
                  this.updateSelected(item.projectUUID);
                }}>
                <ListItemAvatar>
                  <Avatar alt="React" src={item.imageURL} />
                </ListItemAvatar>
                <ListItemText primary={item.name} />
              </MenuItem>
            ))}
          </List>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={self.onFormCreate} color="primary">Create</Button>
        <Button onClick={self.onFormCancel} color="primary">Cancel</Button>
      </DialogActions>
    </Dialog>
  }
}

export default connect(ProjectCreationForm.mapStateToProps,
  ProjectCreationForm.mapDispatchToProps)(withStyles(useStyles)(ProjectCreationForm));

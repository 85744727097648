import React from 'react';
import {connect} from "react-redux";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import {
  clearUnlockSnippetForm,
  setUnlockSnippetForm
} from "../../../redux/actions/forms/unlockSnippetActioon";
import {UnlockSnippetFormValue} from "../../../redux/reducers/forms/unlockSnippetReducer";
import processForm from "./processForm";
import getCurrentSnippet from "../../../redux/logic/getCurrentSnippet";

interface Props extends UnlockSnippetFormValue {
  setUnlockSnippetForm: (formValue: UnlockSnippetFormValue) => any,
  clearUnlockSnippetForm: () => any,
  currentSnippetUUID: string,
}

interface State {
}

// Responsible for setting the redux state that is associated with this form.
// On crate calls the callback this.props.createActionBlock.
class UnlockSnippetForm extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      formError: false,
    };
    this.onFormApprove = this.onFormApprove.bind(this);
    this.onFormCancel = this.onFormCancel.bind(this);
  }

  static mapReduxStateToProps(reduxState: any) {
    const unlockSnippetFormValue: UnlockSnippetFormValue = reduxState.unlockSnippetState;
    const currentSnippet = getCurrentSnippet();
    const currentSnippetUUID: string = currentSnippet === null ? '' :
        currentSnippet.snippetUUID;
    return {
      isOpen: unlockSnippetFormValue.isOpen,
      currentSnippetUUID
    }
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      setUnlockSnippetForm: (formValue: UnlockSnippetFormValue) => {
        dispatch(setUnlockSnippetForm(formValue));
      },
      clearUnlockSnippetForm: () => {
        dispatch(clearUnlockSnippetForm());
      }
    }
  }

  onFormCancel() {
    this.props.clearUnlockSnippetForm();
    this.props.setUnlockSnippetForm({
      confirmed: false,
      unlockSnippetUUID: "",
      isOpen: false
    });
  }

  onFormApprove() {
    const unlockSnippetFormValue: UnlockSnippetFormValue = {
      confirmed: true,
      unlockSnippetUUID: this.props.currentSnippetUUID,
      isOpen: false
    };
    this.props.setUnlockSnippetForm(unlockSnippetFormValue);
    processForm(unlockSnippetFormValue);
  }

  render() {
    const dialogTitle = 'Unlock Snippet';
    const self = this;
    return <Dialog open={this.props.isOpen}
                   fullWidth
                   onClose={self.onFormCancel}>
      <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <Typography>Unlocked snippets enables it to be edited textually vs. via
          blocks.
          Important!: Unlocking can not be reversed. Once approved you will
          not be able to edit or view this snippet in block view, only in the
          text editor.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={self.onFormApprove} color="primary">Approve</Button>
        <Button onClick={self.onFormCancel} color="primary">Cancel</Button>
      </DialogActions>
    </Dialog>
  }
}

// @ts-ignore
export default connect(UnlockSnippetForm.mapReduxStateToProps, UnlockSnippetForm.mapDispatchToProps)(UnlockSnippetForm);

/** @module common/ToolboxCategory */

/**
 * Enum for ToolboxCategory.
 * @readonly
 * @enum {string}
 */
enum ToolboxCategory {
    "MUI_INPUTS" = "MUI_INPUTS",
    "MUI_NAVIGATION" = "MUI_NAVIGATION",
    "MUI_SURFACES" = "MUI_SURFACES",
    "ACTIONS" = "ACTIONS",
    "ACTION_DATA" = "ACTION_DATA",
    "TEXT_ACTION" = "TEXT_ACTION",
    "MUI_LINK" = "MUI_LINK",
    "MUI_IMAGE" = "MUI_IMAGE",
    "DATA_GRID" = "DATA_GRID",
    "MUI_TABS" = "MUI_TABS",
    "MUI_TEXT_EDITOR" = "MUI_TEXT_EDITOR",
    "MUI_TEXT_FIELD" = "MUI_TEXT_FIELD",
    "UI_STYLED_TYPOGRAPHY" = "UI_STYLED_TYPOGRAPHY",
    "MUI_DATA_DISPLAY" = "MUI_DATA_DISPLAY",
    "HTML_DIV" = "HTML_DIV",
    "HTML_BODY" = "HTML_BODY",
    "HTML_BUTTON" = "HTML_BUTTON",
    "HTML_HEAD" = "HTML_HEAD",
    "HTML_HTML" = "HTML_HTML",
    "HTML_IMG" = 'HTML_IMG',
    "HTML_LINK" = 'HTML_LINK',
    "UI_ROUTER" = "UI_ROUTER",
    "DATA_PRIMITIVES" = "DATA_PRIMITIVES",
    "DATA_SNIPPETS" = "DATA_SNIPPETS",
    "LAYOUT" = "LAYOUT",
    "PROJECTS" = "PROJECTS",
    "STYLE" = "STYLE",
    "STYLED_TEXT_SNIPPETS" = "STYLED_TEXT_SNIPPETS"
};

export default ToolboxCategory;

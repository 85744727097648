export function revokeToken() {
  const rawTokenObject = localStorage.getItem('tokenObject');
  // Note that this functionality is currently broken on the Google side.
  // See: https://stackoverflow.com/questions/71532935/cors-issue-only-when-revoking-token-by-calling-google-identity-services-giss
  let tokenObject: any = rawTokenObject ? JSON.parse(rawTokenObject as string) :
      null;
  if (tokenObject) {
    // @ts-ignore
    google.accounts.oauth2.revoke(tokenObject.accessToken, (res) => {
      console.log(
          'revoked access token', JSON.stringify(res))
    });
  }
}

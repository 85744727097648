import * as Blockly from "blockly/core";

export function styleWidth(block) {
  const value = Blockly.JavaScript.statementToCode(block, 'value');
  return `{width: (context.evaluateFJSONExpressionWithRedux("${value}") + "${block.getFieldValue(
      'units')}")}`;
}

export function styleHeight(block) {
  const value = Blockly.JavaScript.statementToCode(block, 'value');
  return `{height: (context.evaluateFJSONExpressionWithRedux("${value}") + "${block.getFieldValue(
      'units')}")}`;
}

import SnippetTypes from "../../common/SnippetTypes";
import UTILS from "../../common/utils";
import * as Blockly from "blockly/core";
import {
  createJSONFunctionCall,
  JSONFunctionCall
} from "../../redux/logic/data_functions/DataFunction";
import getSnippet from "../../redux/logic/getSnippet";

/**
 * @namespace BlocklyLogic
 */

/** @function
 * @memberof BlocklyLogic
 * @name injectBlocks
 * @param {string} snippetUUID snippetUUID
 * @param {SnippetTypes} snippetType
 * @description Given a snippetUUID of a given type, inject into blockly
 * it's deceleration block. Can be used to update block.
 */
export function injectBlocks(snippetUUID: string, snippetType: SnippetTypes,
                             name: string | undefined) {
  name = name ? name : snippetUUID;
  switch (snippetType) {
    case SnippetTypes.ACTION:
      injectBlocksForActionSnippet(snippetUUID, snippetType, name);
      break;
    case SnippetTypes.HTML:
      injectBlocksForHTMLSnippet(snippetUUID, name);
      break;
    case SnippetTypes.DATA:
      injectBlocksForDataSnippet(snippetUUID, snippetType, name);
      break;
    case SnippetTypes.STYLE:
      injectStyleSnippetDeclarationBlockAndGenerator(snippetUUID, name);
      break;
    case SnippetTypes.REACT_COMPONENT:
      injectSnippetDeclarationBlockTypeAndGenrator(snippetUUID, snippetType, name,
          'REACT_COMPONENT_CONNECTOR');
      break;
    case SnippetTypes.PROJECT_ROOT:
      injectSnippetDeclarationBlockTypeAndGenrator(snippetUUID, snippetType, name,
          'PROJECT_ROOT_CONNECTOR');
      break;
    default:
      UTILS.assert(false, snippetType);
  }
}

function injectBlocksForActionSnippet(snippetUUID: string,
                                      snippetType: SnippetTypes,
                                      snippetName: string) {
  injectDeclarationBlockType(snippetUUID,
      UTILS.getColorForSnippetType(snippetType),
      "ACTION_CONNECTOR", snippetName);
  // @ts-ignore
  Blockly.JavaScript[snippetUUID] = function () {
    return `context.executeJsSnippet("${snippetUUID}")`;
  };
}

export default function injectBlocksForDataSnippet(snippetUUID: string,
                                                   snippetType: SnippetTypes,
                                                   snippetName: string) {
  injectDeclarationBlockType(snippetUUID,
      UTILS.getColorForSnippetType(snippetType),
      "DATA_CONNECTOR", snippetName);
  // @ts-ignore
  Blockly.JavaScript[snippetUUID] = function () {
    const evalSnippetCall: JSONFunctionCall = createJSONFunctionCall('evalSnippet',
        [JSON.stringify(snippetUUID)]);
    return JSON.stringify(evalSnippetCall);
  };
}

function injectBlocksForHTMLSnippet(snippetUUID: string,
                                    blockMessage: string) {
  injectDeclarationBlockType(snippetUUID, UTILS.getColorPalette().HTML,
      "String", blockMessage);
  // @ts-ignore
  Blockly.JavaScript[snippetUUID] = function (block) {
    return `context.getSnippet("${snippetUUID}").content`;
  };
}

function injectStyleSnippetDeclarationBlockAndGenerator(snippetUUID: string,
                                                        snippetName: string) {
  injectDeclarationBlockType(snippetUUID,
      UTILS.getColorPalette().STYLE, "STYLE_CONNECTOR", snippetName);
  // @ts-ignore
  Blockly.JavaScript[snippetUUID] = function () {
    const snippet = getSnippet(snippetUUID);
    return snippet && snippet.content ? snippet.content : "";
  };
}

function injectSnippetDeclarationBlockTypeAndGenrator(snippetUUID: string,
                                                      snippetType: SnippetTypes,
                                                      snippetName: string,
                                                      connector: string | null) {
  injectDeclarationBlockType(snippetUUID,
      UTILS.getColorForSnippetType(snippetType), connector, snippetName);
  // @ts-ignore
  Blockly.JavaScript[snippetUUID] = function () {
    return `<${snippetUUID}/>`;
  };
}

function injectDeclarationBlockType(blockType: string, color: string,
                                    connector: string | null,
                                    snippetName: string) {
  Blockly.Blocks[blockType] = {
    init: function () {
      // @ts-ignore
      this.jsonInit(
          {
            "type": blockType,
            "message0": `${snippetName}`,
            "previousStatement": connector,
            "nextStatement": connector,
            "colour": color
          }
      );
    }
  };
}

import UTILS from "../../common/utils";
import Snippet from "../../common/Snippet";
import ToolboxCategory from "../../common/ToolboxCategory";
import ReduxAction from "./ReduxAction";
import Project from "../../common/Project";
import SnippetTypes from "../../common/SnippetTypes";

/** @module redux/actions/persistentStateActions */

export interface PersistentStateActionPayload {
  callbackKey: any;
  project: Project;
  snippet: Snippet;
  text: string;
  toolboxCategory: ToolboxCategory;
  type: string;
  projectUUID: string;
  snippetsArray: Array<Snippet>;
}

export interface PersistentStateAction extends ReduxAction {
  payload: PersistentStateActionPayload;
}

interface InjectBlockForSnippetActionPayload {
  snippetUUID: string;
  type: SnippetTypes;
  name: string
}

export interface InjectBlockAndCreateSnippetAction extends ReduxAction {
  payload: InjectBlockAndCreateSnippetActionPayload;
}

export interface InjectBlockForSnippetAction extends ReduxAction {
  payload: InjectBlockForSnippetActionPayload;
}

// Block Actions

/** @function
 * @name injectBlocks
 * @description This action injects blocks for a given snippet to Blockly.
 * @see {@link injectBlocksReducer}
 * @see {@link injectBlocksSaga}
 */
export function injectBlocks(snippetUUID: string,
                             type: SnippetTypes,
                             name: string) {
  return {
    type: "INJECT_BLOCKS_FOR_SNIPPET",
    payload: {snippetUUID, type, name}
  }
}

export interface InjectBlockAndCreateSnippetActionPayload {
  snippet: Snippet;
  upsertToBE: boolean;
  type: SnippetTypes;
}

/** @function
 * @name injectBlocksAndUpsertSnippet
 * @description  Compound action.
 *  1. Inject blocks for given category.
 *  2. Once blocks are injected, dispatch upsert snippet.
 * @param {Snippet} snippet.
 */
export function injectBlocksAndUpsertSnippet(snippet: Snippet,
                                             upsertToBE: boolean,
                                             type: SnippetTypes) {
  UTILS.assert(snippet.isContentGeneratedFromXml ||
      (snippet.xml.length === 0));
  const payload: InjectBlockAndCreateSnippetActionPayload = {
    snippet,
    upsertToBE,
    type
  };
  return {
    type: "INJECT_BLOCKS_AND_CREATE_SNIPPET",
    payload
  }
}

// Snippet Actions

export interface InjectBlockAndCreateSnippetActionPayload {
  snippet: Snippet;
  type: SnippetTypes;
}

/** @function
 * @name upsertSnippet
 * @description Update a snippet
 * @param {Snippet} snippet
 */

export interface UpsertSnippetAction {
  type: "UPSERT_SNIPPET";
  payload: {
    snippet: Snippet;
    upsertToBE: boolean;
  }
}

export function upsertSnippet(snippet: Snippet, upsertToBE: boolean): UpsertSnippetAction {
  UTILS.assert(!snippet.isContentGeneratedFromXml || snippet.xml);
  UTILS.assert(snippet.isContentGeneratedFromXml ||
      (snippet.content.length > 0), snippet.snippetUUID);
  return {
    type: "UPSERT_SNIPPET",
    payload: {snippet, upsertToBE}
  }
}

export interface UpsertSnippetsArrayAction {
  type: "UPSERT_SNIPPETS_ARRAY";
  payload: {
    snippetsArray: Array<Snippet>;
    upsertToBE: boolean;
  }
}

export function upsertSnippetsArray(snippetsArray: Array<Snippet>,
                                    upsertToBE: boolean):
    UpsertSnippetsArrayAction {
  return {
    type: "UPSERT_SNIPPETS_ARRAY",
    payload: {snippetsArray, upsertToBE}
  }
}

// Toolbox Actions

export function addItemToToolbox(type: string, toolboxCategory: ToolboxCategory) {
  return {
    type: "ADD_ITEM_TO_TOOLBOX",
    payload: {type, toolboxCategory}
  }
}

export function addButtonToToolbox(text: string, callbackKey: string,
                                   toolboxCategory: ToolboxCategory) {
  return {
    type: "ADD_BUTTON_TO_TOOLBOX",
    payload: {text, callbackKey, toolboxCategory}
  }
}

// Project Actions

export function upsertProject(project: Project) {
  return {
    type: "UPSERT_PROJECT",
    payload: {project}
  }
}

export function deleteProject(projectUUID: string) {
  return {
    type: "DELETE_PROJECT",
    payload: {projectUUID}
  }
}

/** @module redux/reducers/createProjectFormReducer */

export interface CreateProjectFormValue {
  isOpen: boolean,
  isPublic: boolean,
  name: string,
}

const initCreateProjectFormValue: CreateProjectFormValue = {
  isOpen: false,
  isPublic: false,
  name: "",
};

/** @function
 * @memberof ReduxReducers
 * @name createProjectFormReducer
 * @description Manage all state variables that are used in createProjectForm.
 * Handles the following actions:<br>
 * SET_CREATE_PROJECT_BLOCK_FORM.<br>
 * CLEAR_PROJECT_BLOCK_FORM.
 */
const createProjectFormReducer = (
    createProjectForm: CreateProjectFormValue = initCreateProjectFormValue,
    action: any) => {
  switch (action.type) {
    case 'SET_CREATE_PROJECT_BLOCK_FORM':
      return {...createProjectForm, ...action.payload.createProjectForm};
    case 'CLEAR_PROJECT_BLOCK_FORM':
      return initCreateProjectFormValue;
    default:
      return createProjectForm;
  }
};

export default createProjectFormReducer;

import Snippet from "./Snippet";
import * as Blockly from "blockly/core";
import generateContentFromWorkspace
  from "../Blockly/BlocklyLogic/generateContentFromWorkspace";

export default function generateContentFromSnippet(snippet: Snippet): string | undefined {
  const xml = snippet.xml as string;
  let workspace = new Blockly.Workspace();
  let res : string | undefined = undefined;
  try {
    Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(xml), workspace);
    res = generateContentFromWorkspace(workspace);
  } catch (err) {
    return undefined;
  }
  workspace.clear(); // TODO(odedf): Check if should be deleted.
  return res;
}

import React, { Component } from "react";
import { connect } from "react-redux";
// @ts-ignore
import { Redirect } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import Fab from "@material-ui/core/Fab";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import LinearProgress from "@material-ui/core/LinearProgress";
import "../../../App.css";
// @ts-ignore
import { AppContext } from "../../../api/ArenaBlockAPI.ts";
import ArenaAlert from "../../../components/ArenaAlert";
import CONSTANTS from "../../../common/Constants";
import SnippetEditorController
  // @ts-ignore
  from "../../../components/pages/work_area/SnippetEditorController.tsx";
import SnippetEditor
  // @ts-ignore
  from "../../../components/pages/work_area/snippet_editor/SnippetEditor.tsx";
import UnlockSnippetForm
  // @ts-ignore
  from "../../../components/forms/unlock_snippet_form/unlockSnippetForm.tsx"
import "../../../generator/generator";
import { loadSingleProjectFromBackend } from
  // @ts-ignore
  "../../../server_interface/loadAllProjectsFromBackend.ts"
import { clearAllMasks } from "../../../redux/actions/maskerActions";
import {
  setIsSnippetEditorOpen,
  setWorkAreaLoadingState,
  clearStackAndPushUUID,
  pushSnippetUUIDToStack
} from "../../../redux/actions/workAreaPageStateActions";
import UTILS from "../../../common/utils";
// @ts-ignore
import Snippet from "../../../common/Snippet";
import getCurrentSnippet from "../../../redux/logic/getCurrentSnippet";
import {
  setCurrentProject,
} from "../../../redux/actions/sessionStateActions";
import Box from "@material-ui/core/Box";
import {
  LoadingState,
  WorkAreaPageState
} from "../../../redux/reducers/workAreaPageStateReducer";
import getProject from "../../../redux/logic/getProject";
import Project from "../../../common/Project";
import initProjectSnippets, { initTemplateSnippetsFromBackend } from "../../../server_interface/initProjectSnippets";
import initCore from "./initCore";
import { SessionState } from "../../../redux/reducers/sessionStateReducer";

const theme = createTheme({
  typography: {
    body2: {
      fontFamily: 'monaco, Consolas, Lucida Console, monospace',
      fontSize: 14,
    },
  }
});

interface Props extends WorkAreaPageState {
  actuallyRenderProject: boolean;
  currentProjectUUID: string | null;
  headSnippetUUID: string;
  currentSnippet: Snippet;
  isDeployedSession: boolean;
  setIsSnippetEditorOpen: (value: boolean) => any;
  clearAllMasks: () => any;
  clearStackAndPushUUID: (snippetUUID: string) => any;
  pushSnippetUUIDToStack: (snippetUUID: string) => any;
  setCurrentProject: (currentProjectUUID: string) => any;
  setWorkAreaLoadingState: (loadingState: LoadingState,
    loadingProgress: number) => any;
  history: any;
}

type WorkAreaPageLocalState = {}

class WorkAreaPage extends Component<Props, WorkAreaPageLocalState> {

  static contextType = AppContext;

  constructor(props: Props) {
    super(props);
    WorkAreaPage.renderProgress = WorkAreaPage.renderProgress.bind(this);
  }

  static mapStateToProps(reduxState: any) {
    const workAreaPageState = reduxState.workAreaPageState;
    const sessionState: SessionState = reduxState.sessionState;
    return {
      ...workAreaPageState,
      currentProjectUUID: sessionState.currentProjectUUID,
      isDeployedSession: sessionState.isDeployedSession,
      currentSnippet: getCurrentSnippet(),
      headSnippetUUID: workAreaPageState.snippetUUIDStackEnd === 0 ? '' :
        workAreaPageState.snippetUUIDStack[0],
    }
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      setIsSnippetEditorOpen: (value: boolean) => {
        dispatch(setIsSnippetEditorOpen(value));
      },
      clearAllMasks: (snippet: Snippet) => {
        dispatch(clearAllMasks());
      },
      setCurrentProject: (currentProjectUUID: string) => {
        dispatch(setCurrentProject(currentProjectUUID));
      },
      setWorkAreaLoadingState: (loadingState: LoadingState,
        loadingProgress: number) => {
        dispatch(setWorkAreaLoadingState(loadingState, loadingProgress));
      },
      clearStackAndPushUUID: (snippetUUID: string) => {
        dispatch(clearStackAndPushUUID(snippetUUID));
      },
      pushSnippetUUIDToStack: (snippetUUID: string) => {
        dispatch(pushSnippetUUIDToStack(snippetUUID));
      }
    }
  }

  getProjectUUID(): string {
    let currentProjectUUID = null;
    // @ts-ignore
    if ("projectUUID" in this.props.match.params) {
      // @ts-ignore
      currentProjectUUID = this.props.match.params["projectUUID"];
    } else {
      currentProjectUUID = this.props.currentProjectUUID;
    }
    this.props.setCurrentProject(currentProjectUUID);
    return currentProjectUUID;
  }

  async componentDidUpdate(prevProps: any) {
    if (this.props.loadingState === LoadingState.INITIALIZED) {
      prevProps.setWorkAreaLoadingState(LoadingState.LOADING, 0);
      const currentProjectUUID = this.getProjectUUID();
      await loadSingleProjectFromBackend(currentProjectUUID);
      const currentProject: Project = getProject(currentProjectUUID) as Project;
      UTILS.assert(currentProject,
        `Not able to get project: ${currentProjectUUID}`);
      UTILS.assert(currentProject.rootSnippetUUID);
      await initTemplateSnippetsFromBackend();
      await initCore();
      await initProjectSnippets(currentProjectUUID);
      prevProps.setCurrentProject(currentProjectUUID);
      prevProps.clearStackAndPushUUID(currentProject.rootSnippetUUID);
      prevProps.setWorkAreaLoadingState(LoadingState.LOADED, 100);
    }
  }

  static renderProgress(progress: number) {
    const Progress = withStyles({
      root: {
        width: "50%",
        color: "#3453E2s",
      }
    })(LinearProgress);
    const Wrapper = withStyles({
      root: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 200,
        backgroundColor: "#FFFE"

      }
    })(Box);
    return <Wrapper><Progress variant="determinate"
      value={progress} /></Wrapper>;
  }

  render() {
    const projectUUID = this.getProjectUUID();
    if (!projectUUID) {
      return <Redirect to={{ pathname: `/dashboard`, }} />
    }
    const StyledFab = withStyles({
      root: {
        position: "fixed",
        bottom: 48,
        right: 48,
        color: "#676767",
        backgroundColor: "#E0E0E0",
        zIndex: 6400
      },
    })(Fab);
    const self = this;
    return <ThemeProvider theme={theme}>
      <div style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }}>
        {this.props.isSnippetEditorOpen &&
          <SnippetEditorController history={this.props.history} />}
        <UnlockSnippetForm />
        <div style={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          overflowY: 'hidden',
        }}>
          <div style={{
            minHeight: '100%',
            width: this.props.isSnippetEditorOpen ?
              CONSTANTS.SNIPPET_EDITOR_WIDTH_PERCENT + '%' : '0%',
          }}>
            <SnippetEditor />
          </div>
          {(this.props.loadingState === LoadingState.LOADING) &&
            WorkAreaPage.renderProgress(this.props.loadingProgress)}
          {this.props.loadingState === LoadingState.LOADED &&
            <div style={{
              minHeight: '100%',
              overflowY: 'auto',
              width: this.props.isSnippetEditorOpen ?
                (100 - CONSTANTS.SNIPPET_EDITOR_WIDTH_PERCENT) + '%' : '100%',
            }}>
              {this.props.headSnippetUUID && this.props.actuallyRenderProject &&
                UTILS.renderComponent(this.props.headSnippetUUID, {})}
            </div>}
        </div>
        <ArenaAlert />
        {(!this.props.isDeployedSession) && <StyledFab
          onClick={() => {
            self.props.setIsSnippetEditorOpen(!this.props.isSnippetEditorOpen);
          }}>
          {this.props.isSnippetEditorOpen ? <FullscreenIcon /> : <EditIcon />}
        </StyledFab>}

      </div>
    </ThemeProvider>
  }
}

// @ts-ignore
export default connect(WorkAreaPage.mapStateToProps, WorkAreaPage.mapDispatchToProps)(WorkAreaPage);

import UTILS from "../common/utils";
import {snippetToData} from "./BackendSnippet";
import Snippet from "../common/Snippet";
import buildSnippet from "../common/buildSnippet";
import store from "../store";

const axios = require('axios').default;

export default class SnippetCRUD {

  static async upsertSnippet(snippet: Snippet) {
    UTILS.assert(store.getState().sessionState.isLoggedIn);
    try {
      await SnippetCRUD.createSnippet(snippet)
    } catch (e) {
      await SnippetCRUD.updateSnippet(snippet);
    }
  }

  static async createSnippet(snippet: Snippet) {
    UTILS.assert(store.getState().sessionState.isLoggedIn);
    const SERVER_URL = UTILS.getServerAPIURL();
    return axios.post(SERVER_URL + '/snippets', snippetToData(snippet),
        {withCredentials: true});
  }

  static async updateSnippet(snippet: Snippet) {
    UTILS.assert(store.getState().sessionState.isLoggedIn);
    const SERVER_URL = UTILS.getServerAPIURL();
    return axios.put(SERVER_URL +
        `/snippets/${snippet.snippetUUID}`,
        snippetToData(snippet), {withCredentials: true});
  }

  static async getSnippet(snippetUUID: string): Promise<Snippet | undefined> {
    const SERVER_URL = UTILS.getServerAPIURL();
    const response = await axios.get(`${SERVER_URL}/snippets/${snippetUUID}`,
        {withCredentials: true});
    const data = response.data;
    if (response.data)
      return buildSnippet(data.snippet_uuid, data.type,
          data.is_content_generated_from_xml, data.xml, data.content, data.name,
          data.is_public, data.prerequisites);
    return undefined;
  }

  static async retrieveMultipleSnippet(requestedSnippetsUUID: Array<string>):
      Promise<Snippet[] | undefined> {
    const mapSnippetUUIDToResponse: Map<string, Snippet> = new Map();
    const SERVER_URL = UTILS.getServerAPIURL();
    try {
      const response = await axios.post(`${SERVER_URL}/snippets/retrieve_multiple_snippets`,
          {
            multiple_snippet_uuid: requestedSnippetsUUID
          }, {withCredentials: true});
      response.data.forEach((backendSnippet: any) => {
        const snippet: Snippet = buildSnippet(backendSnippet.snippet_uuid,
            backendSnippet.type,
            backendSnippet.is_content_generated_from_xml, backendSnippet.xml,
            backendSnippet.content, backendSnippet.name,
            backendSnippet.is_public, backendSnippet.prerequisites);
        mapSnippetUUIDToResponse.set(backendSnippet.snippet_uuid, snippet)
      });
    } catch (e) {
      console.log(e);
    }
    let foundAllSnippets : boolean = true;
    requestedSnippetsUUID.forEach(snippetUUID => {
      if (!mapSnippetUUIDToResponse.has(snippetUUID)) {
        console.warn("Could not download:", snippetUUID );
      }
      foundAllSnippets = foundAllSnippets && mapSnippetUUIDToResponse.has(snippetUUID);
    });

    if (!foundAllSnippets)
      return undefined;
    const res: Snippet[] = [];
    requestedSnippetsUUID.forEach(snippetUUID =>
      res.push(mapSnippetUUIDToResponse.get(snippetUUID) as Snippet));
    return res;
  }
}

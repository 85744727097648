import * as React from 'react'
import ActionsToolBox from "./actions/ActionsToolBox.jsx";
import UiComponentsToolBox from "./ui/UiComponentsToolBox.jsx"
import DataToolBox from "./data/DataToolBox.jsx";
// @ts-ignore
import ProjectsToolBox from "./examples/ProjectsToolBox.tsx";
import StyleToolBox from "./style/StyleToolBox.jsx";
import HTMLToolBox from "./ui/HTML/HTMLToolBox.jsx";
import {SessionState} from "../../redux/reducers/sessionStateReducer";
import {connect} from "react-redux";
import MaterialUIToolBox from "./ui/MaterialUIToolBox";

type Props = {
  isProductionSession: boolean,
};

type State = {};

class MainToolBox extends React.Component<Props, State> {
  // @ts-ignore
  static mapStateToProps(reduxState) {
    const sessionState: SessionState = reduxState.sessionState;
    return {
      isProductionSession: sessionState.isProductionSession,
    }
  }

  // @ts-ignore
  static mapDispatchToProps() {
    return {}
  }

  render() {
    let res = this.props.isProductionSession ? [
          <MaterialUIToolBox/>,
          // @ts-ignore
          <sep/>,
          <StyleToolBox/>,
          // @ts-ignore
          <sep/>,
          <ActionsToolBox/>,
          // @ts-ignore
          <sep/>,
          <DataToolBox/>,
        ] :
        [
          <UiComponentsToolBox/>,
          // @ts-ignore
          <sep/>,
          <StyleToolBox/>,
          // @ts-ignore
          <sep/>,
          <ActionsToolBox/>,
          // @ts-ignore
          <sep/>,
          <DataToolBox/>,
          // @ts-ignore
          <sep/>,
          <HTMLToolBox/>,
          // @ts-ignore
          <sep/>,
          <ProjectsToolBox/>,
          // @ts-ignore
          <sep/>];
    res.map((component, index) => (
        <React.Fragment key={index}>
          {component}
        </React.Fragment>
    ));
    return res;
  }
}

// @ts-ignore
export default connect(MainToolBox.mapStateToProps, MainToolBox.mapDispatchToProps)(MainToolBox);


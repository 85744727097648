import * as Blockly from "blockly/core";
import generateStyleCode from "../../generateStyleCode";

export function fabImplementationGenerator(block) {
  const iconType = block.getFieldValue('iconType');
  let currentBlock = this.getInputTargetBlock('action');
  let actionCode = ``;
  while (currentBlock) {
    actionCode += `${Blockly.JavaScript[currentBlock.type].call(currentBlock, currentBlock)};`;
    currentBlock = currentBlock.getNextBlock();
  }
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  let code = `class MUIFabImplementation extends BaseComponent {`;
  code += `Render() {`;
  code += `this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';`;
  code += `const self = this;`;
  code += generateStyleCode(block);
  code += `const StyledFab = withStyles({root: style})(Fab);`;
  code += `return `;
  code += `<StyledFab ref={self.componentReference} aria-label="home"`;
  code += `onClick={(event) => {${actionCode}}}`;
  code += `${content ? 'variant="extended"' : ''}>`;
  code += ` {context.evaluateFJSONExpressionWithRedux(${content})}`;
  code += iconType ? `< ${iconType}/>` : ``;
  code += `</StyledFab>`;
  code += `}}`;
  return code;
}
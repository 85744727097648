import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { Icon } from "@material-ui/core";
import NetlifyIcon from "../../../netlify/netlify-icon.svg";
import AccountTree from '@material-ui/icons/AccountTree';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ApiIcon from '@material-ui/icons/School';
import ExtensionIcon from '@material-ui/icons/Extension';
import GithubIcon from '@material-ui/icons/GitHub';
import HomeIcon from '@material-ui/icons/Home';
import LockIcon from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PublishIcon from '@material-ui/icons/Publish';
import HelpIcon from '@material-ui/icons/Help';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import { TextField } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { connect } from 'react-redux';
import { AppContext } from "../../../api/ArenaBlockAPI";
import UTILS from "../../../common/utils";
import Snippet from "../../../common/Snippet";
import AccountIcon from "../../AccountIcon";
import addSnippets, { SnippetTarget } from "../../../common/addSnippets";
import { setAlert } from "../../../redux/actions/actions";
import { setHelpForm } from "../../../redux/actions/forms/helpFormActions";
import { setUnlockSnippetForm } from "../../../redux/actions/forms/unlockSnippetActioon";
import { upsertSnippet } from "../../../redux/actions/persistentStateActions";
import {
  moveBackInStack, moveForwardInStack, setUpdateUncontrolledBlocklyEditor,
  setWorkAreaPageState
} from '../../../redux/actions/workAreaPageStateActions';
import { AlertOptions } from '../../../redux/reducers/alertReducer';
import { UnlockSnippetFormValue } from "../../../redux/reducers/forms/unlockSnippetReducer";
import { SessionState } from "../../../redux/reducers/sessionStateReducer";
import { WorkAreaPageState } from "../../../redux/reducers/workAreaPageStateReducer";
import commitToGithub, { GithubCommitResult } from "../../../netlify/commitToGithub";
import { publish } from "../../../netlify/publish";
import { injectBlocks } from "../../../Blockly/BlocklyLogic/injectBlocks";
import BackendActions from "../../../server_interface/BackendActions";

const useStyles = () => ({
  toggle: {
    marginRight: 8,
    marginLeft: 8,
    height: 40,
    width: 40,
  },
});

interface Props extends WorkAreaPageState {
  allSnippets: object;
  isControllerDisabled: boolean;
  currentSnippet: Snippet | undefined;
  currentProjectUUID: string | null;
  isGitSession: boolean;
  isNetlifySession: boolean;
  blocksToggleButtonDisabled: boolean;
  contentButtonDisabled: boolean;
  editedSnippetUUID: string;
  disableForwardArrow: boolean;
  disableBackArrow: boolean;
  XMLButtonDisabled: boolean;
  history: any;
  isProductionSession: boolean;
  classes: any;
  unlockSnippet: () => any;
  moveBackInStack: () => any;
  moveForwardInStack: () => any;
  upsertSnippet: (snippet: Snippet) => any;
  controllerSnippetLocked: boolean;
  setUnlockSnippetForm: (unlockSnippetFormValue: UnlockSnippetFormValue) => any;
  setAlert: (arenaAlertOptions: AlertOptions) => any;
  openHelpForm: () => any;
  setUpdateUncontrolledBlocklyEditor: (value: boolean) => any,
  setWorkAreaPageState: (workAreaPageState: any) => any;
  highlightComponentSnippetUuid: string | null;
}

interface State {
  doesTextFieldHaveFocus: boolean,
  snippetNameTextFieldValue: string,
}

const BLOCKS_TOGGLE_VALUE = "BLOCKS_TOGGLE_VALUE";
const CODE_TOGGLE_VALUE = "CODE_TOGGLE_VALUE";
const XML_TOGGLE_VALUE = "XML_TOGGLE_VALUE";
const SNIPPET_NAME_TEXT_FIELD_KEY =
  "SNIPPET_NAME_TEXT_FIELD_KEY";

class SnippetEditorController extends React.Component<Props, State> {

  static contextType = AppContext;
  private snippetNameTextFieldRef: React.RefObject<unknown>;

  constructor(props: Props) {
    super(props);
    this.state = {
      doesTextFieldHaveFocus: false,
      snippetNameTextFieldValue: ""
    };
    this.snippetNameTextFieldRef = React.createRef();
    this.handleToggleChange = this.handleToggleChange.bind(this);
    this.unlockSnippet = this.unlockSnippet.bind(this);
    this.build = this.build.bind(this);
    this.onSnippetNameBlur = this.onSnippetNameBlur.bind(this);
    this.onSnippetNameFocus = this.onSnippetNameFocus.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.moveBackInStack = this.moveBackInStack.bind(this);
    this.moveForwardInStack = this.moveForwardInStack.bind(this);
    this.commitToGithub = this.commitToGithub.bind(this);
    this.commitToNetlify = this.commitToNetlify.bind(this);
  }

  static mapStateToProps(reduxState: any) {
    const workAreaPageState: WorkAreaPageState = reduxState.workAreaPageState;
    const sessionState: SessionState = reduxState.sessionState;
    const arenaController = reduxState.arenaController;
    const snippetUUIDStack: Array<string> = workAreaPageState.snippetUUIDStack;
    const snippetUUIDStackEnd = workAreaPageState.snippetUUIDStackEnd;
    const allSnippets: object = reduxState.persistentState.allSnippets;
    UTILS.assert(snippetUUIDStackEnd >= 0);
    UTILS.assert(snippetUUIDStackEnd <= snippetUUIDStack.length);
    return {
      ...workAreaPageState,
      allSnippets: allSnippets,
      currentProjectUUID: sessionState.currentProjectUUID,
      isProductionSession: sessionState.isProductionSession,
      isGitSession: sessionState.isGitSession,
      isNetlifySession: sessionState.isNetlifySession,
      disableForwardArrow: snippetUUIDStackEnd === snippetUUIDStack.length,
      disableBackArrow: snippetUUIDStackEnd <= 1,
      blocksToggleButtonDisabled: arenaController.controllerBlocksToggleButtonDisabled,
      contentButtonDisabled: arenaController.controllerContentButtonDisabled,
      XMLButtonDisabled: arenaController.controllerXMLButtonDisabled,
      controllerSnippetLocked: arenaController.controllerSnippetLocked,
      isControllerDisabled: arenaController.isControllerDisabled,
      highlightComponentSnippetUuid: reduxState.maskerState.highlightComponentSnippetUuid,
      currentSnippet: workAreaPageState.snippetUUIDStackEnd === 0 ? undefined :
        // @ts-ignore
        allSnippets[
        workAreaPageState.snippetUUIDStack[workAreaPageState.snippetUUIDStackEnd - 1]],
    }
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      setWorkAreaPageState: (workAreaPageState: WorkAreaPageState) => {
        dispatch(setWorkAreaPageState(workAreaPageState));
      },
      upsertSnippet: (snippet: Snippet) => {
        dispatch(upsertSnippet(snippet, true));
      },
      moveForwardInStack: () => {
        dispatch(moveForwardInStack());
      },
      moveBackInStack: () => {
        dispatch(moveBackInStack());
      },
      setUnlockSnippetForm: (unlockSnippetFormValue: UnlockSnippetFormValue) => {
        dispatch(setUnlockSnippetForm(unlockSnippetFormValue));
      },
      openHelpForm: () => {
        dispatch(setHelpForm({
          isOpen: true
        }));
      },
      setAlert: (arenaAlertOptions: AlertOptions) => {
        dispatch(setAlert(arenaAlertOptions));
      },
      setUpdateUncontrolledBlocklyEditor: (value: boolean) => {
        dispatch(setUpdateUncontrolledBlocklyEditor(value));
      },
    }
  }

  handleToggleChange(event: any, newFormats: any) {
    const WYSIWYGEditorOpen = newFormats.includes(CODE_TOGGLE_VALUE) ||
      newFormats.includes(XML_TOGGLE_VALUE);
    const WYSIWYGIsContentContent = newFormats.includes(CODE_TOGGLE_VALUE);
    const newWorkAreaPageState = {
      ...{ blocklyEditorOpen: newFormats.includes(BLOCKS_TOGGLE_VALUE) },
      ...{ WYSIWYGEditorOpen },
      ...{ WYSIWYGIsContentContent }
    };
    this.props.setWorkAreaPageState(newWorkAreaPageState);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>,
    snapshot?: any): void {
    const snippet: Snippet | null = this.getNameTextFieldSnippet();
    if (snippet) {
      const newValue: string = (snippet as Snippet).name;
      if ((!this.state.doesTextFieldHaveFocus) &&
        this.state.snippetNameTextFieldValue !== newValue) {
        this.setState({ snippetNameTextFieldValue: newValue });
      }
    }
  }

  render() {
    const self = this;
    const StyledAppBar = withStyles({ root: { backgroundColor: "#FFF" }, })
      (AppBar);
    const selectedToggles = [...(this.props.blocklyEditorOpen ? [BLOCKS_TOGGLE_VALUE] : []),
    ...(this.props.WYSIWYGEditorOpen && this.props.WYSIWYGIsContentContent ? [CODE_TOGGLE_VALUE] :
      []),
    ...(this.props.WYSIWYGEditorOpen && !this.props.WYSIWYGIsContentContent ? [XML_TOGGLE_VALUE] :
      [])];
    const { classes } = this.props;
    const textFieldSnippet = this.getNameTextFieldSnippet();
    const textFieldSnippetName = textFieldSnippet ? textFieldSnippet.name : '';
    const FabGapStyle = {
      display: 'flex',
      alignItems: "center",
      marginLeft: 4,
      minHeight: 64,
    }
    return <StyledAppBar position="static">
      <Toolbar>
        <Tooltip title="Home">
          <span>
            <IconButton
              disabled={this.props.isControllerDisabled}
              onClick={() => {
                this.props.history.push('/');
              }}>
              <HomeIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Dashboard">
          <span>
            <IconButton
              disabled={this.props.isControllerDisabled}
              onClick={() => {
                this.props.history.push('/dashboard');
              }}>
              <DashboardIcon />
            </IconButton>
          </span>
        </Tooltip>
        <div style={FabGapStyle} />
        <Tooltip title="Previous Snippet">
          <span>
            <IconButton onClick={this.moveBackInStack}
              disabled={this.props.isControllerDisabled ||
                this.props.disableBackArrow}>
              <ArrowBackIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title="Next Snippet">
          <span>
            <IconButton onClick={this.moveForwardInStack}
              disabled={this.props.isControllerDisabled ||
                this.props.disableForwardArrow}>
              <ArrowForwardIcon />
            </IconButton>
          </span>
        </Tooltip>
        <div style={FabGapStyle} />
        <ToggleButtonGroup exclusive={true} style={{ marginRight: 16 }}
          value={selectedToggles}
          onChange={self.handleToggleChange}>
          <ToggleButton
            disabled={this.props.isControllerDisabled ||
              this.props.blocksToggleButtonDisabled}
            value={BLOCKS_TOGGLE_VALUE}
            className={classes.toggle}>
            <ExtensionIcon />
          </ToggleButton>
          <ToggleButton
            disabled={this.props.isControllerDisabled ||
              this.props.contentButtonDisabled}
            style={{ marginRight: 4, borderLeft: "1px solid #DDD" }}
            value={CODE_TOGGLE_VALUE} className={classes.toggle}>
            <CodeIcon />
          </ToggleButton>
          {!this.props.isProductionSession &&
            <ToggleButton disabled={this.props.isControllerDisabled ||
              this.props.XMLButtonDisabled}
              style={{ marginRight: 4, borderLeft: "1px solid #DDD" }}
              value={XML_TOGGLE_VALUE} className={classes.toggle}>
              <AccountTree />
            </ToggleButton>}
        </ToggleButtonGroup>
        <div style={FabGapStyle} />
        {this.props.controllerSnippetLocked && <Tooltip title="Unlock snippet">
          <span>
            <IconButton disabled={this.props.isControllerDisabled}
              onClick={self.unlockSnippet}>
              <LockIcon />
            </IconButton>
          </span>
        </Tooltip>}
        {!this.props.controllerSnippetLocked &&
          <Tooltip title="Snippet is open">
            <span>
              <IconButton disabled={this.props.isControllerDisabled || true}>
                <LockOpen />
              </IconButton>
            </span>
          </Tooltip>}
        <Tooltip title="Run">
          <span>
            <IconButton
              disabled={this.props.isControllerDisabled}
              onClick={this.build}>
              <PlayArrowIcon />
            </IconButton>
          </span>
        </Tooltip>
        <div style={FabGapStyle} />
        <div style={{ flexGrow: 1 }} />
        <div style={{
          width: 400,
        }}>
          <TextField fullWidth
            id="standard-basic"
            variant="standard"
            label="Snippet Name"
            size="small"
            value={this.state.snippetNameTextFieldValue}
            inputRef={this.snippetNameTextFieldRef}
            autoFocus={this.state.doesTextFieldHaveFocus}
            onFocus={self.onSnippetNameFocus}
            onBlur={self.onSnippetNameBlur}
            onChange={(e) => {
              self.setState(
                { snippetNameTextFieldValue: e.target.value });
            }}
            key={SNIPPET_NAME_TEXT_FIELD_KEY}
            onKeyDown={self.onKeyDown}
          />
        </div>
        <div style={FabGapStyle} />
        {(!this.props.isNetlifySession) && <Tooltip title="Publish">
          <span>
            <IconButton
              disabled={this.props.isControllerDisabled}
              onClick={() => publish(
                this.props.currentProjectUUID as string)}>
              <PublishIcon />
            </IconButton>
          </span>
        </Tooltip>}
        {this.props.isNetlifySession && <>
          <div style={FabGapStyle} />
          <Icon style={{ padding: 12, cursor: 'pointer' }} onClick={() => this.commitToNetlify(
            this.props.currentProjectUUID as string)}>
            <img src={NetlifyIcon} height={24} />
          </Icon>
        </>
        }
        {this.props.isGitSession && <>
          <div style={FabGapStyle} />
          <Tooltip title="Commit to github">
            <span>
              <IconButton
                disabled={this.props.isControllerDisabled}
                onClick={() => this.commitToGithub(
                  this.props.currentProjectUUID as string)}>
                <GithubIcon />
              </IconButton>
            </span>
          </Tooltip>
        </>
        }
        <div style={FabGapStyle} />
        <Tooltip title="Help">
          <span>
            <IconButton
              disabled={this.props.isControllerDisabled}
              onClick={this.props.openHelpForm}>
              <HelpIcon />
            </IconButton>
          </span>
        </Tooltip>
        {!this.props.isProductionSession && <>
          <div style={FabGapStyle} />
          <IconButton
            disabled={this.props.isControllerDisabled}
            onClick={async () => {
              await BackendActions.doAction();
            }}>
            <ApiIcon />
          </IconButton>
        </>
        }
        <div style={FabGapStyle} />
        <AccountIcon history={this.props.history} />
      </Toolbar>
    </StyledAppBar >;
  }

  unlockSnippet() {
    if (this.props.currentSnippet) {
      const unlockSnippetFormValue: UnlockSnippetFormValue = {
        confirmed: false,
        unlockSnippetUUID: "",
        isOpen: true
      };
      this.props.setUnlockSnippetForm(unlockSnippetFormValue);
    }
  }

  build() {
    // @ts-ignore
    const workspace = global.context.workspace;
    UTILS.assert(workspace);
    UTILS.assert(this.props.currentSnippet !== undefined);
    addSnippets([this.props.currentSnippet as Snippet],
      true, true,
      [SnippetTarget.REDUX, SnippetTarget.GLOBAL],
      workspace, null);
  }

  getNameTextFieldSnippet(): Snippet | null {
    const highlightComponentSnippetUuid = this.props.highlightComponentSnippetUuid;
    const lastSnippetUUID: string = this.props.snippetUUIDStackEnd === 0 ? "" :
      this.props.snippetUUIDStack[this.props.snippetUUIDStackEnd - 1];
    if (!highlightComponentSnippetUuid && (!lastSnippetUUID))
      return null;
    let snippetUUID: string = highlightComponentSnippetUuid !== null ?
      highlightComponentSnippetUuid : lastSnippetUUID;
    // @ts-ignore
    return this.props.allSnippets[snippetUUID];
  }

  onSnippetNameFocus(): void {
    if (!this.state.doesTextFieldHaveFocus) {
      this.setState({ doesTextFieldHaveFocus: true });
    }
  }

  async onSnippetNameBlur() {
    if (this.state.doesTextFieldHaveFocus) {
      this.updateSnippetName();
    }
  }

  onKeyDown(e: any): void {
    if (e.keyCode === 13) {
      this.updateSnippetName();
    }
  }

  updateSnippetName() {
    this.setState({ doesTextFieldHaveFocus: false });
    const snippet: Snippet = this.getNameTextFieldSnippet() as Snippet;
    this.props.upsertSnippet({
      ...snippet,
      ...{ name: this.state.snippetNameTextFieldValue }
    });
    this.props.setUpdateUncontrolledBlocklyEditor(true);
    injectBlocks(snippet.snippetUUID, snippet.type,
      this.state.snippetNameTextFieldValue);
  }

  moveBackInStack() {
    this.build();
    this.props.moveBackInStack();
  }

  moveForwardInStack() {
    this.build();
    this.props.moveForwardInStack();
  }

  async commitToGithub(currentProjectUUID: string) {
    const res: GithubCommitResult = await commitToGithub(currentProjectUUID);
    const alertSeverity = res.success ? "success" : "error";
    const alertTitle = res.success ? "Commit sucseeded" : "Comit failed";
    this.props.setAlert({
      alertContent: res.msg,
      alertDurationMsc: -1,
      alertSeverity,
      alertTitle
    });
  }

  async commitToNetlify(currentProjectUUID: string) {
    const res: GithubCommitResult = await commitToGithub(currentProjectUUID);
    const alertSeverity = res.success ? "success" : "error";
    const alertTitle = res.success ? "Publish on Netlify sucseeded" : "Publish on Netlify failed";
    const publishedURL = process.env.REACT_APP_NETLIFY_TARGET;
    const alertContent = res.success ? `Published on netlify: See ${publishedURL}. ` +
     `Important: Deployment might take a couple of minutes.` : 'res.msg';
    this.props.setAlert({
      alertContent,
      alertDurationMsc: -1,
      alertSeverity,
      alertTitle
    });
    if (res.success) {
      window.open(publishedURL, "_blank");
    }
  }

}

export default connect(SnippetEditorController.mapStateToProps,
  SnippetEditorController.mapDispatchToProps)(
    withStyles(useStyles)(SnippetEditorController));
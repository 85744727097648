import * as Blockly from "blockly/core";
import extractSnippetUUIDFromCode from "../../extractSnippetUUIDFromCode";

export function sliderImplementationGenerator(block) {
  const properties = Blockly.JavaScript.statementToCode(block, 'properties');
  const varSnippetUUID = extractSnippetUUIDFromCode(
      Blockly.JavaScript.statementToCode(block, 'variable'));
  let code = "class SliderImplementation extends BaseComponent {";
  code += "render() {";
  code += `const StyledSlider = withStyles(  {root:{color: "#3453E2",}})(Slider);`;
  code += `let value = null;`;
  code += `if ("${varSnippetUUID}")`;
  code += `  {`;
  code += `  value = context.evaluateDataSnippet("${varSnippetUUID}");`;
  code += `  }`;
  code += `return <StyledSlider`;
  code +=
      ` onChange={(event, newValue) =>  {context.setDataSnippet("${varSnippetUUID}", newValue);}}`;
  code += ` defaultValue={30}`;
  code += ` value={value}`;
  code += ` valueLabelDisplay="auto"`;
  code += ` ${properties} />`;
  code += `}}`;
  return code;
}

export function sliderDisableGenerator() {
  return ` disabled`;
}

export function sliderMarksGenerator() {
  return ` marks`;
}

export function sliderRangeGenerator(block) {
  let min = Blockly.JavaScript.statementToCode(block, 'min');
  let max = Blockly.JavaScript.statementToCode(block, 'max');
  min = min ? min : 0;
  max = max ? max : min + 100;
  return ` min={${min}} max={${max}}`;
}

export function sliderStepGenerator(block) {
  let step = Blockly.JavaScript.statementToCode(block, 'step');
  step = step ? step : 10;
  return ` step={${step}}  `;
}

export function sliderDefaultValueGenerator(block) {
  let defaultValue = Blockly.JavaScript.statementToCode(block, 'defaultValue');
  defaultValue = defaultValue ? defaultValue : 1;
  return ` defaultValue={${defaultValue}}  `;
}


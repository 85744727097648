import React, {Component} from "react";
import {connect} from "react-redux";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {withStyles} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {setCreateProjectForm} from "../../../redux/actions/forms/createProjectFormActions";
import AccountIcon from "../../AccountIcon";
import {setAboutForm} from "../../../redux/actions/forms/aboutFormActions";
const StyledAppBar = withStyles({root: {backgroundColor: "#FFF"}})(AppBar);
const StyledMenuItem = withStyles({root: {color: "#000"}})(MenuItem);

const useStyles = () => ({
  page: {
    height: "100vh",
    width: '100vw',
    padding: 0,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  body: {
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
  },
});

interface Props {
  setCreateProjectForm: (form: any) => any;
  setAboutForm: (form: any) => any;
}

type State = {
  aboutMenuOpen: boolean,
  anchorEl: HTMLElement | null,
}

class HomePage extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = {
      anchorEl: null,
      aboutMenuOpen: false,
    };
    this.handleMainButtonClick = this.handleMainButtonClick.bind(this);
  }

  // @ts-ignore
  static mapDispatchToProps(dispatch) {
    return {
      setCreateProjectForm: (form: any) => {
        dispatch(setCreateProjectForm(form));
      },
      setAboutForm: (aboutForm: any) => {
        dispatch(setAboutForm(aboutForm));
      },
    }
  }

  handleMainButtonClick() {
    // @ts-ignore
    this.props.history.push('/dashboard')
  }

  render() {
    // @ts-ignore
    const {classes} = this.props;
    const self = this;
    return <div className={classes.page}>
      <StyledAppBar position="static">
        <Toolbar>
          <div style={{flexGrow: 1}}/>
          <Button
              endIcon={<KeyboardArrowDownIcon/>}
              onClick={(e) => {
                e.stopPropagation();
                self.setState(
                    {
                      anchorEl: e.currentTarget,
                      aboutMenuOpen: true
                    });
              }}
          >
            About
          </Button>
          <Popper
              open={self.state.aboutMenuOpen}
              anchorEl={self.state.anchorEl}
              role={undefined}
              placement="bottom-start"
              transition
              disablePortal
          >
            <ClickAwayListener onClickAway={() => {
              self.setState({aboutMenuOpen: false});
            }}>
              <Paper>
                <MenuList>
                  <StyledMenuItem
                      onClick={() => {
                        self.props.setAboutForm({isOpen: true});
                      }}>
                    About
                  </StyledMenuItem>
                  <StyledMenuItem
                      onClick={() => {
                        window.open(
                            "https://sites.google.com/thecodingarena.com/terms-of-usage", "_blank");
                        self.setState({aboutMenuOpen: false});
                      }}>
                    Terms Of Use
                  </StyledMenuItem>
                  <StyledMenuItem
                      onClick={() => {
                        window.open(
                            "https://sites.google.com/thecodingarena.com/privacy-policy", "_blank");
                        self.setState({aboutMenuOpen: false});
                      }}>
                    Privacy Policy
                  </StyledMenuItem>
                </MenuList>
              </Paper>
            </ClickAwayListener>
          </Popper>
          <AccountIcon/>
        </Toolbar>
      </StyledAppBar>
      <div className={classes.body}>
        <Box>
          <Typography variant="h3" display="inline">Build </Typography>
          <Typography variant="h3" display="inline"
                      style={{
                        color: "#218F68", animationName: "flickerAnimation",
                        animationIterationCount: "infinite",
                        animationTimingFunction: "linear",
                        animationDuration: "8s",
                      }}>Advanced </Typography>
          <Typography variant="h3" display="inline">Web Apps with </Typography>
          <Typography variant="h3" display="inline">Ease</Typography>
          <Typography variant="h3" display="inline">(*)</Typography>
        </Box>
        <br/>
        <br/>
        <br/>
        <br/>
        <Box>
          <Typography variant="h6" display="inline">(*) Seriously!</Typography>
        </Box>
        <br/>
        <br/>
        <br/>
        <br/>
        <Button variant="outlined" onClick={this.handleMainButtonClick}>Start
          Building for Free</Button>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
      </div>
    </div>
  }
}

//
//
// @ts-ignore
export default connect(HomePage.mapStateToProps, HomePage.mapDispatchToProps)(withStyles(useStyles)(HomePage));

/** @module generator */

import React from "react";

import UTILS from "../common/utils";
import * as Blockly from 'blockly/core';
import 'blockly/javascript';
import extractSnippetUUIDFromCode from './extractSnippetUUIDFromCode';
import { dataGridImplementation, dataGridColumn, dataGridColumnDetailed, muiDataGridRowHeightPropertyGenerator }
  from "./ui/dataGridGenerator";
import { radioButtonsImplementation, radioButtonLabel } from "./ui/inputs/radioButtonGenerator";
import {
  checkboxImplementation, checkboxSizeProperty,
  checkboxSizePropertyValue, checkboxDisabled
} from "./ui/inputs/checkboxGenerator";
import { boxImplementationGenerator, rootBoxImplementationGenerator } from "./ui/layout/boxGenerator";
import { fabImplementationGenerator } from "./ui/inputs/fabGenerator";
import {
  buttonImplementationGenerator, startIconPropertyImplementationGenerator,
  endIconPropertyImplementationGenerator, muiButtonVariantPropertyGenerator
} from "./ui/inputs/buttonGenerator";
import { textFieldImplementation } from "./ui/inputs/textFieldGenerator";
import {
  sliderImplementationGenerator, sliderDisableGenerator, sliderMarksGenerator,
  sliderRangeGenerator, sliderStepGenerator, sliderDefaultValueGenerator
} from "./ui/inputs/sliderGenerator";
import { switchImplementation, switchSmall, switchDisabled } from "./ui/inputs/switchGenerator";
import { projectImplementation, rootImplementation } from "./ui/projectGenerator";
import {
  setMargin,
  setMarginDetailed,
  setMarginTop,
  setMarginBottom
} from "./style/marginsGenerator";
import {
  styleColor, styleBackgroundColor, styleColorPicker,
  styleGreyPicker
} from "./style/colorGenerator";
import { styleImplementation } from "./style/styleGenerator";
import { setPadding, setPaddingDetailed } from "./style/paddingsGenerator";
import {
  pushDataToArrayGenerator, alertImplementationGenerator, clearArrayGenerator,
  filterArrayImplementationGenerator
} from "./action/arrayActionGenertor";
import {
  generateUuidImplementationGenerator,
  codexParseImplementation,
  createArrayOfMapsImplementation
} from "./action/miscGenerators";
import { styleHeight, styleWidth } from "./style/fitAndSizeGenerator";
import { tabsImplementation, muiTab } from "./ui/navigation/tabs";
import { muiImageImplementation } from "./ui/data_display/imageGenerator";
import { muiTypographyImplementation } from "./ui/data_display/typographyGenerator";
import { muiCardImplementation, muiCardIMedia } from "./ui/surfaces/cardImplementationGenertor"
import {
  paperElevation,
  paperOutlined,
  muiPaperImplementation,
  paperSquare
} from "./ui/surfaces/papermplementationGenertor"
import {
  muiGridContainerImplementation,
  muiGridItemDetailed,
  muiGridItemLean
} from "./ui/layout/gridGenerator";
import generateStyleCode from "./generateStyleCode";

// File specific style guide + coding style.
// For generated code use the `` quotes.
// Do not use the `/n` code within generated code.

// DATA BLOCKS
// All data blocks represent data.
// For such blocks content is generated by a line similar to the following:
//   content = Blockly.JavaScript.statementToCode(block, 'content');
// The return value is always a json string. JSON.stringify(FJSONObject).
// The return value can be evaluated directly via evaluateJSONFunction(JSON.parse(jsn)).
// The return value can be saved directly in a DataSnippet.

// STYLE BLOCKS
// All style blocks return an entry of an object such as
// `backgroundColor:context.evaluateFJSONExpressionWithRedux(${value}),`
// or a multiplicity of such lines.

/** @function
 * @name data_primitive_true
 * @description Generator for a block representing a number value.
 * @returns string
 */
Blockly.JavaScript['data_primitive_number'] = function (block) {
  return JSON.stringify(block.getFieldValue('value'));
};

Blockly.JavaScript['string_block'] = function (block) {
  return JSON.stringify(block.getFieldValue('content'));
};

/** @function
 * @name data_primitive_true
 * @description Generator for a block representing the true value.
 * @returns string
 */
Blockly.JavaScript['data_primitive_true'] = function () {
  return JSON.stringify(`true`);
};

/** @function
 * @name data_primitive_false
 */
Blockly.JavaScript['false'] = function () {
  return JSON.stringify(`true`);
};

// TODO(odedf): Test with compound object.
Blockly.JavaScript['get_from_object_action'] = function (block) {
  let key = Blockly.JavaScript.statementToCode(block, 'key');
  let from = Blockly.JavaScript.statementToCode(block, 'from');
  from = from ? from : {};
  key = key ? key : {};
  let code = ``;
  code += `let from = context.evaluateFJSONExpressionWithRedux(${from});`;
  code += `let key = context.evaluateFJSONExpressionWithRedux(${key});`;
  code += `return from[key];`;
  return `(() => {${code}})()`;
};

// TODO(odedf): Test with compound object.
Blockly.JavaScript['get_from_datagrid_row_action'] = function (block) {
  let key = Blockly.JavaScript.statementToCode(block, 'key');
  key = key ? key : {};
  let code = ``;
  code += `let key = context.evaluateFJSONExpressionWithRedux(${key});`;
  code += `return context.getTempVar()[key];`;
  return `(() => {${code}})()`;
};

/** @description Generator for a block representing the false value.
 * @returns string
 */
Blockly.JavaScript['data_primitive_false'] = function () {
  return JSON.stringify(`false`);
};

/** @function
 * @name data_primitive_undefined
 * @description Generator for a block representing the false value.
 * @returns string
 */
Blockly.JavaScript['data_primitive_undefined'] = function () {
  return JSON.stringify(`undefined`);
};

/** @function
 * @name data_primitive_undefined
 * @description Generator for a block representing the null value.
 * @returns string
 */
Blockly.JavaScript['data_primitive_null'] = function () {
  return JSON.stringify(`null`);
};

Blockly.JavaScript['img_clock'] = function () {
  return '"../media/images/clock.png"';
};

Blockly.JavaScript['img_complicated'] = function () {
  return '"../media/images/complicated.jpg"';
};

Blockly.JavaScript['img_lizard'] = function () {
  return '"../media/images/lizard.jpg"';
};

Blockly.JavaScript['react_logo'] = function () {
  return '"../media/images/react_logo.svg"';
};

Blockly.JavaScript['img_surfers'] = function () {
  return '"../media/images/surfers.jpg"';
};

Blockly.JavaScript['img_james_webb'] = function () {
  return '"https://live-production.wcms.abc-cdn.net.au/ccbed5bb6ce614a23e430efa75f40bce?src"';
};

Blockly.JavaScript['img_arena'] = function () {
  return '"../media/images/arena.jpg"';
};

Blockly.JavaScript['img_codex_shape'] = function () {
  return '"../codex/Shape.png"';
};

Blockly.JavaScript['csv_codex_connections'] = function () {
  return '"../codex/connections.csv"';
};

Blockly.JavaScript['csv_codex_features'] = function () {
  return '"../codex/features.csv"';
};

Blockly.JavaScript['data_array_implementation'] = function (block) {
  // See https://groups.google.com/g/blockly/c/uXewhtr-mvM
  let currentBlock = this.getInputTargetBlock('content');
  let res = '[';
  while (currentBlock) {
    res += Blockly.JavaScript[currentBlock.type].call(currentBlock, currentBlock);
    currentBlock = currentBlock.getNextBlock();
    res += currentBlock ? ', ' : '';
  }
  res += ']';
  return res;
};

Blockly.JavaScript['data_object_implementation'] = function (block) {
  // See https://groups.google.com/g/blockly/c/uXewhtr-mvM
  let currentBlock = this.getInputTargetBlock('content');
  let res = '{';
  while (currentBlock) {
    res += Blockly.JavaScript[currentBlock.type].call(currentBlock, currentBlock);
    currentBlock = currentBlock.getNextBlock();
    res += currentBlock ? ', ' : '';
  }
  res += '}';
  return res;
};

Blockly.JavaScript['data_key_value'] = function (block) {
  return `${Blockly.JavaScript.statementToCode(block, 'key')}:
  ${Blockly.JavaScript.statementToCode(block, 'value')}`;
};

Blockly.JavaScript['https_link'] = function (block) {
  return JSON.stringify(`https://${block.getFieldValue('content')}`);
};

Blockly.JavaScript['http_link'] = function (block) {
  return JSON.stringify(`http://${block.getFieldValue('content')}`);
};

// UI BLOCKS

Blockly.JavaScript['ui_implementation'] = function (block) {
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  let code = "class MUIBox extends BaseComponent {";
  code += "Render() {";
  code += "const self = this;";
  code += "this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';";
  code += "const StyledBox = withStyles({";
  code += "root: {";
  code += "},})(Box);";
  code += `return <StyledBox ref={self.componentReference}>`;
  code += `${content} </StyledBox>}}`;
  return code;
};

Blockly.JavaScript['new_ui_styled_typography_implementation'] = function (block) {
  let content = Blockly.JavaScript.statementToCode(block, 'content');
  content = content.replace(/(\r\n|\n|\r)/gm, ' ');
  let code = `class Foo extends BaseComponent {`;
  code += `Render() {`;
  code += `this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';`;
  code += `const self = this;`;
  code += generateStyleCode(block);
  code += `return <div ref={self.componentReference} style={style}> `;
  code += `{ReactHtmlParser(context.evaluateFJSONExpressionWithRedux(${content}))}</div>`;
  code += `}}`;
  return code;
};

Blockly.JavaScript['mui_typography'] = function (block) {
  const properties = Blockly.JavaScript.statementToCode(block, 'properties');
  const variant = block.getFieldValue('variant');
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  return `<Typography variant='${variant}' ${properties}>` +
    `{context.evaluateFJSONExpressionWithRedux(${content})}</Typography>`;
};

Blockly.JavaScript['mui_link_implementation'] = function (block) {
  const properties = Blockly.JavaScript.statementToCode(block, 'properties');
  const href = Blockly.JavaScript.statementToCode(block, 'href');
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  let code = `class MUILInk extends BaseComponent {`;
  code += `Render() {`;
  code += `this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';`;
  code += `const content = context.evaluateFJSONExpressionWithRedux(${content});`;
  code += `const href = context.evaluateFJSONExpressionWithRedux(${href});`;
  code += generateStyleCode(block);
  code += ` style.lineHeight = "normal";`;
  //code += ` return <div>Foo</div>}}`;
  code += `return <a `;
  code += (href ? ` href={href} ` : ``);
  code += ` ${properties}`;
  code += ` style={style}`;
  code += `>{content}</a>}}`;
  return code;
};

Blockly.JavaScript['mui_grid_container_implementation'] = function (block) {
  return (muiGridContainerImplementation.bind(this))(block);
};

Blockly.JavaScript['mui_grid_item_lean'] = function (block) {
  return (muiGridItemLean.bind(this))(block);
};

Blockly.JavaScript['mui_grid_item_detailed'] = function (block) {
  return (muiGridItemDetailed.bind(this))(block);
};

Blockly.JavaScript['mui_dialog_implementation'] = function (block) {
  const styleCode = Blockly.JavaScript.statementToCode(block, 'style');
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  const title = Blockly.JavaScript.statementToCode(block, 'title');
  const actions = Blockly.JavaScript.statementToCode(block, 'actions');
  const properties = Blockly.JavaScript.statementToCode(block, 'properties');
  let code = "class MUIDialogImplementation extends BaseComponent {";
  code += "Render() {";
  code += "this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';";
  code += `const StyledDialog = withStyles({root: {${styleCode}},})(Dialog);`;
  code += `return <StyledDialog ${properties}> <DialogTitle>${title}</DialogTitle>`;
  code += `<DialogContent>${content}</DialogContent>`;
  code += `<DialogActions>${actions}</DialogActions>`;
  code += `</StyledDialog>}}`;
  return code;
};

Blockly.JavaScript['mui_dialog_property_is_open'] = function (block) {
  // TODO(odedf): Clean this crap!!!
  const isOpen = JSON.parse(Blockly.JavaScript.statementToCode(block, 'is_open')) === 'true';
  return `open={${isOpen}}`;
};

// MUI Layout blocks
Blockly.JavaScript['mui_card_implementation'] = function (block) {
  return (muiCardImplementation.bind(this))(block);
};

Blockly.JavaScript['mui_card_media'] = function (block) {
  return (muiCardIMedia.bind(this))(block);
};

Blockly.JavaScript['mui_paper_implementation'] = function (block) {
  return (muiPaperImplementation.bind(this))(block);
};

Blockly.JavaScript['paper_outlined'] = function () {
  return (paperOutlined.bind(this))();
};

Blockly.JavaScript['paper_square'] = function () {
  return (paperSquare.bind(this))();
};

Blockly.JavaScript['paper_elevation'] = function (block) {
  return (paperElevation.bind(this))(block);
};

// MUI Data Display blocks

Blockly.JavaScript['mui_image_implementation'] = function (block) {
  return (muiImageImplementation.bind(this))(block);
};

Blockly.JavaScript['mui_typography_implementation'] = function (block) {
  return (muiTypographyImplementation.bind(this))(block);
};

Blockly.JavaScript['tabs_implementation'] = function (block) {
  return (tabsImplementation.bind(this))(block);
};

Blockly.JavaScript['mui_tab'] = function (block) {
  return (muiTab.bind(this))(block);
};

Blockly.JavaScript['mui_box_implementation'] = function (block) {
  return (boxImplementationGenerator.bind(this))(block);
};

Blockly.JavaScript['mui_root_box_implementation'] = function (block) {
  return (rootBoxImplementationGenerator.bind(this))(block);
};

Blockly.JavaScript['mui_fab_implementation'] = function (block) {
  return (fabImplementationGenerator.bind(this))(block);
};

Blockly.JavaScript['mui_button_implementation'] = function (block) {
  return (buttonImplementationGenerator.bind(this))(block);
};

Blockly.JavaScript['start_icon_property'] = function (block) {
  return (startIconPropertyImplementationGenerator.bind(this))(block);
};

Blockly.JavaScript['end_icon_property'] = function (block) {
  return (endIconPropertyImplementationGenerator.bind(this))(block);
};

Blockly.JavaScript['mui_button_variant_property'] = function (block) {
  return (muiButtonVariantPropertyGenerator.bind(this))(block);
};

Blockly.JavaScript['mui_data_grid_implementation'] = function (block) {
  return (dataGridImplementation.bind(this))(block);
};

Blockly.JavaScript['mui_data_grid_column'] = function (block) {
  return (dataGridColumn.bind(this))(block);
};

Blockly.JavaScript['mui_data_grid_column_detailed'] = function (block) {
  return (dataGridColumnDetailed.bind(this))(block);
};

Blockly.JavaScript['mui_data_grid_row_height_property'] = function (block) {
  return (muiDataGridRowHeightPropertyGenerator.bind(this))(block);
};

Blockly.JavaScript['text_field_implementation'] = function (block) {
  return (textFieldImplementation.bind(this))(block);
};

Blockly.JavaScript['mui_checkbox_implementation'] = function (block) {
  return (checkboxImplementation.bind(this))(block);
};

Blockly.JavaScript['mui_checkbox_size_property'] = function (block) {
  return (checkboxSizeProperty.bind(this))(block);
};

Blockly.JavaScript['mui_checkbox_size_property_value'] = function (block) {
  return (checkboxSizePropertyValue.bind(this))(block);
};

Blockly.JavaScript['mui_checkbox_disabled'] = function (block) {
  return (checkboxDisabled.bind(this))(block);
};

Blockly.JavaScript['radio_buttons_implementation'] = function (block) {
  return (radioButtonsImplementation.bind(this))(block);
};

Blockly.JavaScript['radio_button_label'] = function (block) {
  return (radioButtonLabel.bind(this))(block);
};

Blockly.JavaScript['project_implementation'] = function (block) {
  return (projectImplementation.bind(this))(block);
};

Blockly.JavaScript['root_implementation'] = function (block) {
  return (rootImplementation.bind(this))(block);
};

Blockly.JavaScript['style_color_picker'] = function (block) {
  return (styleColorPicker.bind(this))(block);
};

Blockly.JavaScript['style_grey_picker'] = function (block) {
  return (styleGreyPicker.bind(this))(block);
};

Blockly.JavaScript['switch_implementation'] = function (block) {
  return (switchImplementation.bind(this))(block);
};

Blockly.JavaScript['switch_small'] = function (block) {
  return (switchSmall.bind(this))(block);
};

Blockly.JavaScript['switch_disabled'] = function (block) {
  return (switchDisabled.bind(this))(block);
};

Blockly.JavaScript['slider_implementation'] = function (block) {
  return (sliderImplementationGenerator.bind(this))(block);
};

Blockly.JavaScript['slider_disabled'] = function (block) {
  return (sliderDisableGenerator.bind(this))();
};

Blockly.JavaScript['slider_range'] = function (block) {
  return (sliderRangeGenerator.bind(this))(block);
};

Blockly.JavaScript['slider_marks'] = function (block) {
  return (sliderMarksGenerator.bind(this))();
};

Blockly.JavaScript['slider_step'] = function (block) {
  return (sliderStepGenerator.bind(this))(block);
};

Blockly.JavaScript['slider_defaultValue'] = function (block) {
  return (sliderDefaultValueGenerator.bind(this))(block);
};

Blockly.JavaScript['mui_image_list_implementation'] = function () {
  let code = "class ImageListImplementation extends BaseComponent {";
  code += "render() {";
  code += `const itemData = [
  {
    img: 'https://images.unsplash.com/photo-1551963831-b3b1ca40c98e',
    title: 'Breakfast',
  },
  {
    img: 'https://images.unsplash.com/photo-1551782450-a2132b4ba21d',
    title: 'Burger',
  },
  {
    img: 'https://images.unsplash.com/photo-1522770179533-24471fcdba45',
    title: 'Camera',
  },
  {
    img: 'https://images.unsplash.com/photo-1444418776041-9c7e33cc5a9c',
    title: 'Coffee',
  },
  {
    img: 'https://images.unsplash.com/photo-1533827432537-70133748f5c8',
    title: 'Hats',
  },
  {
    img: 'https://images.unsplash.com/photo-1558642452-9d2a7deb7f62',
    title: 'Honey',
  },
  {
    img: 'https://images.unsplash.com/photo-1516802273409-68526ee1bdd6',
    title: 'Basketball',
  },
  {
    img: 'https://images.unsplash.com/photo-1518756131217-31eb79b20e8f',
    title: 'Fern',
  },
  {
    img: 'https://images.unsplash.com/photo-1597645587822-e99fa5d45d25',
    title: 'Mushrooms',
  },
  {
    img: 'https://images.unsplash.com/photo-1567306301408-9b74779a11af',
    title: 'Tomato basil',
  },
  {
    img: 'https://images.unsplash.com/photo-1471357674240-e1a485acb3e1',
    title: 'Sea star',
  },
  {
    img: 'https://images.unsplash.com/photo-1589118949245-7d38baf380d6',
    title: 'Bike',
  },
];`;

  code += ` return (
    <ImageList cols={3} rowHeight={64}>
      {itemData.map((item) => (
        <ImageListItem key={item.img}>
          <img
            src= {item.img + '?w=64&h=64&fit=crop&auto=format'}
            srcSet={item.img + '?w=64&h=64&fit=crop&auto=format&dpr=2 2x'}
            alt={item.title}
            loading="lazy"
          />
        </ImageListItem>
      ))}
    </ImageList>
  );`;
  code += `}}`;
  return code;
};

Blockly.JavaScript['text_editor_implementation'] = function (block) {
  const variableCode = Blockly.JavaScript.statementToCode(block, 'variable');
  let initialText = Blockly.JavaScript.statementToCode(block, 'initial_text');
  initialText = initialText ? initialText : JSON.stringify("Empty");
  const snippetUUID = extractSnippetUUIDFromCode(variableCode);
  let code = "class TextEditorImplementation extends BaseComponent {";
  code += "render() {";
  code += "const self = this;";
  code += generateStyleCode(block);
  code +=
    `return <WYSIWYGEditor  ref={self.componentReference} name={"${snippetUUID}"} style={style}`;
  code += ` initialValue={context.evaluateFJSONExpressionWithRedux(${initialText})}`;
  code += ` ></WYSIWYGEditor>`;
  code += `}}`;
  return code;
};

// Action blocks

Blockly.JavaScript['toString_action'] = function (block) {
  let from = Blockly.JavaScript.statementToCode(block, 'from');
  // TODO(odedf):  JSON->stringify => safeToString. see: https://stackoverflow.com/questions/4310535/how-to-convert-anything-to-a-string-safely-in-javascript
  return `(() => {let res= (context.evaluateFJSONExpressionWithRedux(${from}));` +
    `return res === null ? null : JSON.stringify(res);})()`;
};

Blockly.JavaScript['action_implementation'] = function (block) {
  let currentBlock = this.getInputTargetBlock('all_actions');
  let code = `() => {`;
  while (currentBlock) {
    code += Blockly.JavaScript[currentBlock.type].call(currentBlock, currentBlock) + ";";
    currentBlock = currentBlock.getNextBlock();
  }
  code += `}`;
  return code;
};

Blockly.JavaScript['print_implementation'] = function (block) {
  let currentBlock = this.getInputTargetBlock('content');
  let res = '() => {console.log(';
  while (currentBlock) {
    const blockContent = Blockly.JavaScript[currentBlock.type].call(currentBlock, currentBlock);
    res += `context.evaluateFJSONExpressionWithRedux(${blockContent})`;
    res += currentBlock ? ', ' : '';
    currentBlock = currentBlock.getNextBlock();
  }
  res += ');}';
  return res;
};

Blockly.JavaScript['return_action_IMPL'] = function (block) {
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  return `() => {return context.evaluateFJSONExpressionWithRedux(${content})}`;
};

Blockly.JavaScript['alert'] = function (block) {
  // Deprecated
  let alertTitle = Blockly.JavaScript.statementToCode(block, 'alertTitle');
  alertTitle = alertTitle ? alertTitle : JSON.stringify("");
  let alertContent = Blockly.JavaScript.statementToCode(block, 'content');
  alertContent = alertContent ? alertContent : JSON.stringify("");
  let severity = Blockly.JavaScript.statementToCode(block, 'severity');
  let durationMsc = Blockly.JavaScript.statementToCode(block, 'durationMsc');
  durationMsc = durationMsc ? durationMsc : null;
  severity = severity ? severity : `"info"`;
  let code = `{`;
  code += `let alertTitle = context.evaluateFJSONExpressionWithRedux(${alertTitle});`;
  code += `alertTitle = typeof alertTitle === "string" ?  alertTitle : JSON.stringify(alertTitle);`;
  code += `let alertContent = context.evaluateFJSONExpressionWithRedux(${alertContent});`;
  code +=
    `alertContent = typeof alertContent === "string" ?  alertContent : JSON.stringify(alertContent);`;
  code += `context.alert(alertTitle, alertContent, ${severity}, ${durationMsc});`;
  code += `}`;
  return code;
};

Blockly.JavaScript['push_data_to_array_implementation'] = function (block) {
  return (pushDataToArrayGenerator.bind(this))(block);
};

Blockly.JavaScript['clear_array_implementation'] = function (block) {
  return (clearArrayGenerator.bind(this))(block);
};

Blockly.JavaScript['filter_array_implementation'] = function (block) {
  return (filterArrayImplementationGenerator.bind(this))(block);
};

Blockly.JavaScript['alert_implementation'] = function (block) {
  return (alertImplementationGenerator.bind(this))(block);
};

Blockly.JavaScript['generate_uuid_implementation'] = function (block) {
  return (generateUuidImplementationGenerator.bind(this))(block);
};

Blockly.JavaScript['codex_parse_implementation'] = function (block) {
  return (codexParseImplementation.bind(this))(block);
};

Blockly.JavaScript['create_array_of_maps_implementation'] = function (block) {
  return (createArrayOfMapsImplementation.bind(this))(block);
};

Blockly.JavaScript['gmail_send_mail_implementation'] = function (block) {
  let to = (Blockly.JavaScript.statementToCode(block, 'to',
    Blockly.JavaScript.ORDER_ATOMIC));
  let subject = (Blockly.JavaScript.statementToCode(block, 'subject',
    Blockly.JavaScript.ORDER_ATOMIC));
  let message = (Blockly.JavaScript.statementToCode(block, 'message',
    Blockly.JavaScript.ORDER_ATOMIC));
  UTILS.assert(to, "empty to field while sending e-mail");
  to = to ? to : "''";
  subject = subject ? subject : "''";
  message = message ? message : "''";
  let code = `() => {`;
  code += `let to = context.evaluateFJSONExpressionWithRedux(${to});`;
  code += `let subject = context.evaluateFJSONExpressionWithRedux(${subject});`;
  code += `let message = context.evaluateFJSONExpressionWithRedux(${message});`;
  code += `context.sendGmail(to, subject, message)`;
  code += `}`;
  return code;
};

Blockly.JavaScript['set_var_implementation'] = function (block) {
  const variableCode = Blockly.JavaScript.statementToCode(block, 'variable');
  const value = Blockly.JavaScript.statementToCode(block, 'value');
  const snippetUUID = extractSnippetUUIDFromCode(variableCode);
  return `() => {context.setDataSnippet("${snippetUUID}",context.evaluateFJSONExpressionWithRedux(${value}))}`;
};

Blockly.JavaScript['backup_snippet_implementation'] = function (block) {
  const snippetUUID = extractSnippetUUIDFromCode(
    Blockly.JavaScript.statementToCode(block, 'snippet'));
  return `() => {context.backupDataSnippet("${snippetUUID}")}`;
};

// Deprecated
Blockly.JavaScript['set_var'] = function (block) {
  const variableCode = Blockly.JavaScript.statementToCode(block, 'variable');
  const value = Blockly.JavaScript.statementToCode(block, 'value');
  const snippetUUID = extractSnippetUUIDFromCode(variableCode);
  return `context.setDataSnippet("${snippetUUID}",context.evaluateFJSONExpressionWithRedux(${value}));`;
};

Blockly.JavaScript['transform_text_action'] = function (block) {
  let translationMapping = Blockly.JavaScript.statementToCode(block, 'translation_mapping');
  let source = Blockly.JavaScript.statementToCode(block, 'source');
  translationMapping = translationMapping ? translationMapping : '';
  source = source ? source : '';
  let code = ``;
  code += `let source = context.evaluateFJSONExpressionWithRedux(${source});`;
  code +=
    `const translationMapping=context.evaluateFJSONExpressionWithRedux(${translationMapping});`;
  code += `for (const key in translationMapping) {`;
  code += `source = source.replaceAll(key, translationMapping[key]);`;
  code += `}`;
  code += `return source;`;
  return `(() => {${code}})()`;
};

Blockly.JavaScript['http_get_IMPL'] = function (block) {
  const url = Blockly.JavaScript.statementToCode(block, 'url');
  const variableCode = Blockly.JavaScript.statementToCode(block, 'variable');
  const snippetUUID = extractSnippetUUIDFromCode(variableCode);
  let action = Blockly.JavaScript.statementToCode(block, 'action');
  let code = `() => {`;
  code += `const url = context.evaluateFJSONExpressionWithRedux(${url});`;
  code += `context.httpGetRequest(new URL(url)).`;
  code += `then((res) => {context.injectBlocksAndUpsertDataSnippet(res.json, "${snippetUUID}");`;
  code += action;
  code += `});`;
  code += `}`;
  return code;
};

Blockly.JavaScript['http_post_IMPL'] = function (block) {
  const url = Blockly.JavaScript.statementToCode(block, 'url');
  const variableCode = Blockly.JavaScript.statementToCode(block, 'variable');
  const dataCode = Blockly.JavaScript.statementToCode(block, 'data');
  const snippetUUID = extractSnippetUUIDFromCode(variableCode);
  let action = Blockly.JavaScript.statementToCode(block, 'action');
  let code = `() => {`;
  code += `const url = context.evaluateFJSONExpressionWithRedux(${url});`;
  code += `const data = context.evaluateFJSONExpressionWithRedux(${dataCode});`;
  code += `context.httpPostRequest(new URL(url), data).`;
  code += `then((res) => {context.injectBlocksAndUpsertDataSnippet(res.json, "${snippetUUID}");`;
  code += action;
  code += `});`;
  code += `}`;
  return code;
};

Blockly.JavaScript['http_put_IMPL'] = function (block) {
  const url = Blockly.JavaScript.statementToCode(block, 'url');
  const variableCode = Blockly.JavaScript.statementToCode(block, 'variable');
  const snippetUUID = extractSnippetUUIDFromCode(variableCode);
  const dataCode = Blockly.JavaScript.statementToCode(block, 'data');
  let action = Blockly.JavaScript.statementToCode(block, 'action');
  let code = `() => {`;
  code += `const url = context.evaluateFJSONExpressionWithRedux(${url});`;
  code += `const data = context.evaluateFJSONExpressionWithRedux(${dataCode});`;
  code += `context.httpPutRequest(new URL(url), data)
  .then((res) => {context.injectBlocksAndUpsertDataSnippet(res.json, "${snippetUUID}");`;
  code += action;
  code += `});`;
  code += `}`;
  return code;
};

Blockly.JavaScript['http_delete_IMPL'] = function (block) {
  const url = Blockly.JavaScript.statementToCode(block, 'url');
  let code = `() => {`;
  code += `const url = context.evaluateFJSONExpressionWithRedux(${url});`;
  code += `context.httpDeleteRequest(new URL(url));`;
  code += `}`;
  return code;
};

Blockly.JavaScript['recharts_line_chart_inline'] = function (block) {
  return `<LineChart
          width={360}
          height={300}
          data={[
            { name: "Page A", uv: 200, pv: 2400, amt: 2400 },
            { name: "Page B", uv: 600, pv: 400, amt: 2400 },
            { name: "Page C", uv: 800, pv: 1400, amt: 2400 },
          ]}
        >
          {" "}
          <Line type="monotone" dataKey="uv" stroke="#3453E2" />{" "}
          <CartesianGrid stroke="#ccc" />{" "}
        </LineChart>`;
};

Blockly.JavaScript['recharts_bar_chart_inline'] = function (block) {
  return `<BarChart
          width={360}
          height={300}
          data={[
            { name: "Page A", uv: 200, pv: 2400, amt: 2400 },
            { name: "Page B", uv: 600, pv: 400, amt: 2400 },
            { name: "Page C", uv: 800, pv: 1400, amt: 2400 },
          ]}
        >
          <Bar dataKey="pv" fill="#218F68" />
          <Bar dataKey="uv" fill="#FFBC0E" />
          <CartesianGrid stroke="#ccc" />
        </BarChart>`;
};

Blockly.JavaScript['recharts_area_chart_inline'] = function (block) {
  return `<AreaChart
          width={360}
          height={300}
          data={[
            { name: "Page A", uv: 200, pv: 2400, amt: 2400 },
            { name: "Page B", uv: -600, pv: 400, amt: 2400 },
            { name: "Page C", uv: 800, pv: 1400, amt: 2400 },
          ]}
        >
          <Area type="monotone" dataKey="uv" stroke="#218F68" fillOpacity={1.0} fill="#C72A1A" />
          <CartesianGrid stroke="#ccc" />{" "}
        </AreaChart>`;
};

Blockly.JavaScript['recharts_pie_chart_inline'] = function (block) {
  return `<PieChart
          width={360}
          height={300}
        >
          <Pie data={
          [
  {
    "name": "Group A",
    "value": 2400
  },
  {
    "name": "Group B",
    "value": 4567
  },
  {
    "name": "Group C",
    "value": 1398
  },
  {
    "name": "Group D",
    "value": 9800
  },
  {
    "name": "Group E",
    "value": 3908
  },
  {
    "name": "Group F",
    "value": 4800
  }
]
      } dataKey="value" nameKey="name" cx="50%" cy="50%" innerRadius={60} outerRadius={80} fill="#FFBC0E" label />
          <CartesianGrid stroke="#ccc" />{" "}
        </PieChart>`;
};

Blockly.JavaScript['flex_grow'] = function (block) {
  const value = Blockly.JavaScript.statementToCode(block, 'value');
  return `flexGrow={context.evaluateFJSONExpressionWithRedux(${value})} `;
};

// Style blocks

Blockly.JavaScript['style_implementation'] = function (block) {
  return (styleImplementation.bind(this))(block);
};

Blockly.JavaScript['style_width'] = function (block) {
  return (styleWidth.bind(this))(block);
};

Blockly.JavaScript['style_height'] = function (block) {
  return (styleHeight.bind(this))(block);
};

Blockly.JavaScript['style_color'] = function (block) {
  return (styleColor.bind(this))(block);
};

Blockly.JavaScript['style_background_color'] = function (block) {
  return (styleBackgroundColor.bind(this))(block);
};

Blockly.JavaScript['style_zIndex'] = function (block) {
  const value = Blockly.JavaScript.statementToCode(block, 'value');
  return `{zIndex: context.evaluateFJSONExpressionWithRedux("${value}")}`;
};

Blockly.JavaScript['style_position'] = function (block) {
  return `{position: "${block.getFieldValue('position')}"}`;
};

Blockly.JavaScript['style_position_value'] = function (block) {
  const value = Blockly.JavaScript.statementToCode(block, 'value') || "0";
  const position = block.getFieldValue('position');
  const units = block.getFieldValue('units');
  return `{${position}:(context.evaluateFJSONExpressionWithRedux("${value}") + "${units}")}`;
};

Blockly.JavaScript['style_flicker_animation'] = function (block) {
  const duration = Blockly.JavaScript.statementToCode(block, 'value');
  return '{animationName:"flickerAnimation",' +
    'animationIterationCount: "infinite",' +
    'animationTimingFunction: "linear",' +
    `animationDuration: "${duration}s"}`;
};

Blockly.JavaScript['style_spin_with_direction'] = function (block) {
  const duration = Blockly.JavaScript.statementToCode(block, 'value');
  return '{animationName:' +
    ((block.getFieldValue('direction') === 'CLOCKWISE') ? '"spin",' : '"ccw_spin",') +
    'animationIterationCount: "infinite",animationTimingFunction: "linear",' +
    `animationDuration: "${duration}s"}`;
};

Blockly.JavaScript['set_margin'] = function (block) {
  return (setMargin.bind(this))(block);
};

Blockly.JavaScript['set_margin_detailed'] = function (block) {
  return (setMarginDetailed.bind(this))(block);
};

Blockly.JavaScript['set_margin_top'] = function (block) {
  return (setMarginTop.bind(this))(block);
};

Blockly.JavaScript['set_margin_bottom'] = function (block) {
  return (setMarginBottom.bind(this))(block);
};

Blockly.JavaScript['set_padding'] = function (block) {
  return (setPadding.bind(this))(block);
};

Blockly.JavaScript['set_padding_detailed'] = function (block) {
  return (setPaddingDetailed.bind(this))(block);
};

Blockly.JavaScript['style_min_width'] = function (block) {
  const value = Blockly.JavaScript.statementToCode(block, 'value');
  return `{minWidth: (context.evaluateFJSONExpressionWithRedux("${value}") + "${block.getFieldValue(
    'units')}")}`;
};

Blockly.JavaScript['style_min_height'] = function (block) {
  const value = Blockly.JavaScript.statementToCode(block, 'value');
  return `{minHeight: (context.evaluateFJSONExpressionWithRedux("${value}") + "${block.getFieldValue(
    'units')}")}`;
};

Blockly.JavaScript['style_display'] = function (block) {
  return `{display: "${block.getFieldValue('display')}"}`;
};

Blockly.JavaScript['style_text_align'] = function (block) {
  return `{textAlign: "${block.getFieldValue('text-align')}"}`;
};

Blockly.JavaScript['style_object_fit'] = function (block) {
  return `{objectFit: "${block.getFieldValue('object_fit')}"}`;
};

Blockly.JavaScript['deprecated_color_picker'] = function (block) {
  return ['"' + block.getFieldValue('field_colour') + '"', Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['deprecated_grey_picker'] = function (block) {
  return ['"' + block.getFieldValue('field_colour') + '"', Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['deprecated_set_width_and_height'] = function (block) {
  let value = Blockly.JavaScript.valueToCode(block,
    'value', Blockly.JavaScript.ORDER_ATOMIC);
  value = value ? value : 0;
  return `{width: ${value}, height:${value}}`;
};

Blockly.JavaScript['deprecated_style_set_width'] = function (block) {
  let value = Blockly.JavaScript.valueToCode(block,
    'value', Blockly.JavaScript.ORDER_ATOMIC);
  value = value ? value : 0;
  return `{width: ${value}}`;
};

Blockly.JavaScript['deprecated_style_set_height'] = function (block) {
  let value = Blockly.JavaScript.valueToCode(block,
    'value', Blockly.JavaScript.ORDER_ATOMIC);
  value = value ? value : 0;
  return `{height:${value}}`;
};

Blockly.JavaScript['deprecated_style_set_min_width'] = function (block) {
  let value = Blockly.JavaScript.valueToCode(block,
    'value', Blockly.JavaScript.ORDER_ATOMIC);
  value = value ? value : 0;
  return `{minWidth: ${value}}`;
};

Blockly.JavaScript['deprecated_style_set_min_height'] = function (block) {
  let value = Blockly.JavaScript.valueToCode(block,
    'value', Blockly.JavaScript.ORDER_ATOMIC);
  value = value ? value : 0;
  return `{minHeight:${value}}`;
};

Blockly.JavaScript['unit_length_absolute'] = function (block) {
  return [`"${Blockly.JavaScript.valueToCode(block, 'length', Blockly.JavaScript.ORDER_ATOMIC)}`
    + `${block.getFieldValue('units')}"`, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['unit_length_relative'] = function (block) {
  return [`"${Blockly.JavaScript.valueToCode(block, 'length', Blockly.JavaScript.ORDER_ATOMIC)}`
    + `${block.getFieldValue('units')}"`, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['deprecated_set_margin'] = function (block) {
  let margin = Blockly.JavaScript.valueToCode(block,
    'margin', Blockly.JavaScript.ORDER_ATOMIC);
  margin = margin ? margin : 0;
  return `{margin: ${margin}}`;
};

Blockly.JavaScript['style_overflow'] = function (block) {
  return `{overflow: "${block.getFieldValue('overflow')}"}`;
};

Blockly.JavaScript['svg_simple_square'] = function (block) {
  const size = Blockly.JavaScript.valueToCode(block, 'size',
    Blockly.JavaScript.ORDER_ATOMIC);
  const color = Blockly.JavaScript.valueToCode(block, 'color',
    Blockly.JavaScript.ORDER_ATOMIC);
  return `<svg width="${size}" height="${size}">` +
    `<rect width="${size}" height="${size}" style={{fill:${color}}}/></svg>`;
};

Blockly.JavaScript['svg_square_with_style'] = function (block) {
  const size = Blockly.JavaScript.valueToCode(block, 'size',
    Blockly.JavaScript.ORDER_ATOMIC);
  const color = Blockly.JavaScript.valueToCode(block, 'color',
    Blockly.JavaScript.ORDER_ATOMIC);
  const styleCode = `fill:${color},` + Blockly.JavaScript.statementToCode(block, 'style');
  return `<svg width="${size}" height="${size}">` +
    `<rect width="${size}" height="${size}" style={{${styleCode}}}/></svg>`;
};

Blockly.JavaScript['svg_simple_rect'] = function (block) {
  const width = Blockly.JavaScript.valueToCode(block, 'width',
    Blockly.JavaScript.ORDER_ATOMIC);
  const height = Blockly.JavaScript.valueToCode(block, 'height',
    Blockly.JavaScript.ORDER_ATOMIC);
  const color = Blockly.JavaScript.valueToCode(block, 'color',
    Blockly.JavaScript.ORDER_ATOMIC);
  return `<svg width="${width}" height="${height}">` +
    `<rect width="${width}" height="${height}" style={{fill:${color}}}/></svg>`;
};

Blockly.JavaScript['svg_rect_with_style'] = function (block) {
  const width = Blockly.JavaScript.valueToCode(block, 'width',
    Blockly.JavaScript.ORDER_ATOMIC);
  const height = Blockly.JavaScript.valueToCode(block, 'height',
    Blockly.JavaScript.ORDER_ATOMIC);
  const color = Blockly.JavaScript.valueToCode(block, 'color',
    Blockly.JavaScript.ORDER_ATOMIC);
  const styleCode = `fill:${color},` + Blockly.JavaScript.statementToCode(block, 'style');
  return `<svg width="${width}" height="${height}">` +
    `<rect width="${width}" height="${height}" style={{${styleCode}}}/></svg>`;
};

Blockly.JavaScript['svg_simple_circle'] = function (block) {
  const radius = Blockly.JavaScript.valueToCode(block, 'radius',
    Blockly.JavaScript.ORDER_ATOMIC);
  const color = Blockly.JavaScript.valueToCode(block, 'color',
    Blockly.JavaScript.ORDER_ATOMIC);
  return `<svg width="${2 * radius}" height="${2 * radius}">` +
    `<circle r="${radius}" cx="${radius}" cy="${radius}" ` +
    `height="${radius}" style={{fill:${color}}}/></svg>`;
};

Blockly.JavaScript['svg_circle_with_style'] = function (block) {
  const radius = Blockly.JavaScript.valueToCode(block, 'radius',
    Blockly.JavaScript.ORDER_ATOMIC);
  const color = Blockly.JavaScript.valueToCode(block, 'color',
    Blockly.JavaScript.ORDER_ATOMIC);
  const styleCode = `fill:${color},` + Blockly.JavaScript.statementToCode(block, 'style');
  return `<svg width="${2 * radius}" height="${2 * radius}">` +
    `<circle r="${radius}" cx="${radius}" cy="${radius}" ` +
    `height="${radius}" style={{${styleCode}} }/></svg>`;
};

Blockly.JavaScript['svg_simple_ellipse'] = function (block) {
  const rx = Blockly.JavaScript.valueToCode(block, 'rx',
    Blockly.JavaScript.ORDER_ATOMIC);
  const ry = Blockly.JavaScript.valueToCode(block, 'ry',
    Blockly.JavaScript.ORDER_ATOMIC);
  const color = Blockly.JavaScript.valueToCode(block, 'color',
    Blockly.JavaScript.ORDER_ATOMIC);
  return `<svg width="${2 * rx}" height="${2 * ry}">` +
    `<ellipse rx="${rx}" ry="${ry}" cx="${rx}" cy="${ry}" ` +
    `style={{fill:${color}}}/></svg>`;
};

Blockly.JavaScript['svg_ellipse_with_style'] = function (block) {
  const rx = Blockly.JavaScript.valueToCode(block, 'rx',
    Blockly.JavaScript.ORDER_ATOMIC);
  const ry = Blockly.JavaScript.valueToCode(block, 'ry',
    Blockly.JavaScript.ORDER_ATOMIC);
  const color = Blockly.JavaScript.valueToCode(block, 'color',
    Blockly.JavaScript.ORDER_ATOMIC);
  const styleCode = `fill:${color},` + Blockly.JavaScript.statementToCode(block, 'style');
  return `<svg width="${2 * rx}" height="${2 * ry}">` +
    `<ellipse rx="${rx}" ry="${ry}" cx="${rx}" cy="${ry}" ` +
    `style={{${styleCode}}}/></svg>`;
};

Blockly.JavaScript['deprecated_set_width_and_height'] = function (block) {
  const value = Blockly.JavaScript.valueToCode(block,
    'value', Blockly.JavaScript.ORDER_ATOMIC);
  return `{backgroundColor: ${value}}`;
};

Blockly.JavaScript['html_br'] = function (block) {
  return '<br/>';
};

Blockly.JavaScript['html_hr'] = function (block) {
  return '<hr/>';
};

Blockly.JavaScript['html_ul'] = function (block) {
  return `<ul>${Blockly.JavaScript.statementToCode(block, 'body')}</ul>`;
};

Blockly.JavaScript['html_li'] = function (block) {
  return `<li>${Blockly.JavaScript.statementToCode(block, 'body')}</li>`;
};

Blockly.JavaScript['html_ol'] = function (block) {
  return `<ol>${Blockly.JavaScript.statementToCode(block, 'body')}</ol>`;
};

Blockly.JavaScript['html_div'] = function (block) {
  return `<div>${Blockly.JavaScript.statementToCode(block, 'body')}</div>`;
};

Blockly.JavaScript['html_div_with_style'] = function (block) {
  let styleCode = "width: 'fit-content', " +
    Blockly.JavaScript.statementToCode(block, 'style');
  return `<div` + (styleCode ? ` style={{${styleCode}}} ` : ``) + `>` +
    `${Blockly.JavaScript.statementToCode(block, 'body')}</div>`;
};

Blockly.JavaScript['html_div_implementation'] = function (block) {
  const styleCode = Blockly.JavaScript.statementToCode(block, 'style');
  const onClickCode = Blockly.JavaScript.statementToCode(block, 'onClick');
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  let code = "class Foo extends BaseComponent {";
  code += "Render() {";
  code += "this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';";
  code += "const self = this;";
  code += `return <div style={{${styleCode}}} ref={self.componentReference}` +
    `onClick={(event) => {${onClickCode}}}>${content}</div>`;
  code += `}}`;
  return code;
};

Blockly.JavaScript['html_body_implementation'] = function (block) {
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  let code = "class Foo extends BaseComponent {";
  code += "constructor(props) {";
  code += "super(props);";
  code += "this.shouldMaskComponent = false;";
  code += "}";
  code += "Render() {";
  code += "this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';";
  code += "const self = this;";
  code += `return <body ref={self.componentReference}>${content}</body>`;
  code += `}}`;
  return code;
};

Blockly.JavaScript['html_html_implementation'] = function (block) {
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  let code = "class Foo extends BaseComponent {";
  code += "constructor(props) {";
  code += "super(props);";
  code += "this.shouldMaskComponent = false;";
  code += "}";
  code += "Render() {";
  code += "this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';";
  code += "const self = this;";
  code += `return <html ref={self.componentReference}>${content}</html>`;
  code += `}}`;
  return code;
};

Blockly.JavaScript['html_head_implementation'] = function (block) {
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  let code = "class Foo extends BaseComponent {";
  code += "Render() {";
  code += "this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';";
  code += "const self = this;";
  code += `return <head ref={self.componentReference}>${content}</head>`;
  code += `}}`;
  return code;
};

Blockly.JavaScript['html_button_implementation'] = function (block) {
  const styleCode = Blockly.JavaScript.statementToCode(block, 'style');
  const onClickCode = Blockly.JavaScript.statementToCode(block, 'onClick');
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  let code = "class Foo extends BaseComponent {";
  code += "Render() {";
  code += "this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';";
  code += "const self = this;";
  code += `return <button style={{${styleCode}}} ref={self.componentReference}` +
    ` onClick={((event) => {${onClickCode}})}>${content}</button>`;
  code += `}}`;
  return code;
};

Blockly.JavaScript['html_img_implementation'] = function (block) {
  const styleCode = Blockly.JavaScript.statementToCode(block, 'style');
  const src = Blockly.JavaScript.statementToCode(block, 'src');
  const onClickCode = Blockly.JavaScript.statementToCode(block, 'onClick');
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  const showId = (src === "  media/images/../../../media/images/snow_form.png");
  let code = "class Foo extends BaseComponent {";
  code += "Render() {";
  code += "this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';";
  code += "const self = this;";
  code += `return <img style={{${styleCode}}} ` + (showId ? ` id="CONTACT_FORM"` : ``)
    + ` src="${src}" ref={self.componentReference}` +
    (onClickCode ? ` onClick={(event) => {${onClickCode}}}` : ``) +
    `>${content}</img>`;
  code += `}}`;
  return code;
};

Blockly.JavaScript['html_link_implementation'] = function (block) {
  let styleCode = "width: 'fit-content', " + Blockly.JavaScript.statementToCode(
    block, 'style');
  let href = Blockly.JavaScript.statementToCode(block,
    'href');
  const properties = Blockly.JavaScript.statementToCode(block, 'properties');
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  let code = "class Foo extends BaseComponent {";
  code += "Render() {";
  code += "this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';";
  code += "const self = this;";
  code += `return <a href="${href}"${properties}` + (styleCode ? ` style={{${styleCode}}} ` : ``) +
    ` ref={self.componentReference}>${content}</a>`;
  code += `}}`;
  return code;
};

Blockly.JavaScript['html_link'] = function (block) {
  const href = Blockly.JavaScript.valueToCode(block,
    'href', Blockly.JavaScript.ORDER_ATOMIC);
  const properties = Blockly.JavaScript.statementToCode(block, 'properties');
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  return `<a href=${href}${properties}>${content}</a>`;
};

Blockly.JavaScript['html_link_with_style'] = function (block) {
  let styleCode = "width: 'fit-content', " + Blockly.JavaScript.statementToCode(
    block, 'style');
  const href = Blockly.JavaScript.valueToCode(block,
    'href', Blockly.JavaScript.ORDER_ATOMIC);
  const properties = Blockly.JavaScript.statementToCode(block, 'properties');
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  return `<a href=${href}${properties}` + (styleCode ? ` style={{${styleCode}}} ` : ``) +
    `>${content}</a>`;
};

Blockly.JavaScript['html_link_target_property'] = function (block) {
  return ` target="${block.getFieldValue('type')}"`;
};

Blockly.JavaScript['html_p'] = function (block) {
  return `<p>${Blockly.JavaScript.statementToCode(block, 'body')}</p> `;
};

Blockly.JavaScript['html_expanded_image_implementation'] = function (block) {
  return actualImageImplementation(block);
};

Blockly.JavaScript['html_basic_image'] = function (block) {
  return actualImageImplementation(block);
};

function actualImageImplementation(block) {
  const styleCode = Blockly.JavaScript.statementToCode(block, 'style');
  const src = Blockly.JavaScript.valueToCode(block,
    'src', Blockly.JavaScript.ORDER_ATOMIC);
  let code = "<img ";
  if (src)
    code += `src=${src} `;
  if (styleCode)
    code += `style={{${styleCode}}} `;
  code += `/>`;
  return code;
}

Blockly.JavaScript['positive_integer_number'] = function (block) {
  return `${block.getFieldValue('value')}`;
};

Blockly.JavaScript['mui_textfield_property_variant'] = function (block) {
  const variant = block.getFieldValue('variant');
  return variant === 'standard' ? '' : `variant="${variant}" `;
};

Blockly.JavaScript['mui_textfield_property_size'] = function (block) {
  const size = block.getFieldValue('size');
  return `size="${size}" `;
};

Blockly.JavaScript['mui_app_bar_implementation'] = function (block) {
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  const styleCode = Blockly.JavaScript.statementToCode(block, 'style');
  const properties = Blockly.JavaScript.statementToCode(block, 'properties');
  let code = "class Foo extends BaseComponent {";
  code += "Render() {";
  code += "this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';";
  code += "const self = this;";
  code += "const StyledAppBar = withStyles({";
  code += "root: {";
  code += styleCode;
  code += "},})(AppBar);";
  code +=
    `return <StyledAppBar position="static" ${properties} ref={self.componentReference}><ToolBar>`;
  code += `${content} </ToolBar></StyledAppBar>}}`;
  return code;
};

Blockly.JavaScript['flex_direction'] = function (block) {
  return `flexDirection="${block.getFieldValue('flexDirection')}" `;
};

Blockly.JavaScript['flex_justify_content'] = function (block) {
  return `justifyContent="${block.getFieldValue('justifyContent')}" `;
};

Blockly.JavaScript['flex_align_content'] = function (block) {
  return `alignContent= "${block.getFieldValue('alignContent')}" `;
};

Blockly.JavaScript['flex_align_self'] = function (block) {
  return `alignSelf= "${block.getFieldValue('align_self')}" `;
};

Blockly.JavaScript['flex_align_items'] = function (block) {
  return `alignItems= "${block.getFieldValue('align_items')}" `;
};

// React Router

Blockly.JavaScript['react_router'] = function (block) {
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  return `<Router>${content}</Router>`;
};

Blockly.JavaScript['react_router_exact'] = function (block) {
  return 'exact';
};

Blockly.JavaScript['react_router_to'] = function (block) {
  return `to="${block.getFieldValue('to')}"`;
};

Blockly.JavaScript['react_router_path'] = function (block) {
  return `path="${block.getFieldValue('path')}"`;
};

Blockly.JavaScript['react_router_link'] = function (block) {
  const allProps = Blockly.JavaScript.statementToCode(block, 'allProps');
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  return `<Link ${allProps}>${content}</Link>`;
};

Blockly.JavaScript['react_router_route'] = function (block) {
  const allProps = Blockly.JavaScript.statementToCode(block, 'allProps');
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  return `<Route ${allProps}>${content}</Route>`;
};

Blockly.JavaScript['react_router_switch_declaration'] = function (block) {
  // TODO (odedf): revive this
  return `</>`;
};

Blockly.JavaScript['react_router_switch_implementation'] = function (block) {
  const allSwitches = Blockly.JavaScript.statementToCode(block, 'all_switches');
  let code = "class Foo extends BaseComponent {";
  code += `render() {`;
  code += `return <Switch>${allSwitches}</Switch>}}`;
  return code;
};

Blockly.JavaScript['js_comment'] = function (block) {
  return `{/*${Blockly.JavaScript.statementToCode(block, 'content')}*/}`;
};

Blockly.JavaScript['alert_severity_property'] = function (block) {
  return `"${block.getFieldValue('severity')}"`;
};

Blockly.JavaScript['open_window_action'] = function (block) {
  let href = Blockly.JavaScript.statementToCode(block, 'href');
  const name = Blockly.JavaScript.statementToCode(block, 'name');
  let code = `window.open("${href}", ${name});`;
  return `(() => {${code}})()`;
};

Blockly.JavaScript['open_window_action_name'] = function (block) {
  return `"${block.getFieldValue('name')}"`;
};

// JSON Generators.

Blockly.JavaScript['json_key_value'] = function (block) {
  return `${Blockly.JavaScript.valueToCode(block,
    'key', Blockly.JavaScript.ORDER_ATOMIC)}:${Blockly.JavaScript.valueToCode(block,
      'value', Blockly.JavaScript.ORDER_ATOMIC)},`;
};

Blockly.JavaScript['json_expanded_implementation'] = function (block) {
  return `{${Blockly.JavaScript.statementToCode(block, 'body')}}`;
};

Blockly.JavaScript['js_object'] = function (block) {
  return [`{${Blockly.JavaScript.statementToCode(block, 'body')}}`,
  Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['json_stringify'] = function (block) {
  const obj = Blockly.JavaScript.valueToCode(block, 'obj', Blockly.JavaScript.ORDER_ATOMIC);
  return [`JSON.stringify(${obj})`, Blockly.JavaScript.ORDER_FUNCTION_CALL];
};

Blockly.JavaScript['url_address'] = function (block) {
  return [`"${block.getFieldValue('content')}"`, Blockly.JavaScript.ORDER_ATOMIC];
};

Blockly.JavaScript['style_container_implementation'] = function (block) {
  return Blockly.JavaScript.statementToCode(block, 'style');
};

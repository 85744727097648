// push_data_to_list
//
import * as Blockly from "blockly/core";
import extractSnippetUUIDFromCode from "../extractSnippetUUIDFromCode";

/** @function
 * @name pushDataToArrayGenerator
 * @param {Blockly.Block} block
 * @description
 *
 * Returns the generated code for a block of type push_data_to_array_implementation.
 */
export function pushDataToArrayGenerator(block) {
  const arrayCode = Blockly.JavaScript.statementToCode(block, 'array');
  if (!arrayCode) {
    return '';
  }
  const arraySnippetUUID = extractSnippetUUIDFromCode(arrayCode);
  const dataCode = Blockly.JavaScript.statementToCode(block, 'data');
  let code = `() => {`;
  if (!arraySnippetUUID)
    code += ` return ''`;
  else {
    code += `let array = context.evaluateDataSnippet("${arraySnippetUUID}");`;
    code += `const data = context.evaluateFJSONExpressionWithRedux(${dataCode});`;
    code += `array.push(data);`;
    code += `context.setDataSnippet("${arraySnippetUUID}", array);`;
  }
  code += `}`;
  return code;
}

/** @function
 * @name clearArrayGenerator
 * @param {Blockly.Block} block
 * @description
 *
 * Returns the generated code for a block of type clear_array_implementation.
 */
export function clearArrayGenerator(block) {
  const arrayCode = Blockly.JavaScript.statementToCode(block, 'array');
  if (!arrayCode) {
    return '';
  }
  const arraySnippetUUID = extractSnippetUUIDFromCode(arrayCode);
  let code = `() => {`;
  code += `context.setDataSnippet("${arraySnippetUUID}", []);`;
  code += `}`;
  return code;
}

/** @function
 * @name filterArrayImplementationGenerator
 * @param {Blockly.Block} block
 * @description
 *
 * Returns the generated code for a block of type filter_array_implementation.
 */
export function filterArrayImplementationGenerator(block) {
  const arrayCode = Blockly.JavaScript.statementToCode(block, 'array');
  const keyCode = Blockly.JavaScript.statementToCode(block, 'key');
  const expectedValueCode = Blockly.JavaScript.statementToCode(block, 'expected_value');
  if (!arrayCode) {
    return '';
  }
  const arraySnippetUUID = extractSnippetUUIDFromCode(arrayCode);
  let code = `() => {`;
  code += `const array = context.evaluateDataSnippet("${arraySnippetUUID}");`;
  code += `const key = context.evaluateFJSONExpressionWithRedux(${keyCode});`;
  code += `const expectedValue = context.evaluateFJSONExpressionWithRedux(${expectedValueCode});`;
  code += `if (array && key && expectedValue) {`;
  code += ` const filteredArray = array.filter(entry => {`;
  code +=
      ` return (typeof entry === 'object' && entry.hasOwnProperty(key) && entry[key] !== expectedValue);`;
  code += ` });`;
  code += `context.setDataSnippet("${arraySnippetUUID}", filteredArray);`;
  code += `}`;
  code += `}`;
  return code;
}

export function alertImplementationGenerator(block) {
  let alertTitle = Blockly.JavaScript.statementToCode(block, 'alertTitle');
  alertTitle = alertTitle ? alertTitle : JSON.stringify("");
  let alertContent = Blockly.JavaScript.statementToCode(block, 'content');
  alertContent = alertContent ? alertContent : JSON.stringify("");
  let severity = Blockly.JavaScript.statementToCode(block, 'severity');
  let durationMsc = Blockly.JavaScript.statementToCode(block, 'durationMsc');
  durationMsc = durationMsc ? durationMsc : null;
  severity = severity ? severity : `"info"`;
  let code = `() => {`;
  code += `let alertTitle = context.evaluateFJSONExpressionWithRedux(${alertTitle});`;
  code += `alertTitle = typeof alertTitle === "string" ?  alertTitle : JSON.stringify(alertTitle);`;
  code += `let alertContent = context.evaluateFJSONExpressionWithRedux(${alertContent});`;
  code +=
      `alertContent = typeof alertContent === "string" ?  alertContent : JSON.stringify(alertContent);`;
  code += `context.alert(alertTitle, alertContent, ${severity}, ${durationMsc});`;
  code += `}`;
  return code;
}

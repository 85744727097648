import * as Blockly from "blockly/core";
import generateStyleCode from "../../generateStyleCode";

export function muiGridContainerImplementation(block) {
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  const properties = Blockly.JavaScript.statementToCode(block, 'properties');
  const spacing = Blockly.JavaScript.statementToCode(block, 'spacing') || 1;
  let code = `class MUIGrid extends BaseComponent {`;
  code += `Render() {`;
  code += `const self = this;`;
  code += `this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';`;
  code += generateStyleCode(block);
  code += `const StyledGrid = withStyles({root: style})(Grid);`;
  code += `return <StyledGrid container ref={self.componentReference}` +
      ` spacing={context.evaluateFJSONExpressionWithRedux(${spacing})}` +
      `${properties}>${content}</StyledGrid>}}`;
  return code;
}

export function muiGridItemLean(block) {
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  const columnWidth = Blockly.JavaScript.statementToCode(block, 'column_width');
  return `<Grid item xs={context.evaluateFJSONExpressionWithRedux(${columnWidth})}>` +
      `${content} </Grid>`;
}

export function muiGridItemDetailed(block) {
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  const xs = Blockly.JavaScript.statementToCode(block, 'xs');
  const sm = Blockly.JavaScript.statementToCode(block, 'sm');
  const md = Blockly.JavaScript.statementToCode(block, 'md');
  const lg = Blockly.JavaScript.statementToCode(block, 'lg');
  return `<Grid item xs={context.evaluateFJSONExpressionWithRedux(${xs})}` +
      `sm={context.evaluateFJSONExpressionWithRedux(${sm})}` +
      `md={context.evaluateFJSONExpressionWithRedux(${md})}` +
      `lg={context.evaluateFJSONExpressionWithRedux(${lg})}` +
      `>${content}</Grid>`;
}

import React from 'react';
import {connect} from 'react-redux';
import * as Blockly from 'blockly/core';
import BlocklyComponent from '../../../../../Blockly';
import generateContentFromWorkspace
  from '../../../../../Blockly/BlocklyLogic/generateContentFromWorkspace';
// @ts-ignore
import BaseComponent from '../../../../../common/BaseComponent.ts';
import UTILS from '../../../../../common/utils';
import {clearAllMasks, setDisplayMasks, setHighlightComponent}
  from '../../../../../redux/actions/maskerActions';
import {upsertSnippet} from '../../../../../redux/actions/persistentStateActions';
import {
  setUpdateUncontrolledBlocklyEditor,
  setWorkAreaLoadingState,
  setWorkAreaPageState,
  setWYSIWYGEditorContentContent,
  setWYSIWYGEditorXMLContent,
  pushSnippetUUIDToStack,
} from '../../../../../redux/actions/workAreaPageStateActions';
// @ts-ignore
import MainToolBox from '../../../../toolboxes/MainToolBox.tsx';
import {
  LoadingState,
  WorkAreaPageState
} from "../../../../../redux/reducers/workAreaPageStateReducer";
import Snippet from '../../../../../common/Snippet';
import getCurrentSnippet, {getCurrentSnippetUUID} from "../../../../../redux/logic/getCurrentSnippet";
import injectAtomicBlocks from "../../../../../Blockly/injectAtomicBlocks";
import setContextMenu from "./setContextMenu";
import CONSTANTS from "../../../../../common/Constants";
import getBlocklyXml from "../../../../../Blockly/BlocklyLogic/getBlocklyXml";
import cloneTemplateBlock from "./cloneTemplateBlock";
import {injectBlocks} from "../../../../../Blockly/BlocklyLogic/injectBlocks";
import getSnippet from "../../../../../redux/logic/getSnippet";

const implementationBlockTypes: Array<string> = [
  // Actions
  "action_implementation",
  "alert_implementation",
  "backup_snippet_implementation",
  "clear_array_implementation",
  "filter_array_implementation",
  "gmail_send_mail_implementation",
  "generate_uuid_implementation",
  "http_get_IMPL",
  "push_data_to_array_implementation",
  "set_var_implementation",
  // Data
  'data_array_implementation',
  'data_object_implementation',
  // UI
  "mui_box_implementation",
  "mui_root_box_implementation",
  "mui_button_implementation",
  "mui_card_implementation",
  "mui_checkbox_implementation",
  "mui_data_grid_implementation",
  "mui_fab_implementation",
  "mui_grid_container_implementation",
  "mui_image_implementation",
  "mui_paper_implementation",
  "mui_typography_implementation",
  "new_ui_styled_typography_implementation",
  "radio_buttons_implementation",
  "slider_implementation",
  "switch_implementation",
  "tabs_implementation",
  "text_editor_implementation",
  "text_field_implementation",
  // STYLE
  "style_implementation"
];

interface Props extends WorkAreaPageState {
  highlightComponentSnippetUuid: boolean,
  currentSnippetUUID: string | null,
  currentSnippetName: string | null,
  setHighlightComponent: (snippetUUID: string | null) => void,
  setDisplayMasks: (value: boolean) => void,
  xml: string,
  currentProjectUUID: string,
  currentSnippet: Snippet | null,
  toolbox: any,
  doesSnippetExist: (potentialSnippetUUID: string) => boolean,
  buildAndPushSnippetUuid: (snippetUUID: string) => void,
  setUpdateUncontrolledBlocklyEditor: (value: boolean) => any,
  pushSnippetUUIDToStack: (value: string) => any,
  upsertSnippet: (snippet: Snippet) => any
  clearAllMasks: () => any
  setWorkAreaLoadingState: (loadingState: LoadingState,
                            loadingProgress: number) => any;
}

interface State {
  altDown: boolean,
  recentClickTimeMs: number,
  editedBlockName: string,
  editedSnippet: Snippet | null,
  declarationBlockName: string,
}

class UncontrolledBlocklyEditor extends React.Component<Props, State> {
  simpleWorkspace: React.RefObject<unknown>;
  blocklyMoveLock: boolean;

  constructor(props: Props) {
    super(props);
    this.simpleWorkspace = React.createRef();
    this.handleBlocklyEvent = this.handleBlocklyEvent.bind(this);
    this.blocklyMoveLock = false;
    this.state = {
      altDown: false,
      recentClickTimeMs: 0,
      editedBlockName: "",
      editedSnippet: null,
      declarationBlockName: ""
    };
    const setter = (e: any) => {
      switch (e.key) {
        case 'Alt':
          this.setState({altDown: e.altKey});
          if (!this.props.highlightComponentSnippetUuid)
            this.props.setHighlightComponent(this.props.currentSnippetUUID);
          this.props.setDisplayMasks(e.altKey);
          break;
        default:
          break;
      }
    };
    document.addEventListener('keyup', setter);
    document.addEventListener('keydown', setter);
  }

  static mapReduxStateToProps(reduxState: any) {
    const workAreaPageState: WorkAreaPageState = reduxState.workAreaPageState;
    const allSnippets = reduxState.persistentState.allSnippets;
    const currentSnippet = getCurrentSnippet();
    const currentSnippetUUID: string | null = getCurrentSnippetUUID();
    const currentSnippetName: string = (currentSnippet === null ? '' :
        currentSnippet.name);
    return {
      ...workAreaPageState, ...{
        currentProjectUUID: reduxState.sessionState.currentProjectUUID,
        currentSnippetUUID,
        currentSnippetName,
        currentSnippet,
        toolbox: reduxState.persistentState.toolbox,
        isDrawerOpen: workAreaPageState.isDrawerOpen,
        highlightComponentSnippetUuid: reduxState.maskerState.highlightComponentSnippetUuid,
        doesSnippetExist: (potentialSnippetUUID: string) => {
          return Object.keys(allSnippets).includes(potentialSnippetUUID)
        },
      }
    }
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      pushSnippetUUIDToStack: (content: string) => {
        dispatch(pushSnippetUUIDToStack(content));
      },
      setWorkAreaPageState: (workAreaPageState: WorkAreaPageState) => {
        dispatch(setWorkAreaPageState(workAreaPageState));
      },
      setWYSIWYGEditorContentContent: (content: boolean, jsPrettify: true) => {
        dispatch(setWYSIWYGEditorContentContent(content, jsPrettify));
      },
      setWYSIWYGEditorXMLContent: (content: string) => {
        dispatch(setWYSIWYGEditorXMLContent(content));
      },
      upsertSnippet: (snippet: Snippet) => {
        dispatch(upsertSnippet(snippet, true));
      },
      setDisplayMasks: (value: boolean) => {
        dispatch(setDisplayMasks(value));
      },
      setHighlightComponent: (snippetUUID: string | null) => {
        dispatch(setHighlightComponent(snippetUUID));
      },
      setUpdateUncontrolledBlocklyEditor: (value: boolean) => {
        dispatch(setUpdateUncontrolledBlocklyEditor(value));
      },
      setWorkAreaLoadingState: (loadingState: LoadingState,
                                loadingProgress: number) => {
        dispatch(setWorkAreaLoadingState(loadingState, loadingProgress));
      },
      clearAllMasks: () => {
        dispatch(clearAllMasks());
      },
    }
  }

  shouldComponentUpdate(nextProps: Props, nextState: State) {
    UTILS.assert(this.simpleWorkspace.current);
    // @ts-ignore
    const workspace = this.simpleWorkspace.current.workspace;
    if (this.props.updateUncontrolledBlocklyEditor ||
        (this.props.currentSnippetUUID !== nextProps.currentSnippetUUID)) {
      if (nextProps.xml) {
        workspace.clear();
        UTILS.xmlToWorkspace(workspace, nextProps.xml);
      }
      this.props.setUpdateUncontrolledBlocklyEditor(false);
    }
    workspace.getToolbox().refreshSelection();
    return true;
  }

  render() {
    const self = this;
    const style = {
      width: self.props.blocklyEditorOpen ? '100%' : '0%',
      height: '100%',
    };
    // @ts-ignore
    if (this.simpleWorkspace && this.simpleWorkspace.current &&
        // @ts-ignore
        this.simpleWorkspace.current.workspace) {
      this.highlightExpandableBlocks();
      this.setImplementationOrDeclarationBlockName();
    }
    return <div style={style}>
      <BlocklyComponent
          toolBoxItems={this.props.toolbox}
          displayToolbox={!this.props.isDrawerOpen}
          // @ts-ignore
          ref={this.simpleWorkspace}
          readOnly={false}
          media={'media/'}
          trashcan={false}
          initialXml={``}>
        <MainToolBox/>
      </BlocklyComponent>
    </div>
  }

  highlightExpandableBlocks() {
    // @ts-ignore
    const workspace = this.simpleWorkspace.current.workspace;
    const allBlocks = workspace.getAllBlocks(false);
    allBlocks.forEach((block: any) => {
      const blockType = block.type;
      if (this.props.doesSnippetExist(blockType)) {
        workspace.highlightBlock(block.id, true);
      }
    });
  }

  setImplementationOrDeclarationBlockName() {
    // @ts-ignore
    const workspace = this.simpleWorkspace.current.workspace;
    const allBlocks = workspace.getAllBlocks(false);
    const self = this;
    allBlocks.forEach((block: any) => {
      const blockType = block.type;
      if (implementationBlockTypes.indexOf(blockType) !== -1) {
        const field = block.getField("snippet_name");
        if (field)
          field.setValidator(function (name: string) {
                if (name !== self.state.editedBlockName) {
                  self.setState({
                    editedBlockName: name,
                    editedSnippet: self.props.currentSnippet as Snippet
                  });
                }
              }
          );
      }
    });
  }

  componentDidMount = async () => {
    UTILS.assert(this.props.loadingState === LoadingState.BLANK);
    UTILS.assert(this.simpleWorkspace && this.simpleWorkspace.current &&
        // @ts-ignore
        this.simpleWorkspace.current.workspace);
    // @ts-ignore
    const workspace = this.simpleWorkspace.current.workspace;
    workspace.clear();
    Blockly.ContextMenuRegistry.registry = new Blockly.ContextMenuRegistry();
    setContextMenu(workspace);
    await injectAtomicBlocks();
    workspace.addChangeListener(this.handleBlocklyEvent);
    workspace.getToolbox().refreshSelection();
    BaseComponent.blocklyWorkspace = workspace;
    BaseComponent.appContext = this.context;
    // @ts-ignore
    global.context.workspace = workspace;
    this.props.setWorkAreaLoadingState(LoadingState.INITIALIZED,
        0);
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    const prevEditedBlockName = prevState.editedBlockName;
    const prevSnippetUUID: string = prevProps.currentSnippet ?
        prevProps.currentSnippet.snippetUUID : '';
    const snippetUUID: string = this.props.currentSnippet ?
        this.props.currentSnippet.snippetUUID : '';
    if (prevSnippetUUID !== snippetUUID) {
      this.setState({
        editedBlockName: '',
        editedSnippet: null,
      });
      this.setImplementationOrDeclarationBlockName();
      return;
    }
    const editedBlockName = this.state.editedBlockName;
    if (prevEditedBlockName !== editedBlockName) {
      const snippet: Snippet | null = this.state.editedSnippet;
      if (snippet !== null) {
        snippet.name = editedBlockName;
        this.props.upsertSnippet(snippet);
        injectBlocks(snippet.snippetUUID, snippet.type, editedBlockName);
      }
    }
  }

  async buildAndPushSnippetUuid(snippetUUID: string) {
    this.props.clearAllMasks();
    this.props.pushSnippetUUIDToStack(snippetUUID);
  }

  async handleBlocklyEvent(event: any) {
    if (this.props.blocklyEditorOpen) {
      // @ts-ignore
      const workspace = this.simpleWorkspace.current.workspace;
      UTILS.assert(workspace);
      switch (event.type) {
        case 'ui':
          let doubleClick = false;
          if (event.element && event.element === 'click') {
            const currentTime = new Date().getTime();
            doubleClick = (currentTime - this.state.recentClickTimeMs < 500);
            this.setState({recentClickTimeMs: new Date().getTime()});
            const snippetNavigate = doubleClick || this.state.altDown;
            if (snippetNavigate) {
              const clickedBlock = workspace.getBlockById(event.blockId);
              if (clickedBlock) {
                const clickedBlockType = clickedBlock.type;
                if (this.props.doesSnippetExist(clickedBlockType) && snippetNavigate) {
                  this.buildAndPushSnippetUuid(clickedBlockType);
                  break;
                }
                // Check if block is an implementation block of a JS snippet.
                if (UTILS.isSnippetImplementationBlockType(clickedBlockType)) {
                  const JSSnippetBlockType =
                      UTILS.declarationSnippetUUIDToJSSnippetBlockType(
                          UTILS.implementationBlockTypeToDeclarationSnippetUUID(clickedBlock.type));
                  if (this.props.doesSnippetExist(JSSnippetBlockType)) {
                    this.props.buildAndPushSnippetUuid(JSSnippetBlockType);
                    break;
                  }
                }
              }
            }
          }
          if (event.element && event.element === 'selected') {
            if (event.newValue) {
              // @ts-ignore
              const block = workspace.getBlockById(event.newValue);
              if (this.props.doesSnippetExist(block.type)) {
                this.props.setHighlightComponent(block.type);
              } else {
                this.props.setHighlightComponent(null);
              }
            } else {
              this.props.setHighlightComponent(null);
            }
          }
          break;
        case 'change':
        case 'delete':
        case 'move': {
          UTILS.assert(this.props.currentSnippet);
          const currentSnippet = this.props.currentSnippet as Snippet;
          if (event.type === 'move' && !this.blocklyMoveLock) {
            this.blocklyMoveLock = true;
            const templateBlock = workspace.getBlockById(event.blockId);
            if (templateBlock && CONSTANTS.TEMPLATE_SNIPPET_UUID.includes(
                templateBlock.type)) {
              const snippetUUID = await cloneTemplateBlock(workspace,
                  templateBlock);
              UTILS.assert(snippetUUID);
              currentSnippet.prerequisites.push(snippetUUID as string);
            }
            this.blocklyMoveLock = false;
          }
          // @ts-ignore
          if (this.props.currentSnippet.isContentGeneratedFromXml) {
            this.props.upsertSnippet({
              ...currentSnippet,
              xml: getBlocklyXml(workspace),
              // @ts-ignore
              content: generateContentFromWorkspace(workspace)
            });
          }
          break;
        }
        default:
          break;
      }
    }
  }
}

export default connect(UncontrolledBlocklyEditor.mapReduxStateToProps,
    UncontrolledBlocklyEditor.mapDispatchToProps, null,
    {forwardRef: true})(
    UncontrolledBlocklyEditor);

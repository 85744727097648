import { Component, createRef } from "react";
import * as Blockly from "blockly/core";
import store from '../store';
import { setUserEditorState } from '../redux/actions/actions';
import { clearAllMasks, upsertMask } from "../redux/actions/maskerActions";
import {
  pushSnippetUUIDToStack,
  setWorkAreaPageState
} from '../redux/actions/workAreaPageStateActions';
import {
  injectBlocksAndUpsertSnippet,
  upsertSnippet
} from "../redux/actions/persistentStateActions";
import evaluateSnippetImplementation
  from "../redux/logic/evaluateSnippetImplementation";
import { evaluateJSONFunction } from "../redux/logic/data_functions/DataFunction";
import createContentBasedDataSnippet from "./createContentBasedDataSnippet";
import Snippet from "./Snippet";
import SnippetTypes from "./SnippetTypes";
import UTILS from "./utils";

/**
 * API methods available for any Redux Component.
 * Note that in the interim, this class holds some non API methods.
 */

class BaseComponent extends Component {
  private uuid: any;
  private componentReference: React.RefObject<HTMLElement>;
  private shouldMaskComponent: boolean;

  constructor(props: any) {
    super(props);
    this.state = { boundingBox: undefined };
    this.uuid = UTILS.generateUUID();
    this.componentReference = createRef();
    // override and set false in derived component if component should not be masked.
    this.shouldMaskComponent = true;
  }

  static mapStateToProps(reduxState: any) {
    return {
      allVariables: reduxState.allVariables,
      workAreaPageState: reduxState.workAreaPageState,
      isSnippetEditorOpen: reduxState.workAreaPageState.isSnippetEditorOpen,
    }
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      setWorkAreaPageState: (workAreaPageState: any) => {
        dispatch(setWorkAreaPageState(workAreaPageState));
      },
      setUserEditorState: (editorName: string, state: any) => {
        dispatch(setUserEditorState(editorName, state));
      },
      pushSnippetUUIDToStack: (snippetUUID: string) => {
        dispatch(pushSnippetUUIDToStack(snippetUUID));
      },
      // @ts-ignore
      upsertMask: (snippetUUID, boundingBox, depth) => {
        dispatch(upsertMask(snippetUUID, boundingBox, depth));
      },
      clearAllMasks: () => {
        dispatch(clearAllMasks());
      },

      upsertSnippet: (snippet: Snippet) => {
        UTILS.assert(false);
        dispatch(upsertSnippet(snippet, false))
      },
      injectBlocksAndUpsertSnippet: (snippet: Snippet, type: SnippetTypes) => {
        dispatch(injectBlocksAndUpsertSnippet(snippet, false, type))
      },
      injectDataSnippet(varName: string, value: any) {
        const snippet = createContentBasedDataSnippet(varName, JSON.stringify(value));
        dispatch(injectBlocksAndUpsertSnippet(snippet, false, SnippetTypes.DATA));
      }
    }
  }

  render() {
    // @ts-ignore
    return this.Render();
  }

  async componentDidMount() {
    await (UTILS.asyncTimeout(1));
    if (this.componentReference.current && this.shouldMaskComponent) {
      const currentReference: HTMLElement = this.componentReference.current;
      const boundingBox = { ...getOffset(currentReference), ...getWidthHeight(currentReference) };
      const depth = getDepth(currentReference);
      // @ts-ignore
      this.props.upsertMask(this.SNIPPET_UUID, boundingBox, depth);
    }
  }

  static evaluateBlockStatement(block: any, name: string): any {
    // @ts-ignore
    let value = Blockly.JavaScript.statementToCode(block, name);
    value = evaluateJSONFunction(value, [evaluateSnippetImplementation(store)]);
    value = value || "";
    return value;
  }

  setFocusedComponent(value: string | undefined) {
    // @ts-ignore
    this.props.setWorkAreaPageState({ focusedInputComponent: value ? value : undefined });
  }

  getFocusedComponent() {
    // @ts-ignore
    return this.props.workAreaPageState.focusedInputComponent;
  }

  clearAllMasks() {
    // @ts-ignore
    this.props.clearAllMasks();
  }
}

export default BaseComponent;

// Important! Connect should be called only on derived classes and not in base class.
// See: https://stackoverflow.com/questions/53497025/react-redux-and-inheritance
// See connect call in ArenaBlockAPI.js
// @ts-ignore
function getOffset(element) {
  var left = 0;
  var top = 0;
  while (element && !isNaN(element.offsetLeft) && !isNaN(element.offsetTop)) {
    left += element.offsetLeft - element.scrollLeft;
    top += element.offsetTop - element.scrollTop;
    element = element.offsetParent;
  }
  return { top: top, left: left };
}

function getWidthHeight(element: HTMLElement) {
  return { width: element.offsetWidth, height: element.offsetHeight };
}

function getDepth(element: HTMLElement | null) {
  let depth = -1;
  while (element != null) {
    depth++;
    element = element.parentElement;
  }
  return depth;
}

import * as Blockly from "blockly/core";
import generateStyleCode from "../../generateStyleCode";

/** @function
 * @name muiImageImplementation
 * @returns Generate code for the mui_image_implementation block.
 * This is a generated plain HTML(!) component code, with content, style and actiin.
 */
export function muiImageImplementation(block) {
  const src = Blockly.JavaScript.statementToCode(block, 'src');
  const properties = Blockly.JavaScript.statementToCode(block, 'properties');
  let currentBlock = this.getInputTargetBlock('action');
  let actionCode = ``;
  while (currentBlock) {
    actionCode += `${Blockly.JavaScript[currentBlock.type].call(currentBlock, currentBlock)};`;
    currentBlock = currentBlock.getNextBlock();
  }
  let code = "class MUIImageImplementation extends BaseComponent {";
  code += "Render() {";
  code += "this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';";
  code += "const self = this;";
  code += generateStyleCode(block);
  if (actionCode)
    code += `style.cursor = "pointer";`;
  code += " return <img ref={self.componentReference}";
  if (src)
    code += ` src=${src}`;
  code += ` onClick={(event) => {${actionCode}}}`;
  code += ` style={style}`;
  code += ` ${properties} />}}`;
  return code;
}

import UTILS from "../../common/utils";

const {WebClient} = require('@slack/web-api');

class SlackAPI {

  constructor() {
    this.webClient = new WebClient(process.env.REACT_APP_SLACK_BOT_TOKEN);
  }

  async postMessage(channel, text) {
    UTILS.assert(typeof channel === 'string');
    UTILS.assert(typeof text === 'string');
    try {
      await this.webClient.chat.postMessage({
        channel,
        text
      });
    } catch (error) {
      console.log(error);
    }
  }
}

export default SlackAPI;

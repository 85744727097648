import React from "react";

export default class ColorToolBox extends React.Component {
  render() {
    return <category name="Colors">
      <block type="style_color">
        <statement name="value">
          <block type="style_color_picker">
            <field name="field_colour">#d654c2</field>
          </block>
        </statement>
      </block>
      <block type="style_background_color">
        <statement name="value">
          <block type="style_color_picker">
            <field name="field_colour">#038541</field>
          </block>
        </statement>
      </block>
      <block type="style_color_picker"/>
      <block type="style_grey_picker"/>
    </category>;
  }
}

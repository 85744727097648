import Snippet from "../../common/Snippet";
import getAllSnippetsTransitively from "./getAllSnippetsTransitively";
import generateSnippetUUID from "../../common/generateSnippetUUID";
import addSnippets, {SnippetTarget} from "../../common/addSnippets";

export default async function deepCloneSnippetAndAddToRedux(
    rootSnippetUUID: string): Promise<Array<Snippet>> {
  // returns the cloned Snippets topologically sorted where the rootSnippet
  // is the last element in the Array.
  let origSnippets: Array<Snippet> =
      getAllSnippetsTransitively(rootSnippetUUID).reverse();
  const clonedSnippets: Array<Snippet> = cloneSnippetsArray(origSnippets);
  await addSnippets(clonedSnippets, true, true,
      [SnippetTarget.GLOBAL, SnippetTarget.REDUX,
        SnippetTarget.BLOCKLY], null, null);
  return clonedSnippets;
}

export function cloneSnippetsArray(origSnippets: Array<Snippet>): Array<Snippet> {
  const uuidMap: Map<string, string> = new Map();
  origSnippets.map(snippet => uuidMap.set(
      snippet.snippetUUID, generateSnippetUUID()));
  const clonedSnippets: Array<Snippet> = new Array<Snippet>();
  origSnippets.forEach(snippet => (clonedSnippets.push(Object.assign({},
      snippet))));
  clonedSnippets.map(snippet => snippet.snippetUUID =
      uuidMap.get(snippet.snippetUUID) as string);
  clonedSnippets.map(snippet => mapSnippetPrerequisites(snippet, uuidMap));
  clonedSnippets.map(snippet => mapSnippetXML(snippet, uuidMap));
  return clonedSnippets;
}

function mapSnippetPrerequisites(snippet: Snippet, uuidMap: Map<string, string>) {
  for (let i = 0; i < snippet.prerequisites.length; i++)
    snippet.prerequisites[i] = uuidMap.get(snippet.prerequisites[i]) as string;
}

function mapSnippetXML(snippet: Snippet, uuidMap: Map<string, string>) {
  // See: https://stackoverflow.com/questions/15604140/replace-multiple-strings-with-multiple-other-strings
  const re = new RegExp(Array.from(uuidMap.keys()).join("|"), "gi");
  snippet.xml = snippet.xml || "";
  snippet.xml = snippet.xml.replace(re, function (matched) {
    // @ts-ignore
    return uuidMap.get(matched) as string;
  });
}

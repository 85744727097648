import * as Blockly from 'blockly/core';
import getSurroundParentChecks from "./BlocklyLogic/getSurroundParentChecks";

/**
 * A connection checker that imposes stricter typing rules than the default
 * checker in Blockly, but uses the same rules for dragging and safety.
 * This checker still expects nullable arrays of string for connection
 * type checks, and still looks for intersections in the arrays. Unlike the
 * default checker, null checks arrays are only compatible with other null
 * arrays.
 * @implements {Blockly.IConnectionChecker}
 */

export class ArenaBlockChecker extends Blockly.ConnectionChecker {
  doTypeChecks(a: Blockly.Connection, b: Blockly.Connection) {
    const aBlock: Blockly.Block = a.getSourceBlock();
    const bBlock: Blockly.Block = b.getSourceBlock();
    let aChecks: Array<string> = a.getCheck();
    let bChecks: Array<string> = b.getCheck();
    if (aChecks === null || bChecks === null)
      return true;
    if (aBlock.getSurroundParent()) {
      aChecks = getSurroundParentChecks(aBlock);
      for (let i = 0; i < bChecks.length; i++)
        if (aChecks.includes(bChecks[i])) {
          return true;
        }
      return false;
    } else if (bBlock.getSurroundParent()) {
      bChecks = getSurroundParentChecks(bBlock);
      for (let i = 0; i < aChecks.length; i++) {
        if (bChecks.includes(aChecks[i])) {
          return true;
        }
      }
      return false;
    } else {
      for (let i = 0; i < aChecks.length; i++)
        if (bChecks.includes(aChecks[i])) {
          return true;
        }
      for (let i = 0; i < bChecks.length; i++)
        if (aChecks.includes(bChecks[i])) {
          return true;
        }
      if (aChecks[0].includes('ACTION_CONNECTOR') && bChecks[0].includes('DATA_CONNECTOR')) {
        return true;
      }
      if (aChecks[0].includes('DATA_CONNECTOR') && bChecks[0].includes('ACTION_CONNECTOR')) {
        return true;
      }
      if (aChecks[0].includes('ACTION_CONTAINER_CONNECTOR') && bChecks[0].includes('ACTION_CONNECTOR')) {
        return true;
      }
      if (aChecks[0].includes('ACTION_CONNECTOR') && bChecks[0].includes('ACTION_CONTAINER_CONNECTOR')) {
        return true;
      }

      return false;
    }
    return true;
  }
}

export const registrationType: any = Blockly.registry.Type.CONNECTION_CHECKER;
export const registrationName = 'ArenaBlockChecker';

// Register the checker so that it can be used by name.
Blockly.registry.register(
    registrationType, registrationName, ArenaBlockChecker);

export const pluginInfo = {
  [registrationType]: registrationName,
};
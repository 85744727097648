import * as Blockly from 'blockly/core';
import getSnippet from '../../../../../redux/logic/getSnippet';
import cloneSnippet from '../../../../../redux/logic/cloneSnippet';
import ContextMenuItems from "./ContextMenuItems";
import Snippet from "../../../../../common/Snippet";
import addSnippets, {SnippetTarget} from "../../../../../common/addSnippets";
import deepClone from "./deepClone";

export default function setContextMenu(workspace: any) {
  Blockly.ContextMenuRegistry.registry.register(ContextMenuItems.shallowCloneBlock(
      (block: any) => shallowClone(block, workspace), enableBlock));
  Blockly.ContextMenuRegistry.registry.register(ContextMenuItems.deepCloneBlock(
      (block: any) => deepClone(block, workspace), enableBlock));
}

function shallowClone(block: any, workspace: any): Snippet {
  const originalSnippetUUID: string = block.type;
  const clone: Snippet = cloneSnippet(originalSnippetUUID);
  const targets: Array<SnippetTarget> = [SnippetTarget.REDUX,
    SnippetTarget.GLOBAL, SnippetTarget.BLOCKLY, SnippetTarget.WORKSPACE];
  addSnippets([clone], true, true,
      targets, workspace, null);
  return clone;
}

function enableBlock(block: any) {
  return block && block.block && block.block.type &&
  getSnippet(block.block.type) ? 'enabled' : 'disabled' && false;
}



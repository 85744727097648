import store from "../../../store";

import Snippet from "../../../common/Snippet";

import {upsertSnippet} from "../../../redux/actions/persistentStateActions";
import {UnlockSnippetFormValue} from "../../../redux/reducers/forms/unlockSnippetReducer";
import UTILS from "../../../common/utils";

export default async function processForm(form: UnlockSnippetFormValue) {
  const allSnippets = store.getState().persistentState.allSnippets;
  const unlockSnippetUUID = form.unlockSnippetUUID;
  const snippet: Snippet | null = unlockSnippetUUID ?
      allSnippets[unlockSnippetUUID] : null;
  if (snippet !== null) {
    UTILS.assert(snippet !== undefined);
    snippet.isContentGeneratedFromXml = false;
    snippet.xml = '';
    store.dispatch(upsertSnippet(snippet, true));
  }
}

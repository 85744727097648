enum DataFunctionTypes {
  FUNCTION_CALL = 'FUNCTION_CALL',
}

export interface JSONFunctionCall {
  'type': DataFunctionTypes.FUNCTION_CALL;
  name: string;
  values: Array<any | JSONFunctionCall>
}

export interface JSONFunctionImplementation {
  name: string;
  functionImplementation: any;
}

/**
 *
 * @param data
 * @param implementations
 *
 *  E1. function calls function
 *    const fooCall: JSONFunctionCall = createJSONFunctionCall('foo', [2]);
 *    const fooDeclaration: JSONFunctionImplementation =
 *    createJSONFunctionImplementation('foo', 'x', '{return x - 1;}');
 *    expect(evaluateJSONFunction(JSON.parse(JSON.stringify(fooCall)),
 [fooDeclaration])).toEqual(1);

 *  E2. function calls function
 *   const fooCall: JSONFunctionCall = createJSONFunctionCall('foo',['blah(2)']);
 *   const fooDeclaration: JSONFunctionImplementation =
 *     createJSONFunctionImplementation('foo', 'x', '{ return x - 1;}');
 *   const blahDeclaration: JSONFunctionImplementation =
 *   createJSONFunctionImplementation('blah', 'x', '{ return x * 2;}');
 *    expect(evaluateJSONFunction(fooCall,[fooDeclaration])).toEqual(256);
 */
function evaluateJSONFunction(data: any | JSONFunctionCall,
                              implementations: Array<JSONFunctionImplementation> = []) {
  if (data === null)
    return data;
  switch (typeof data) {
    case "undefined":
    case "boolean":
    case "string":
    case "function":
    case "symbol":
    case "bigint":
    case "number":
      return data;
    case "object":
      if (Array.isArray(data)) {
        return _evaluateArray(data, implementations);
      } else if (data.type && data.type ===
          DataFunctionTypes.FUNCTION_CALL) {
        return _evaluateJSONFunctionCall(data, implementations);
      } else {
        return _evaluateObject(data, implementations);
      }
  }
}

function createJSONFunctionImplementation(name: string,
                                          params: string, body: string)
    : JSONFunctionImplementation {
  return {
    name,
    functionImplementation: new Function(params, body)
  };
}

function createJSONFunctionImplementationFromFunction(name: string,
                                                      functionImplementation: any)
    : JSONFunctionImplementation {
  return {
    name,
    functionImplementation
  };
}

function createJSONFunctionCall(name: string, values: Array<any>):
    JSONFunctionCall {
  return {
    'type': DataFunctionTypes.FUNCTION_CALL,
    name,
    values
  };
}

// Local functions.
function _evaluateArray(array: Array<any>, functions:
    Array<JSONFunctionImplementation>): Array<any> {
  let res = [];
  for (let entery of array)
    res.push(evaluateJSONFunction(entery, functions));
  return res;
}

function _evaluateJSONFunctionCall(functionCall: JSONFunctionCall,
                                   functions: Array<JSONFunctionImplementation>) {
  let evaluatedParams: Array<any>;
  let functionNames: Array<string> = [];
  let functionImplementations: Function[] = [];
  functions.forEach(entry => {
    functionNames.push(entry.name);
    functionImplementations.push(entry.functionImplementation)
  });
  const functionNamesAsString = functionNames.join(',');
  evaluatedParams = [];
  functionCall.values.forEach(entry => {
    evaluatedParams.push(evaluateJSONFunction(entry, functions));
  });
  return (new Function(functionNamesAsString,
      `return ${functionCall.name}(${evaluatedParams})`)
  (...functionImplementations));
}

function _evaluateObject(object: Object,
                         functions: Array<JSONFunctionImplementation>): Object {
  let res: Record<string, any> = {};
  for (const [key, value] of Object.entries(object)) {
    res[key] = (evaluateJSONFunction(value, functions));
  }
  return res;
}

export {
  evaluateJSONFunction,
  createJSONFunctionImplementation,
  createJSONFunctionCall,
  createJSONFunctionImplementationFromFunction
};

/** @function
 * @name setIsLoggedIn
 * @param {boolean} isLoggedIn.
 */
export function setIsLoggedIn(isLoggedIn: boolean) {
  return {
    type: "SET_IS_LOGGED_IN",
    payload: { isLoggedIn }
  };
}

/** @function
 * @name setIsDeveloper
 * @param {boolean} isDeveloper.
 */
export function setIsDeveloper(isDeveloper: boolean) {
  return {
    type: "SET_IS_DEVELOPER",
    payload: { isDeveloper }
  };
}

/** @function
 * @name setIsNelifySession
 * @param {boolean} isNetlifySession.
 */
export function setIsNelifySession(isNetlifySession: boolean) {
  return {
    type: "SET_IS_NETLIFY_SESSION",
    payload: { isNetlifySession }
  };
}

/** @function
 * @name setIsGitSession
 * @param {boolean} isGitSession.
 */
export function setIsGitSession(isGitSession: boolean) {
  return {
    type: "SET_IS_GIT_SESSION",
    payload: { isGitSession }
  };
}

/** @function
 * @name setUserUUID
 * @param {boolean} userUUID.
 */
export function setUserUUID(userUUID: string) {
  return {
    type: "SET_USER_UUID",
    payload: { userUUID }
  };
}

/** @function
 * @name setIsDeployedSession
 * @param {boolean} isDeployedSession.
 */
export function setIsDeployedSession(isDeployedSession: boolean) {
  return {
    type: "SET_IS_DEPLOYED_SESSION",
    payload: { isDeployedSession }
  };
}

/** @function
 * @name setIsNetlifySession
 * @param {boolean} isNetlifySession.
 */
export function setIsNetlifySession(isNetlifySession: boolean) {
  return {
    type: "SET_IS_NETLIFY_SESSION",
    payload: { isNetlifySession }
  };
}

/** @function
 * @name setCurrentProject
 * @param {string} currentProjectUUID.
 */
export function setCurrentProject(currentProjectUUID: string) {
  return {
    type: "SET_CURRENT_PROJECT",
    payload: { currentProjectUUID }
  };
}

/** @function
 * @name setWasProjectInitialized
 * @param {boolean} wasProjectInitialized
 */
export function setWasProjectInitialized(wasProjectInitialized: boolean) {
  return {
    type: "SET_WAS_PROJECT_INITIALIZED",
    payload: { wasProjectInitialized }
  };
}

import {
  InjectBlockAndCreateSnippetAction,
  InjectBlockAndCreateSnippetActionPayload,
  InjectBlockForSnippetAction,
  PersistentStateAction,
  PersistentStateActionPayload,
  upsertSnippet, UpsertSnippetAction, UpsertSnippetsArrayAction
} from "../actions/persistentStateActions";
import {injectBlocks} from "../../Blockly/BlocklyLogic/injectBlocks";
import store from "../../store";
import ProjectCRUD from "../../server_interface/ProjectCRUD";
import getCurrentSnippet from "../logic/getCurrentSnippet";
import Snippet from "../../common/Snippet";
import {updateWorkAreaPageStateFromSnippet} from "../actions/workAreaPageStateActions";
import UTILS from "../../common/utils";
import SnippetCRUD from "../../server_interface/SnippetCRUD";
import Project from "../../common/Project";

// eslint-disable-next-line require-yield
export function* upsertSnippetSaga(action: UpsertSnippetAction) {
  if (action.payload.upsertToBE && store.getState().sessionState.isLoggedIn) {
    SnippetCRUD.upsertSnippet(action.payload.snippet);
  }
}

export function* upsertSnippetsArraySaga(action: UpsertSnippetsArrayAction) {
  if (action.payload.upsertToBE && store.getState().sessionState.isLoggedIn) {
    action.payload.snippetsArray.forEach(snippet =>
        SnippetCRUD.upsertSnippet(snippet));
  }
}

export function* upsertProjectSaga(action: any) {
 if (action.payload.project && store.getState().sessionState.isLoggedIn) {
   ProjectCRUD.upsertProject(action.payload.project as Project);
 }
}

/** @function
 * @memberof ReduxSagas
 * @name injectBlocksSaga
 * @param {ReduxAction} action
 * @description Given a new block, inject it to Blockly and into the matching ToolBox.
 * @see {@link foo} for further information.
 */
// eslint-disable-next-line require-yield
export function* injectBlocksSaga(action: InjectBlockForSnippetAction) {
  const payload = action.payload;
  injectBlocks(payload.snippetUUID, payload.type, payload.name);
}

/** @function
 * @memberof ReduxSagas
 * @name injectBlocksAndUpsertSnippetSaga
 * @param {InjectBlockAndCreateSnippetAction} action
 * @description Inject relevant blocks and once the block is injected, upsert the snippet to redux.
 */
// eslint-disable-next-line require-yield
export function* injectBlocksAndUpsertSnippetSaga(action: InjectBlockAndCreateSnippetAction) {
  const payload: InjectBlockAndCreateSnippetActionPayload = action.payload;
  const snippet = action.payload.snippet;
  injectBlocks(snippet.snippetUUID, payload.type,
      snippet.name ? snippet.name : snippet.snippetUUID);
  store.dispatch(upsertSnippet(snippet, action.payload.upsertToBE));
}

/** @function
 * @memberof ReduxSagas
 * @name deleteProjectSaga
 * @param {PersistentStateAction} action
 * @description delete project from server.
 */
// eslint-disable-next-line require-yield
export function* deleteProjectSaga(action: PersistentStateAction) {
  const payload: PersistentStateActionPayload = action.payload;
  ProjectCRUD.deleteProject(payload.projectUUID);
}

// eslint-disable-next-line require-yield
export function* callWorkAreaPageStateFromCurrentSnippetSaga() {
  const snippet: Snippet | null = getCurrentSnippet();
  UTILS.assert(snippet !== undefined);
  if (snippet !== null)
    store.dispatch(updateWorkAreaPageStateFromSnippet(snippet));
}

import React from "react";
import ToolboxCategory from "../../../../../common/ToolboxCategory";

export default class HTMLGroupingToolBox extends React.Component {
  render() {
    return <category name="Forms">
      <category name="button" toolboxitemid={ToolboxCategory.HTML_BODY}></category>
      <category name="button" toolboxitemid={ToolboxCategory.HTML_BUTTON}></category>
    </category>
  }
}
import React from "react";
import AnimationsToolBox from "./AnimationsToolBox";
import ColorToolBox from "./ColorToolBox";
import PositioningToolBox from "./PositioningToolBox";
import MarginsToolBox from "./MarginsToolBox";
import PaddingToolBox from "./PaddingToolBox";
import SizeToolBox from "./SizeToolBox";
import ToolboxCategory from "../../../common/ToolboxCategory";
import UTILS from "../../../common/utils";

export default class StyleToolBox extends React.Component {
  render() {
    return <category name="Style" colour={UTILS.getColorPalette().STYLE}
                     toolboxitemid={ToolboxCategory.STYLE}>
      <ColorToolBox/>
      <MarginsToolBox/>
      <PaddingToolBox/>
      <PositioningToolBox/>
      <SizeToolBox/>
      <AnimationsToolBox/>
    </category>;
  }
}

import React from "react";
import {connect} from "react-redux";
// @ts-ignore
import {Editor} from "react-draft-wysiwyg";
import UTILS from "../../../../common/utils";
import {setWorkAreaPageState} from "../../../../redux/actions/workAreaPageStateActions";
import SnippetTypes from "../../../../common/SnippetTypes";
// @ts-ignore
import {convertToRaw} from "draft-js";
// @ts-ignore
import draftToHtml from "draftjs-to-html";
import Snippet from "../../../../common/Snippet";
import {WorkAreaPageState} from "../../../../redux/reducers/workAreaPageStateReducer";
import store from "../../../../store";
import {setIsControllerDisabled} from "../../../../redux/actions/workAreaPageStateActions";
import {setAlert} from "../../../../redux/actions/actions";
import {AlertOptions} from "../../../../redux/reducers/alertReducer";

interface Props extends WorkAreaPageState {
  currentSnippet: Snippet | null,
  setWorkAreaPageState: (workAreaPageState: WorkAreaPageState) => any,
  isEditorEnabled: boolean,
  setIsControllerDisabled : any;
}

interface State {
}

class SimpleWSYIWYGEditor extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.updateSnippet = this.updateSnippet.bind(this);
    this.handleBeforeInput = this.handleBeforeInput.bind(this);
  }

  static mapReduxStateToProps(reduxState: any) {
    const workAreaPageState: WorkAreaPageState = reduxState.workAreaPageState;
    const allSnippets = reduxState.persistentState.allSnippets;
    const currentSnippetUUID = workAreaPageState.snippetUUIDStackEnd === 0 ? '' :
        workAreaPageState.snippetUUIDStack[workAreaPageState.snippetUUIDStackEnd - 1];
    const currentSnippet: Snippet | null = currentSnippetUUID ? allSnippets[currentSnippetUUID] :
        null;
    const isEditorEnabled: boolean = currentSnippet == null ? false :
        (!(currentSnippet.isContentGeneratedFromXml) &&
            workAreaPageState.WYSIWYGIsContentContent);
    return {
      WYSIWYGEditorOpen: workAreaPageState.WYSIWYGEditorOpen,
      WYSIWYGIsContentContent: workAreaPageState.WYSIWYGIsContentContent,
      WYSIWYGEditorContentState: workAreaPageState.WYSIWYGEditorContentState,
      WYSIWYGEditorXMLState: workAreaPageState.WYSIWYGEditorXMLState,
      isSnippetEditorOpen: workAreaPageState.isSnippetEditorOpen,
      isEditorEnabled,
      currentSnippet,
    }
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      setWorkAreaPageState: (workAreaPageState: WorkAreaPageState) => {
        dispatch(setWorkAreaPageState(workAreaPageState));
      },
      setAlert: (alertOptions: AlertOptions) => {
        dispatch(setAlert(alertOptions));
      },
      setIsControllerDisabled: (disableController: boolean) => {
        dispatch(setIsControllerDisabled(disableController));
      },
    };
  }

  render() {
    const self = this;
    const style = {
      visibility: this.props.isSnippetEditorOpen ? 'visible' : 'hidden',
      width: this.props.isSnippetEditorOpen && this.props.WYSIWYGEditorOpen ?
          '100%' : '0%',
      height: '100%',
      maxHeight: '100%',
      overflow: 'auto',
    };
    // @ts-ignore
    return <div style={style}>
      <Editor
          handleBeforeInput={self.handleBeforeInput}
          toolbarClassName="toolbarClassName"
          wrapperClassName="wrapperClassName"
          editorClassName="editorClassName"
          editorState={this.props.WYSIWYGIsContentContent ?
              this.props.WYSIWYGEditorContentState :
              this.props.WYSIWYGEditorXMLState}
          onEditorStateChange={self.onEditorStateChange}
          onBlur={self.updateSnippet}
          toolbarStyle={{
            backgroundColor: "#DDDDDD", borderStyle: "solid", borderWidth: 1,
            borderColor: "#BBB"
          }}
          toolbar={{
            options: ['fontSize', 'fontFamily', 'history', 'inline',
              'colorPicker'],
            inline: {
              options: ['bold', 'italic', 'underline', 'strikethrough',
                'monospace'],
            },
            history: {
              inDropdown: false,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ['undo', 'redo'],
              undo: {className: undefined},
              redo: {className: undefined},
            },
            colorPicker: {
              className: undefined,
              component: undefined,
              popupClassName: undefined,
              colors: ['rgb(0,0,0)',
                UTILS.getColorPalette().DATA,
                UTILS.getColorPalette().UI,
                UTILS.getColorPalette().STYLE,
                UTILS.getColorPalette().ACTION],
            },
            fontSize: {
              options: [8, 9, 10, 11, 12, 14, 16, 18, 24],
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
            },
            fontFamily: {
              options: ['monospace', 'Arial', 'Georgia', 'Impact', 'Tahoma',
                'Times New Roman', 'Verdana'],
            },
            blockType: {
              className: 'bordered-option-classname',
            }
          }}/>
    </div>
  }

  onEditorStateChange(WYSIWYGEditorState: any) {
    this.props.setIsControllerDisabled(true);
    const workAreaPageState = this.props.WYSIWYGIsContentContent ?
        {WYSIWYGEditorContentState: WYSIWYGEditorState} :
        {WYSIWYGEditorXMLState: WYSIWYGEditorState};
    this.props.setWorkAreaPageState(workAreaPageState as WorkAreaPageState);
  }

  handleBeforeInput(chars: string) {
    if (this.props.isEditorEnabled) {
      return 'not-handled';
    } else {
      const alertOptions: AlertOptions = {
        alertTitle: "Snippet is locked",
        alertContent: "Unock snippet in order to edit it directly." +
            "This can be done by clicking the lock button in the " +
            "control panel above.",
        alertDurationMsc: -1, alertSeverity: "warning",
      };
      store.dispatch(setAlert(alertOptions));
      return 'handled';
    }
  }

  updateSnippet() {
    this.props.setIsControllerDisabled(false);
    UTILS.assert(this.props.currentSnippet !== undefined);
    const snippet: Snippet = this.props.currentSnippet as Snippet;
    if (snippet.isContentGeneratedFromXml) {
      // @ts-ignore
      //snippet.xml = this.blocklyEditor.current.getBlocklyXml();
    } else {
      const editorContent =
          this.props.WYSIWYGEditorContentState.getCurrentContent();
      switch (snippet.type) {
        case SnippetTypes.HTML:
          snippet.content = draftToHtml(convertToRaw(editorContent));
          break;
        default:
          snippet.content = editorContent.getPlainText().replace(/\n/g, "");
      }
    }
  }
}

export default connect(SimpleWSYIWYGEditor.mapReduxStateToProps,
    SimpleWSYIWYGEditor.mapDispatchToProps)(SimpleWSYIWYGEditor);

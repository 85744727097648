import UTILS from "../../common/utils";
import SnippetTypes from "../../common/SnippetTypes";
import Snippet from "../../common/Snippet";

/** @module redux/reducers/controllerReducer */

const initControllerOptions = {
  controllerBlocksToggleButtonDisabled: false,
  controllerContentButtonDisabled: false,
  controllerSnippetLocked: true,
  snippetLocked: true,
  isControllerDisabled: false,
};

/** @function
 * @memberof ReduxReducers
 * @name controllerReducer
 * @description Manage the state variables that are managed by the controller UI.
 * component.
 * Handles the UPDATE_WORK_AREA_PAGE_STATE_FROM_SNIPPET action.
 */
const controllerReducer = (arenaController = initControllerOptions, action: any) => {
  switch (action.type) {
    case 'UPDATE_WORK_AREA_PAGE_STATE_FROM_SNIPPET':
      UTILS.assert(action.payload.snippet !== undefined);
      return {...arenaController, ...(controllerOptionsFromSnippet(action.payload.snippet))};
    case 'SET_IS_CONTROLLER_DISABLED':
      return {
        ...arenaController,
        ...{
          isControllerDisabled: action.payload.value,
        }
      };
    default:
      return arenaController;
  }
};

/**
 * Set the state variables that are managed by the controller from a given snippet.
 */
function controllerOptionsFromSnippet(snippet: Snippet) {
  UTILS.assert(snippet !== undefined);
  UTILS.assert(snippet.isContentGeneratedFromXml ||
      (snippet.content.length > 0));
  if (snippet.type === SnippetTypes.HTML)
    return controllerOptionsFromBlocklessSnippet(snippet);
  return controllerOptionsFromBlockBasedSnippet(snippet);
}

function controllerOptionsFromBlockBasedSnippet(snippet: Snippet) {
  const controllerBlocksToggleButtonDisabled = !snippet.isContentGeneratedFromXml;
  const controllerContentButtonDisabled = !snippet.isContentGeneratedFromXml;
  const controllerXMLButtonDisabled = !snippet.isContentGeneratedFromXml;
  const controllerSnippetLocked = snippet.isContentGeneratedFromXml;
  return {
    controllerBlocksToggleButtonDisabled,
    controllerContentButtonDisabled,
    controllerXMLButtonDisabled,
    controllerSnippetLocked
  };
}

function controllerOptionsFromBlocklessSnippet(snippet: Snippet) {
  const controllerBlocksToggleButtonDisabled = true;
  const controllerContentButtonDisabled = false;
  const controllerXMLButtonDisabled = true;
  const controllerSnippetLocked = snippet.isContentGeneratedFromXml;
  return {
    controllerBlocksToggleButtonDisabled,
    controllerContentButtonDisabled,
    controllerXMLButtonDisabled,
    controllerSnippetLocked
  };
}

export default controllerReducer;

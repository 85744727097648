import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Typography, withStyles} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar/AppBar';
import IconButton from '@material-ui/core/IconButton';
import ImageList from '@material-ui/core/ImageList';
import ImageListItem from '@material-ui/core/ImageListItem';
import ListItem from '@material-ui/core/ListItem';
import ImageListItemBar from '@material-ui/core/ImageListItemBar';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import HomeIcon from '@material-ui/icons/Home';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UTILS from '../../../common/utils';
import {deleteProject} from '../../../redux/actions/persistentStateActions';
import {setCreateProjectForm} from '../../../redux/actions/forms/createProjectFormActions';
import {
  setCurrentProject,
  setWasProjectInitialized
} from '../../../redux/actions/sessionStateActions';
import {
  setIsSnippetEditorOpen,
  setWorkAreaLoadingState
} from '../../../redux/actions/workAreaPageStateActions';
import AccountIcon from '../../AccountIcon';
import {SUITE_TYPE} from "../../../redux/reducers/reducerTypes";
import {setAlert} from "../../../redux/actions/actions";
import ArenaAlert from "../../ArenaAlert";
import {AlertOptions} from "../../../redux/reducers/alertReducer";
import {
  LoadingState,
  WorkAreaPageState
} from "../../../redux/reducers/workAreaPageStateReducer";
import {PersistentState} from "../../../redux/reducers/persistentStateReducer";
import {setModifyProjectForm} from "../../../redux/actions/forms/modifyProjectFormActions";
import getProject from "../../../redux/logic/getProject";
import Project from "../../../common/Project";
const StyledAppBar = withStyles({root: {backgroundColor: "#FFF"}})(AppBar);
const StyledImageListItemBar = withStyles(
    {root: {backgroundColor: UTILS.getColorPalette().DATA}})(ImageListItemBar);

const useStyles = () => ({
  toggle: {
    marginRight: 8,
    marginLeft: 8,
    height: 40,
    width: 40,
  },
  imageList: {
    position: 'fixed',
    width: "80vw",
    height: "72vh",
    left: "16vw",
    top: "24vh",
  },
  imageListItem: {
    borderStyle: 'solid',
    borderWidth: 'thin',
    borderColor: UTILS.getColorPalette().DISABLED,
    margin: '2px',
    cursor: 'pointer'
  },
  createProjectItem: {
    display: 'flex',
    flexDirection: 'column',
    displayItems: 'center',
    justifyContent: 'center',
  },
  title: {
    position: 'fixed',
    top: "16vh",
    left: "16vw",
  },
  icon: {
    color: 'white',
  },
});

type Props = {
  loadingState: LoadingState,
  userProjects: any;
  publicProjects: any;
  classes: any;
  setAlert: any;
  setCreateProjectForm: any;
  setModifyProjectForm: any;
  isLoggedIn: boolean;
  history: any;
  setWorkAreaLoadingState: (loadingState: LoadingState,
                            loadingProgress: number) => any;
  setIsSnippetEditorOpen: (value: boolean) => any;
  setWasProjectInitialized: (value: boolean) => any;
}

type DashboardPageLocalState = {
  anchorEl: HTMLElement | null;
  projectUUID: string | null;
}

class DashboardPage extends Component<Props, DashboardPageLocalState> {

  constructor(props: Props) {
    super(props);
    this.state = {
      anchorEl: null,
      projectUUID: null,
    };
  }

  static mapStateToProps(reduxState: any) {
    const workAreaPageState: WorkAreaPageState = reduxState.workAreaPageState;
    const persistentState: PersistentState = reduxState.persistentState;
    return {
      loadingState: workAreaPageState.loadingState,
      userProjects: persistentState.userProjects,
      publicProjects: persistentState.publicProjects,
      isLoggedIn: reduxState.sessionState.isLoggedIn,
      workspaceInitialed: reduxState.wor
    }
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      setAlert: (arenaAlertOptions: AlertOptions) => {
        dispatch(setAlert(arenaAlertOptions));
      },
      deleteProject: (value: string) => {
        dispatch(deleteProject(value));
      },
      setModifyProjectForm: (form: any) => {
        dispatch(setModifyProjectForm(form));
      },
      setCreateProjectForm: (form: any) => {
        dispatch(setCreateProjectForm(form));
      },
      setCurrentProject: (currentProjectUUID: string) => {
        dispatch(setCurrentProject(currentProjectUUID));
      },
      setWorkAreaLoadingState: (loadingState: LoadingState,
                                loadingProgress: number) => {
        dispatch(setWorkAreaLoadingState(loadingState, loadingProgress));
      },
      setIsSnippetEditorOpen: (value: boolean) => {
        dispatch(setIsSnippetEditorOpen(value));
      },
      setWasProjectInitialized: (value: boolean) => {
        dispatch(setWasProjectInitialized(value));
      },
    }
  }

  render() {
    const {classes} = this.props;
    const self = this;
    const userProjects = this.props.userProjects;
    UTILS.assert(userProjects);
    let items: Array<any> = Array.from(Object.values(userProjects));
    const TITLE = 'Projects';
    return <div>
      <StyledAppBar position="static">
        <Toolbar>
          <IconButton onClick={() => {
            // @ts-ignore
            this.props.history.push('/')
          }}>
            <HomeIcon/>
          </IconButton>
          <div style={{flexGrow: 1}}/>
          <AccountIcon/>
        </Toolbar>
      </StyledAppBar>
      <Menu
          id="simple-menu"
          anchorEl={self.state.anchorEl}
          keepMounted
          open={Boolean(self.state.anchorEl)}
          onClose={() => self.setState({
            anchorEl: null,
            projectUUID: null
          })}
      >
        <MenuItem
            onClick={() => {
              self.openProject(self.state.projectUUID);

            }}>Edit project</MenuItem>
        <MenuItem
            onClick={() => {
              self.modifyProject(self.state.projectUUID);
            }}>Modify properties</MenuItem>
        <MenuItem
            onClick={() => {
              self.deleteProject(self.state.projectUUID);
            }}>Delete</MenuItem>
      </Menu>
      <Typography variant="h6" gutterBottom
                  className={classes.title}> {TITLE}</Typography>
      <ImageList rowHeight={320} cols={4} gap={4}
                 className={classes.imageList}>
        <ListItem key={'new project'}
                  className={`${classes.imageListItem} ${classes.createProjectItem}`}
                  onClick={() => {
                    if (self.props.isLoggedIn) {
                      self.props.setCreateProjectForm({isOpen: true});
                    } else {
                      self.props.setAlert({
                        alertContent: "Log in as user in order to create a new project.\n" +
                            " (Click user icon on the top right corner.)",
                        alertDurationMsc: 7000,
                        alertSeverity: "error",
                        alertTitle: "Not logged in"
                      });
                    }
                  }}>
          <br/>
          <IconButton>
            <AddCircleIcon/>
          </IconButton>
          <br/>
          <Typography variant="subtitle1" gutterBottom>
            New Project
          </Typography>
        </ListItem>
        {items.map((item) => (
            <ImageListItem key={item.projectUUID}
                           className={classes.imageListItem}
                           onClick={() => {
                             self.props.setWorkAreaLoadingState(
                                 self.props.loadingState === LoadingState.BLANK ?
                                     LoadingState.BLANK : LoadingState.BLANK, 0);
                             this.props.setIsSnippetEditorOpen(true);
                             this.props.setWasProjectInitialized(false);
                             this.props.history.push({
                               pathname: `/main/${item.projectUUID}`,
                               search: '?edit=true'
                             });
                           }}>
              <img src={`${item.imageURL}`} alt={item.name}/>
              <StyledImageListItemBar
                  title={item.name}
                  subtitle={item.isPublic ? "Public" : "Private"}
                  actionIcon={
                    <IconButton aria-label={`info about ${item.name}`}
                                className={classes.icon}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  self.setState({
                                    anchorEl: e.currentTarget,
                                    projectUUID: item.projectUUID
                                  });
                                }}>
                      <MoreVertIcon/>
                    </IconButton>
                  }
              />
            </ImageListItem>
        ))}
      </ImageList>
      <ArenaAlert/>
    </div>;
  }

  openProject(projectUUID: string | null) {
    UTILS.assert(projectUUID);
    this.props.setWorkAreaLoadingState(
        this.props.loadingState === LoadingState.BLANK ?
            LoadingState.BLANK : LoadingState.BLANK, 0);
    this.props.setIsSnippetEditorOpen(true);
    this.props.setWasProjectInitialized(false);
    this.props.history.push({
      pathname: `/main/${projectUUID}`,
      search: '?edit=true'
    });
  }

  modifyProject(projectUUID: string | null) {
    UTILS.assert(projectUUID);
    this.setState({
      anchorEl: null
    });
    const project: Project = getProject(projectUUID as string) as Project;
    UTILS.assert(project);
    this.props.setModifyProjectForm({
      imageURL: project.imageURL,
      isOpen: true,
      isPublic: project.isPublic,
      name: project.name,
      projectUUID
    });
  }

  deleteProject(projectUUID: string | null) {
    UTILS.assert(projectUUID);
    // @ts-ignore
    this.props.deleteProject(projectUUID as string);
    this.setState({
      anchorEl: null
    });
  }
}

export default connect(DashboardPage.mapStateToProps, DashboardPage.mapDispatchToProps)
// @ts-ignore
(withStyles(useStyles)(DashboardPage));

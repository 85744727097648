/** @module redux/reducers/loginFormReducer */

export interface UnlockSnippetFormValue {
  isOpen: boolean,
  unlockSnippetUUID: string,
  confirmed: boolean
}

const initFormValue: UnlockSnippetFormValue = {
  isOpen: false,
  unlockSnippetUUID: '',
  confirmed: false
};

/** @function
 * @memberof ReduxReducers
 * @name unlockSnippetFormReducer
 * @description Manage all state variables that are used in loginForm.
 * Handles the following actions:<br>
 * SET_LOGIN_FORM.<br>
 * CLEAR_LOGIN_FORM.
 */
const unlockSnippetFormReducer = (formValue = initFormValue,
                          action: any) => {
  switch (action.type) {
    case 'SET_UNLOCK_SNIPPET_FORM':
      return {...formValue, ...action.payload.formValue};
    case 'CLEAR_UNLOCK_SNIPPET_FORM':
      return initFormValue;
    default:
      return formValue;
  }
};

export default unlockSnippetFormReducer;
import React from "react";
import {Block} from "../../../../Blockly";
import ToolboxCategory from "../../../../common/ToolboxCategory";
import UTILS from "../../../../common/utils";
import HTMLFormsToolBox from "./forms/HTMLFormsToolBox";
import HTMLGroupingToolBox from "./grouping/HTMLGroupingToolBox";
import LayoutExamplesToolBox from "./LayoutExamplesToolBox";
import ShapesToolBox from "./ShapesToolBox";

class HTMLToolBox extends React.Component {
  render() {
    return <category name="HTML" colour={UTILS.getColorPalette().HTML}>
      <HTMLGroupingToolBox/>
      <HTMLFormsToolBox/>
      <category name="html" toolboxitemid={ToolboxCategory.HTML_HTML}></category>
      <category name="head" toolboxitemid={ToolboxCategory.HTML_HEAD}></category>
      <category name="img" toolboxitemid={ToolboxCategory.HTML_IMG}>
        <block type="html_img_implementation"></block>
      </category>
      <category name="link" toolboxitemid={ToolboxCategory.HTML_LINK}>
        <block type="html_link_implementation"></block>
      </category>
      <ShapesToolBox/>
      <Block type="html_br"/>
      <Block type="html_hr"/>
      <Block type="html_p"/>
      <category name="images">
        <block type="html_basic_image">
          <value name="src">
            <block type="text">
              <field name="TEXT">media/images/complicated.jpg</field>
            </block>
          </value>
        </block>
        <block type="html_expanded_image_implementation">
          <value name="src">
            <block type="text">
              <field name="TEXT">media/images/surfers.jpg</field>
            </block>
          </value>
        </block>
        <block type="text">
          <field name="TEXT">media/images/lizard.jpg</field>
        </block>
        <block type="text">
          <field name="TEXT">media/images/something.jpg</field>
        </block>
        <block type="text">
          <field
              name="TEXT">https://upload.wikimedia.org/wikipedia/commons/6/66/Bundesarchiv_Bild_102-00459%2C_Emanuel_Lasker.jpg
          </field>
        </block>
      </category>
      <category name="ordered/unordered lists">
        <block type="html_ul">
          <statement name="body">
            <block type="html_li">
              <statement name="body">
                <block type="string_block">
                  <field name="content">Something in the way she moves</field>
                </block>
              </statement>
              <next>
                <block type="html_li">
                  <statement name="body">
                    <block type="string_block">
                      <field name="content">Attracts me like no other lover</field>
                    </block>
                  </statement>
                  <next>
                    <block type="html_li">
                      <statement name="body">
                        <block type="string_block">
                          <field name="content">Something in the way she woos me</field>
                        </block>
                      </statement>
                    </block>
                  </next>
                </block>
              </next>
            </block>
          </statement>
        </block>
        <block type="html_ol">
          <statement name="body">
            <block type="html_li">
              <statement name="body">
                <block type="string_block">
                  <field name="content">Empty filling cabinet to reorganize it</field>
                </block>
              </statement>
              <next>
                <block type="html_li">
                  <statement name="body">
                    <block type="string_block">
                      <field name="content">Take bed spread off bed to wash it</field>
                    </block>
                  </statement>
                </block>
              </next>
            </block>
          </statement>
        </block>
      </category>
      <category name="layout">
        <LayoutExamplesToolBox/>
        <sep gap="64"></sep>
      </category>
    </category>
  }
}

export default HTMLToolBox;

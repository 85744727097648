import Snippet from "../../../common/Snippet";
import CONSTANTS from "../../../common/Constants";
import getSnippet from "../../../redux/logic/getSnippet";
import UTILS from "../../../common/utils";
import addSnippets, {SnippetTarget} from "../../../common/addSnippets";
import ToolboxCategory from "../../../common/ToolboxCategory";
import store from "../../../store";
import {
  addItemToToolbox,
  injectBlocks
} from "../../../redux/actions/persistentStateActions";
import SnippetTypes from "../../../common/SnippetTypes";
import {downloadSnippetChildrenOfRoot} from "../../../server_interface/downloadProjectSnippets";

const TBC = ToolboxCategory;

const sessionState = store.getState().sessionState;
const isProductionSession = sessionState.isProductionSession;

const MAP_SNIPPET_UUID_TO_TOOLBOX_CATEGORY = {
  ...{
    'DATA ARRAY_TEMPLATE': TBC.DATA_SNIPPETS,
    'DATA OBJECT_TEMPLATE': TBC.DATA_SNIPPETS,
    'EF2CDE10FF9E4A8B8D5C3A1980EE30C6': TBC.DATA_SNIPPETS,
    'DATA_BOOL_TEMPLATE': TBC.DATA_SNIPPETS,
    'DATA_NUMBER_TEMPLATE': TBC.DATA_SNIPPETS,
    'BOX_TEMPLATE': TBC.LAYOUT,
    'GRID_TEMPLATE': TBC.LAYOUT,
    'TABS_TEMPLATE': TBC.MUI_NAVIGATION,
    'LINK_TEMPLATE': TBC.MUI_NAVIGATION,
    'BUTTON_TEMPLATE': TBC.MUI_INPUTS,
    'CHECKBOX_TEMPLATE': TBC.MUI_INPUTS,
    'FAB_TEMPLATE': TBC.MUI_INPUTS,
    'RADIO_TEMPLATE': TBC.MUI_INPUTS,
    'SLIDER_TEMPLATE': TBC.MUI_INPUTS,
    'SWITCH_TEMPLATE': TBC.MUI_INPUTS,
    'TEXT_EDITOR_TEMPLATE': TBC.MUI_INPUTS,
    'TEXT_FIELD_TEMPLATE': TBC.MUI_INPUTS,
    'CARD_TEMPLATE': TBC.MUI_SURFACES,
    'PAPER_TEMPLATE': TBC.MUI_SURFACES,
    'IMAGE_TEMPLATE': TBC.MUI_DATA_DISPLAY,
    'TYPOGRAPHY_TEMPLATE': TBC.MUI_DATA_DISPLAY,
    'STYLED_TYPOGRAPHY_TEMPLATE': TBC.MUI_DATA_DISPLAY,
    'DATA_GRID_TEMPLATE': TBC.DATA_GRID,
    'ACTION_SNIPPET_TEMPLATE': TBC.ACTIONS,
    'ALERT_TEMPLATE': TBC.ACTIONS,
    'BACKUP_DATA_TEMPLATE': TBC.ACTIONS,
    'CLEAR_ARRAY_TEMPLATE': TBC.ACTIONS,
    'CREATE_ARRAY_OF_MAPS_TEMPLATE': TBC.ACTIONS,
    'FILTER_ARRAY_TEMPLATE': TBC.ACTIONS,
    'GENERATE_UUID_TEMPLATE': TBC.ACTIONS,
    'GMAIL_SEND_TEMPLATE': TBC.ACTIONS,
    'HTTP_GET_TEMPLATE': TBC.ACTIONS,
    'PARSE_CODEX_CSV_TEMPLATE': TBC.ACTIONS,
    'PUSH_TO_ARRAY_TEMPLATE': TBC.ACTIONS,
    'SET_VAR_TEMPLATE': TBC.ACTIONS,
  }, ...(isProductionSession ? {} : {'STYLE_SNIPPET_TEMPLATE': TBC.STYLE,})
};

export default async function initCore() {
  initBlocks();
  addTemplateSnippetsToGlobal();
  await addCoreSnippetsToGlobal();
  initToolbox();
}

async function addCoreSnippetsToGlobal() {
  const ROOT_COMMON = 'E0779610FAD346F9B10A51DF91714C8C';
  let orderedSnippets: Snippet[] =
      await downloadSnippetChildrenOfRoot(ROOT_COMMON) || [];
  orderedSnippets = orderedSnippets.filter((snippet) => {
    return snippet.snippetUUID !== ROOT_COMMON
  });
  orderedSnippets.reverse();
  // @ts-ignore
  const workspace = global.context.workspace;
  UTILS.assert(workspace);
  const targets = [SnippetTarget.REDUX, SnippetTarget.GLOBAL,
    SnippetTarget.BLOCKLY, SnippetTarget.TOOLBOX];
  addSnippets(orderedSnippets, true, true,
      targets, workspace, ToolboxCategory.STYLED_TEXT_SNIPPETS);
}

function addTemplateSnippetsToGlobal() {
  const snippetsArray: Array<Snippet> = [];
  CONSTANTS.TEMPLATE_SNIPPET_UUID.forEach(snippetUUID => {
    const snippet: Snippet = getSnippet(snippetUUID);
    snippetsArray.push(snippet);
  });
  addSnippets(snippetsArray, true, false,
      [SnippetTarget.GLOBAL], null, null);
}

function initBlocks() {
  store.dispatch(injectBlocks('VERTICAL_GROWING_BOX',
      SnippetTypes.REACT_COMPONENT, 'VERTICAL GROWING BOX'));
  CONSTANTS.TEMPLATE_SNIPPET_UUID.forEach(snippetUUID => {
    const snippet: Snippet = getSnippet(snippetUUID);
    UTILS.assert(snippet, snippetUUID);
    store.dispatch(injectBlocks(snippetUUID, snippet.type, snippet.name));
  });
}

function initToolbox() {
  CONSTANTS.TEMPLATE_SNIPPET_UUID.forEach(snippetUUID => {
    if (MAP_SNIPPET_UUID_TO_TOOLBOX_CATEGORY.hasOwnProperty(snippetUUID))
    // @ts-ignore
      store.dispatch(addItemToToolbox(snippetUUID, MAP_SNIPPET_UUID_TO_TOOLBOX_CATEGORY[snippetUUID]));
  });
  store.dispatch(addItemToToolbox('data_primitive_number', TBC.DATA_PRIMITIVES));
  store.dispatch(addItemToToolbox('data_primitive_number', TBC.DATA_PRIMITIVES));
  store.dispatch(addItemToToolbox('string_block', TBC.DATA_PRIMITIVES));
  store.dispatch(addItemToToolbox('data_primitive_true', TBC.DATA_PRIMITIVES));
  store.dispatch(addItemToToolbox('data_primitive_false', TBC.DATA_PRIMITIVES));
  store.dispatch(addItemToToolbox('data_primitive_undefined', TBC.DATA_PRIMITIVES));
  store.dispatch(addItemToToolbox('data_primitive_null', TBC.DATA_PRIMITIVES));
  store.dispatch(addItemToToolbox('data_key_value', TBC.DATA_SNIPPETS));
}

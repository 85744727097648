import UTILS from "../common/utils";
const { graphql } = require("@octokit/graphql");

export interface GithubCommitResult {
  success: boolean;
  msg: string;
}

export default async function commitToGithub(projectUUID: string): Promise<GithubCommitResult> {
  const content = read("../netlify/index.html", "text/html").split
    ("PROJECT_UUID").join(projectUUID);
  UTILS.assert(process.env);
  if (!process.env) {
    return { success: false, msg: 'Failed to commit to github. process.env not defined.' };
  }
  const GIT_ACCESS_TOKEN = process.env.REACT_APP_GIT_ACCESS_TOKEN;
  const graphqlWithAuth = graphql.defaults({
    headers: {
      authorization: `token ${GIT_ACCESS_TOKEN}`,
    },
  });
  try {
    const oid = await getHeadOid(graphqlWithAuth);
    await commit(graphqlWithAuth, oid, content);
  } catch (err) {
    return { success: false, msg: JSON.stringify(err) };
  }
  return { success: true, msg: 'Pushed successfully to git.' }
}

async function getHeadOid(graphqlWithAuth: any): Promise<string> {
  const { repository: { object: { oid } } } =
    // https://medium.com/@pyrolistical/destructuring-nested-objects-9dabdd01a3b8
    await graphqlWithAuth(`{
    repository(name: "hello_mail_iframe", owner: "frmoded") 
    {
      object(expression: "main") 
      {
        oid
      }
    }
  }`);
  return oid;
}

// @ts-ignore
async function commit(graphqlWithAuth: any, oid: string, content: string):
  Promise<void> {
  try {
    const res = await graphqlWithAuth(`
  mutation CreateCommitOnBranch {
    createCommitOnBranch(
      input: {
        branch: {
          repositoryNameWithOwner: "frmoded/hello_mail_iframe", 
          branchName: "main"
        },
        message: {
          headline: "some headline 24"
        },
        fileChanges: {
          additions: {
            path: "index.html",
            contents: "${btoa(content)}"
          }
        },
        expectedHeadOid: "${oid}"
      }){
      clientMutationId
    }}`);
  } catch (err: any) {
    console.log(err.message);
  }
}

function read(file: string, mimeType: string) {
  var request = new XMLHttpRequest();
  request.overrideMimeType(mimeType);
  request.open("GET", file, false);
  request.send(null);
  UTILS.assert(request.responseText !== null, file);
  UTILS.assert(request.status === 200, file);
  return request.responseText;
}

import * as Blockly from "blockly/core";
import extractSnippetUUIDFromCode from "../../extractSnippetUUIDFromCode";
import generateStyleCode from "../../generateStyleCode";

export function textFieldImplementation(block) {
  // TODO: switch to LocalState implementation;
  const STORE_STATE_IN_REDUX = true;
  return STORE_STATE_IN_REDUX ? textFieldImplementationWithRedux(block) :
      textFieldImplementationWithLocalState(block);
}

function textFieldImplementationWithRedux(block) {
  const variableCode = Blockly.JavaScript.statementToCode(block, 'variable');
  const textFieldSnippetUUID = extractSnippetUUIDFromCode(variableCode);
  let variant = Blockly.JavaScript.statementToCode(block, 'variant');
  const label = Blockly.JavaScript.statementToCode(block, 'label');
  const helperText = Blockly.JavaScript.statementToCode(block, 'helper');
  let code = "class TextFieldImplementation extends BaseComponent {";
  code += "render() {";
  code += "const self = this;";
  code += `let value = context.evaluateDataSnippet("${textFieldSnippetUUID}");`;
  code += generateStyleCode(block);
  code += ` const color = style.color || "#000";`;
  code += `const StyledTextField = withStyles({root: style})(TextField);`;
  code += `return <StyledTextField inputProps={{ style: { color } }} InputLabelProps={{ style: { color } }} label={context.evaluateFJSONExpressionWithRedux(${label})}`;
  code += `helperText={context.evaluateFJSONExpressionWithRedux(${helperText})} value={value} `;
  code += `${variant}  key="${textFieldSnippetUUID}"`;
  code += `autoFocus={self.getFocusedComponent() === "${textFieldSnippetUUID}"}`;
  code += `onFocus={() => {`;
  code += `if (self.getFocusedComponent() !== "${textFieldSnippetUUID}") {`;
  code += `self.setFocusedComponent("${textFieldSnippetUUID}");}}}`;
  code += `onChange={(e) => {`;
  code += `return context.setDataSnippet("${textFieldSnippetUUID}", e.target.value);`;
  code += `}}`;
  code += `/>`;
  code += `}}`;
  return code;
}

function textFieldImplementationWithLocalState(block) {
  const variableCode = Blockly.JavaScript.statementToCode(block, 'variable');
  const textFieldSnippetUUID = extractSnippetUUIDFromCode(variableCode);
  let variant = Blockly.JavaScript.statementToCode(block, 'variant');
  const label = Blockly.JavaScript.statementToCode(block, 'label');
  const helperText = Blockly.JavaScript.statementToCode(block, 'helper');
  const styleCode = Blockly.JavaScript.statementToCode(block, 'style');
  let code = `class TextFieldImplementation extends BaseComponent {`;
  code += constructorCode(textFieldSnippetUUID);
  code += updateDataSnippetCode(textFieldSnippetUUID);
  code += `render(props) {`;
  code += `const self = this;`;
  code += `const StyledTextField = withStyles({root: {${styleCode}},})(TextField);`;
  code += `return <StyledTextField label={context.evaluateFJSONExpressionWithRedux(${label})}`;
  code += ` helperText={context.evaluateFJSONExpressionWithRedux(${helperText})}`;
  code += ` value={this.state.textFieldValue}`;
  code += ` autoFocus={this.state.doesTextFieldHaveFocus}`;
  code += ` ${variant} `;
  code += ` key="${textFieldSnippetUUID}"`;
  code += onChangeCode();
  code += onFocusCode(textFieldSnippetUUID);
  code += onBlurCode();
  code += onKeyDownCode();
  code += `/>`;
  code += `}}`;
  return code;
}

function constructorCode(textFieldSnippetUUID) {
  let code = ` constructor(props) {`;
  code += ` super(props);`;
  if (textFieldSnippetUUID)
    code += ` const dataSnippet = context.getSnippet("${textFieldSnippetUUID}");`;
  code +=
      ` const textFieldValue = dataSnippet ? JSON.parse(context.evaluateFJSONExpressionWithRedux(dataSnippet.content)) : "";`;
  code += ` this.state = {`;
  code += ` doesTextFieldHaveFocus: false,`;
  code += ` textFieldValue`;
  code += ` };`;
  code += ` this.updateDataSnippet = this.updateDataSnippet.bind(this);`;
  code += ` }`;
  return code;
}

function onFocusCode(textFieldSnippetUUID) {
  let code = ` onFocus={(e) => {`;
  code += ` if (!self.state.doesTextFieldHaveFocus) {`;
  code += ` self.setState({doesTextFieldHaveFocus: true});`;
  code += ` const dataSnippet = context.getSnippet("${textFieldSnippetUUID}");`;
  code += ` self.setState({textFieldValue: JSON.parse(dataSnippet.content)});`;
  code += ` }`;
  code += ` }}`;
  return code;
}

function onBlurCode() {
  let code = ` onBlur={(e) => {`;
  code += ` if (self.state.doesTextFieldHaveFocus) {`;
  code += `  self.updateDataSnippet();`;
  code += ` }`;
  code += ` }}`;
  return code;
}

function onKeyDownCode() {
  let code = ` onKeyDown={(e) => {`;
  code += ` if (e.keyCode === 13) {`;
  code += ` self.updateDataSnippet();`;
  code += ` }}}`;
  return code;
}

function updateDataSnippetCode(textFieldSnippetUUID) {
  let code = ` updateDataSnippet()`;
  code += ` {`;
  code += ` this.setState({doesTextFieldHaveFocus: false});`;
  code += ` context.setDataSnippet("${textFieldSnippetUUID}", this.state.textFieldValue);`;
  code += ` }`;
  return code;
}

function onChangeCode() {
  let code = ` onChange={(e) => {`;
  code += ` self.setState(`;
  code += ` {textFieldValue: e.target.value});`;
  code += ` }}`;
  return code;
}

import { PersistentStateAction } from "../actions/persistentStateActions";
import Project from "../../common/Project";
import UTILS from "../../common/utils";

const _ = require('lodash');

/** @module redux/reducers/persistentStateReducerModule */

export interface PersistentState {
  toolbox: object,
  allSnippets: object,
  publicProjects: object,
  userProjects: object,
}

const initPersistentState: PersistentState = {
  toolbox: {},
  allSnippets: {},
  publicProjects: {},
  userProjects: {},
};

/** @function
 * @memberof ReduxReducers
 * @name persistentStateReducer
 * @param {PersistentState} persistentState
 * @param {ReduxAction} action
 * @description
 * Manage the state variables describing the Arena Persistent state.
 */
const persistentStateReducer = (
  persistentState: PersistentState = initPersistentState, action:
    PersistentStateAction | any) => {
  UTILS.assert(persistentState.userProjects);
  UTILS.assert(persistentState.publicProjects);
  switch (action.type) {
    case 'INJECT_BLOCKS_FOR_SNIPPET':
    case 'INJECT_BLOCKS_AND_CREATE_SNIPPET':
      return persistentState;
    case 'ADD_ITEM_TO_TOOLBOX': {
      const toolboxCategory = action.payload.toolboxCategory;
      const type = action.payload.type;
      let revisedToolbox = _.cloneDeep(persistentState.toolbox);
      if (Object.keys(revisedToolbox).indexOf(toolboxCategory) === -1)
        revisedToolbox[toolboxCategory] = [];
      const itemExists = revisedToolbox[toolboxCategory].some(
        (e: { type: string; kind: string; }) =>
          (e.type === type) && (e.kind === 'block'));
      if (!itemExists)
        revisedToolbox[toolboxCategory].push({ kind: 'block', type });
      return {
        ...persistentState,
        ...{
          toolbox: revisedToolbox
        }
      };
    }
    case 'SET_IS_LOGGED_IN': {
      const res = action.payload.isLoggedIn ? persistentState :
        {
          ...persistentState, ...{
            userProjects: {},
          }
        }
      return res;
    }
    case 'ADD_BUTTON_TO_TOOLBOX': {
      const toolboxCategory = action.payload.toolboxCategory;
      const text = action.payload.text;
      const callbackKey = action.payload.callbackKey;
      let revisedToolbox = _.cloneDeep(persistentState.toolbox);
      if (Object.keys(revisedToolbox).indexOf(action.payload.toolboxCategory) === -1)
        revisedToolbox[toolboxCategory] = [];
      const itemExists = revisedToolbox[toolboxCategory].some(
        (e: { callbackKey: any; kind: string; text: string; }) =>
          (e.callbackKey === callbackKey) && (e.kind === 'button') &&
          (e.text === text));
      if (!itemExists)
        revisedToolbox[toolboxCategory].push({
          kind: 'button',
          text,
          callbackKey
        });
      return {
        ...persistentState,
        ...{
          toolbox: revisedToolbox
        }
      };
    }
    case 'UPSERT_SNIPPET':
      return upsertSnippet(persistentState, action);
    case 'UPSERT_SNIPPETS_ARRAY':
      return upsertSnippetsArray(persistentState, action);
    case 'UPSERT_PROJECT': {
      const project: Project = action.payload.project;
      if (project.isPublic) {
        let revisedPublicProjects = _.cloneDeep(persistentState.publicProjects);
        revisedPublicProjects[project.projectUUID] = _.cloneDeep(project);
        let res = {
          ...persistentState,
          ...{
            publicProjects: revisedPublicProjects
          }};
          return res;
        } else {
        let revisedUserProjects = _.cloneDeep(persistentState.userProjects);
        revisedUserProjects[project.projectUUID] = _.cloneDeep(project);
        let res = {
          ...persistentState,
          ...{
            userProjects: revisedUserProjects
          }
        };
        return res;
      }
    } 
    case 'DELETE_PROJECT': {
      let revisedUserProjects = _.cloneDeep(persistentState.userProjects);
      delete revisedUserProjects[action.payload.projectUUID];
      return {
        ...persistentState, ...{ userProjects: revisedUserProjects }
      };
    }
    default:
      return persistentState;
  }
};

/** @function
 * @memberof ReduxReducers
 * @name upsertSnippet
 * @param {PersistentState} persistentState
 * @param {ReduxAction} action action describes the block
 * @description
 * Store the snippet within the redux state.
 */
function upsertSnippet(persistentState: PersistentState, action: PersistentStateAction) {
  const snippet = action.payload.snippet;
  let revisedAllSnippets = _.cloneDeep(persistentState.allSnippets);
  revisedAllSnippets[snippet.snippetUUID] = _.cloneDeep(snippet);
  return {
    ...persistentState, ...{ allSnippets: revisedAllSnippets }
  };
}

function upsertSnippetsArray(persistentState: PersistentState, action: PersistentStateAction) {
  const snippetsArray = action.payload.snippetsArray;
  let revisedAllSnippets = _.cloneDeep(persistentState.allSnippets);
  snippetsArray.forEach(snippet => {
    revisedAllSnippets[snippet.snippetUUID] = _.cloneDeep(snippet);
  });
  return {
    ...persistentState, ...{ allSnippets: revisedAllSnippets }
  };

}

export default persistentStateReducer;

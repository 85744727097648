export interface SessionState {
  currentProjectUUID: string | null,
  isDeployedSession: boolean,  // Allow editing if false.
  isDeveloper: boolean,
  isLoggedIn: boolean,
  isProductionSession: boolean,
  userUUID: string | null,
  wasProjectInitialized: boolean,
  isGitSession: boolean,
  isNetlifySession: boolean,
}

const initSessionState: SessionState = {
  currentProjectUUID: null,
  isDeployedSession: false,
  isDeveloper: false,
  isLoggedIn: false,
  isProductionSession: true,
  userUUID: null,
  wasProjectInitialized: false,
  isGitSession: false,
  isNetlifySession: false,
};

// @ts-ignore
const sessionStateReducer = (sessionState: SessionState = initSessionState, action):
  SessionState => {
  switch (action.type) {
    case 'LOG_OUT':
      return {
        ...sessionState, ...{
          isDeveloper: false,
        }
      };
    case 'SET_IS_DEVELOPER':
      return {
        ...sessionState, ...{
          isDeveloper: action.payload.isDeveloper,
          isProductionSession: !action.payload.isDeveloper
        }
      };
    case 'SET_USER_UUID':
      return {
        ...sessionState, ...{
          userUUID: action.payload.userUUID,
        }
      };
    case 'SET_IS_DEPLOYED_SESSION':
      return {
        ...sessionState, ...{
          isDeployedSession: action.payload.isDeployedSession,
        }
      };
    case 'SET_IS_GIT_SESSION':
      return {
        ...sessionState, ...{
          isGitSession: action.payload.isGitSession,
        }
      };
    case 'SET_IS_NETLIFY_SESSION':
      return {
        ...sessionState, ...{
          isNetlifySession: action.payload.isNetlifySession,
        }
      };
    case 'SET_WAS_PROJECT_INITIALIZED':
      return {
        ...sessionState, ...{
          wasProjectInitialized: action.payload.wasProjectInitialized
        }
      };
    case 'SET_IS_LOGGED_IN':
      return {
        ...sessionState, ...{
          isLoggedIn: action.payload.isLoggedIn
        }
      };
    case 'SET_CURRENT_PROJECT':
      return { ...sessionState, ...{ currentProjectUUID: action.payload.currentProjectUUID } };
    default:
      return sessionState;
  }
};

export default sessionStateReducer;

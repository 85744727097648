import UTILS from "../common/utils";

const axios = require('axios').default;

export default class BackendActions {
    static async doAction() {
    const SERVER_URL = UTILS.getServerAPIURL();
    return axios.post(SERVER_URL + '/actions',
        '', {withCredentials: true});
  }
}
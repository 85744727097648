import * as Blockly from "blockly/core";
import generateStyleCode from "../../generateStyleCode";

/** @function
 * @name boxImplementationGenerator
 * @returns Generate code for the mui_box_implementation block.
 */
export function boxImplementationGenerator(block) {
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  const properties = Blockly.JavaScript.statementToCode(block, 'properties');
  let code = `class MUIBox extends BaseComponent {`;
  code += `Render() {`;
  code += ` const self = this;`;
  code += ` this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';`;
  code += generateStyleCode(block);
  code += ` const StyledBox = withStyles({root: style})(Box);`;
  code += ` return <StyledBox ref={self.componentReference} display="flex" ${properties}>`;
  code += `${content} </StyledBox>}}`;
  return code;
}

/** @function
 * @name rootBoxImplementationGenerator
 * @returns Generate code for the mui_root_box_implementation block.
 */
export function rootBoxImplementationGenerator(block) {
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  const properties = Blockly.JavaScript.statementToCode(block, 'properties');
  let currentBlock = this.getInputTargetBlock('init_actions');
  let actionCode = ``;
  while (currentBlock) {
    actionCode += `${Blockly.JavaScript[currentBlock.type].call(currentBlock, currentBlock)};`;
    currentBlock = currentBlock.getNextBlock();
  }
  let code = `class MUIBoxWithAction extends BaseComponent {`;
  code += `componentDidMount() {`;
  code += `if (!context.wasProjectInitialized()) {`;
  code += `context.setWasProjectInitialized(true);`;
  code += `${actionCode}; }`;
  code += `}`;
  code += `Render() {`;
  code += ` const self = this;`;
  code += ` this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';`;
  code += generateStyleCode(block);
  code += ` const StyledBox = withStyles({root: style})(Box);`;
  code += ` return <StyledBox ref={self.componentReference} display="flex" ${properties}>`;
  code += `${content} </StyledBox>}}`;
  return code;
}

import React from "react";

export default class PositioningToolBox extends React.Component {
  render() {
    return <category name="Position">
      <block type="style_display"/>
      <block type="style_position"/>
      <block type="style_position_value">
        <field name="position">top</field>
        <field name="units">%</field>
        <statement name="value">
          <block type="data_primitive_number">
            <field name="value">4</field>
          </block>
        </statement>
      </block>
    </category>
  }
  }
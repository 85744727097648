import React from "react";

export default class AnimationsToolBox extends React.Component {
  render() {
    return <category name="Animation">
      <block type="style_spin_with_direction">
        <field name="direction">CLOCKWISE</field>
        <statement name="value">
          <block type="data_primitive_number">
            <field name="value">8</field>
          </block>
        </statement>
      </block>
      <block type="style_flicker_animation">
        <statement name="value">
          <block type="data_primitive_number">
            <field name="value">8</field>
          </block>
        </statement>
      </block>
    </category>
  }
}
/** @module redux/reducers/modifyProjectFormReducer */

export interface ModifyProjectFormValue {
  imageURL: string,
  isOpen: boolean,
  isPublic: boolean,
  name: string,
  projectUUID: string

}

const initModifyProjectFormValue: ModifyProjectFormValue = {
  imageURL: "",
  isOpen: false,
  isPublic: false,
  name: "",
  projectUUID: ""
};

/** @function
 * @memberof ReduxReducers
 * @name modifyProjectFormReducer
 * @description Manage all state variables that are used in modifyProjectForm.
 * Handles the following actions:<br>
 * SET_MODIFY_PROJECT_FORM.<br>
 * CLEAR_MODIFY_PROJECT_FORM.
 */
const modifyProjectFormReducer = (
    modifyProjectForm: ModifyProjectFormValue = initModifyProjectFormValue,
    action: any) => {
  switch (action.type) {
    case 'SET_MODIFY_PROJECT_FORM':
      return {...modifyProjectForm, ...action.payload.modifyProjectForm};
    case 'CLEAR_MODIFY_PROJECT_FORM':
      return initModifyProjectFormValue;
    default:
      return modifyProjectForm;
  }
};

export default modifyProjectFormReducer;

import addSnippets, {SnippetTarget} from "../../../../../common/addSnippets";
import Snippet from "../../../../../common/Snippet";
import deepCloneSnippetAndAddToRedux
  from "../../../../../redux/logic/deepCloneSnippetAndAddToRedux"
import UTILS from "../../../../../common/utils";

export default async function deepClone(rootBlock: any, workspace: any)
    : Promise<Array<string>> {
  const clonedSnippets: Array<Snippet> =
      await deepCloneSnippetAndAddToRedux(rootBlock.type);
  // Add (just) the copy of root block to workspace.
  // It is promised to be the last element in the clonedSnippets array.
  const res = await addSnippets(
      [clonedSnippets[clonedSnippets.length - 1]],
      true, true,
      [SnippetTarget.WORKSPACE], workspace, null);
  UTILS.assert(res.length === 1);
  return res;
}

import React from "react";

import {connect} from "react-redux";
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import Slide from "@material-ui/core/Slide/Slide";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";
import {withStyles} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert/Alert";

import {setAlert,} from "../redux/actions/actions";

/**
 * Component responsible for displaying an alert with state stored in redux.
 * @extends React.Component
 */
class ArenaAlert extends React.Component {

  static mapStateToProps(reduxState) {
    let alertDurationMsc = reduxState.arenaAlertOptions.alertDurationMsc;
    alertDurationMsc = alertDurationMsc >= 0 ? alertDurationMsc : 60*60*1000;
    return {
      alertContent: reduxState.arenaAlertOptions.alertContent,
      alertDurationMsc,
      alertSeverity: reduxState.arenaAlertOptions.alertSeverity,
      alertTitle: reduxState.arenaAlertOptions.alertTitle
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      setAlert: (arenaAlertOptions) => {
        dispatch(setAlert(arenaAlertOptions));
      }
    }
  }

  /** This is a description of the render function. */
  render() {
    const StyledAlert = withStyles({
      root: {
        width: "50vw",
        zIndex: 1,
      },
      filledSuccess: {
        backgroundColor: "#038541",
      },
      filledInfo: {
        backgroundColor: "#1570D6",
      },
      filledWarning: {
        backgroundColor: "#FFD423",
      },
      filledError: {
        backgroundColor: "#EC4E62",
      },
    })(Alert);
    const self = this;
    return <Snackbar open={this.props.alertContent.length > 0}
                     TransitionComponent={Slide}
                     autoHideDuration={this.props.alertDurationMsc}
                     onClose={(event, reason) => {
                       if (reason !== 'clickaway') {
                         self.props.setAlert({alertContent: ""});
                       }
                     }}>
      <StyledAlert variant="filled" onClose={() => {
        self.props.setAlert({alertContent: "",});
      }} severity={this.props.alertSeverity}>
        <AlertTitle>{this.props.alertTitle}</AlertTitle>
        {this.props.alertContent}
      </StyledAlert>
    </Snackbar>
  }
}

export default connect(ArenaAlert.mapStateToProps, ArenaAlert.mapDispatchToProps)(ArenaAlert);
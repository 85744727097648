import * as Blockly from "blockly/core";
import extractSnippetUUIDFromCode from "../extractSnippetUUIDFromCode";

/** @function
 * @name generateUuidImplementationGenerator
 * @description Generator for the generate_uuid_implementation block.
 * @returns string
 */
export function generateUuidImplementationGenerator(block) {
  const variableCode = Blockly.JavaScript.statementToCode(block, 'variable');
  const snippetUUID = extractSnippetUUIDFromCode(variableCode);
  let code = `() => {`;
  if (snippetUUID) {
    code += `context.setDataSnippet("${snippetUUID}", context.generateUUID());`;
  }
  code += `}`;
  return code
}

/** @function
 * @name codexParseImplementation
 * @description Generator for the codex_parse_implementation block.
 * @returns string
 */
export function codexParseImplementation(block) {
  const columnsSnippetCode = Blockly.JavaScript.statementToCode(block, 'columns');
  const columnsSnippetUUID = extractSnippetUUIDFromCode(columnsSnippetCode);
  const rowsSnippetCode = Blockly.JavaScript.statementToCode(block, 'rows');
  const rowsSnippetUUID = extractSnippetUUIDFromCode(rowsSnippetCode);
  const csvFile = Blockly.JavaScript.statementToCode(block, 'csv_file');
  let action = Blockly.JavaScript.statementToCode(block, 'action');
  let code = `() => {`;
  if (csvFile) {
    code += `fetch(${csvFile})`;
    code += `.then(res => res.clone().text())`;
    code += `.then(text => {`;
    code += `let lines =  text.replaceAll('\\r', '').replaceAll('root_id','id').split("\\n");`;
    // JSON.parse(str.replaceAll(';',',').replaceAll("'", '"'))
    code += `context.setDataSnippet("${columnsSnippetUUID}",lines[0].split(","));`;
    code += `lines = lines.slice(1);`;
    code += `lines = lines.map( e => e.split(","));`;
    code += `context.setDataSnippet("${rowsSnippetUUID}", lines);`;
    code += `})`;
    code += `.then((res) => {`;
    code += action;
    code += `});`;
  }
  code += `}`;
  return code;
}

/** @function
 * @name createArrayOfMapsImplementation
 * @description Generator for the create_array_of_maps_implementation block.
 * @returns string
 */
export function createArrayOfMapsImplementation(block) {
  const keys = Blockly.JavaScript.statementToCode(block, 'keys');
  const rows = Blockly.JavaScript.statementToCode(block, 'rows');
  const resultSnippetCode = Blockly.JavaScript.statementToCode(block, 'result');
  const resultSnippetUUID = extractSnippetUUIDFromCode(resultSnippetCode);
  let code = `() => {`;
  code += `const keys = context.evaluateFJSONExpressionWithRedux(${keys});`;
  code += `const rows = context.evaluateFJSONExpressionWithRedux(${rows});`;
  code += `const merged = [];`;
  code += `rows.forEach((row)=> {`;
  code += `var result = {};`;
  code += `keys.forEach((key, i) => result[key] = row[i]);`;
  code += `merged.push(result);`;
  code += `});`;
  code += `context.setDataSnippet("${resultSnippetUUID}", merged);`;
  code += `}`;
  return code;
}

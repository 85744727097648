/** @module redux/reducers/loginFormReducer */

export interface AboutFormValue {
  isOpen: boolean,
}

const initAboutFormValue: AboutFormValue = {
  isOpen: false,
};

/** @function
 * @memberof ReduxReducers
 * @name aboutFormReducer
 * @description Manage all state variables that are used in aboutForm.
 * Handles the following action:<br>
 * SET_ABOUT_FORM.<br>
 */
// @ts-ignore
const aboutFormReducer = (createAboutForm : AboutFormValue = initAboutFormValue, action) => {
  switch (action.type) {
    case 'SET_ABOUT_FORM':
      return {...createAboutForm, ...action.payload.createAboutForm};
    default:
      return createAboutForm;
  }
};

// @ts-ignore
export default aboutFormReducer;

/** @module redux/reducers/loginFormReducer */

interface LoginFormValue {
  isOpen: boolean,
}

const initLoginFormValue: LoginFormValue = {
  isOpen: false,
};

/** @function
 * @memberof ReduxReducers
 * @name loginFormReducer
 * @description Manage all state variables that are used in loginForm.
 * Handles the following actions:<br>
 * SET_LOGIN_FORM.<br>
 * CLEAR_LOGIN_FORM.
 */
const loginFormReducer = (createLoginForm = initLoginFormValue,
                          action: any) => {
  switch (action.type) {
    case 'SET_LOGIN_FORM':
      return {...createLoginForm, ...action.payload.createLoginForm};
    case 'CLEAR_LOGIN_FORM':
      return initLoginFormValue;
    default:
      return createLoginForm;
  }
};

export default loginFormReducer;

import * as Blockly from "blockly/core";

/** @function
 * @name setPadding
 * @returns a string that once evaluated will hold the padding described by this block.
 */
export function setPadding(block) {
  let value = Blockly.JavaScript.statementToCode(block, 'value');
  if (!value)
    return '';
  return `{padding: (context.evaluateFJSONExpressionWithRedux("${value}") + "${block.getFieldValue(
      'units')}")}`;
}

/** @function
 * @name setPaddingDetailed
 * @returns a string that once evaluated will hold the padding described by this block.
 * The underlying block describes equal padding on all 4 sides.
 */
export function setPaddingDetailed(block) {
  let top = Blockly.JavaScript.statementToCode(block, 'top') || "0";
  let left = Blockly.JavaScript.statementToCode(block, 'left') || "0";
  let bottom = Blockly.JavaScript.statementToCode(block, 'bottom') || "0";
  let right = Blockly.JavaScript.statementToCode(block, 'right') || "0";
  return `{paddingTop: (context.evaluateFJSONExpressionWithRedux("${top}") + "${block.getFieldValue(
      'units')}"),` +
      `paddingLeft: (context.evaluateFJSONExpressionWithRedux("${left}") + "${block.getFieldValue(
          'units')}"),` +
      `paddingBottom: (context.evaluateFJSONExpressionWithRedux("${bottom}") + "${block.getFieldValue(
          'units')}"),` +
      `paddingRight: (context.evaluateFJSONExpressionWithRedux("${right}") + "${block.getFieldValue(
          'units')}")}`;
}

import React, {Component} from 'react';
import {connect} from "react-redux";
import {pushSnippetUUIDToStack} from "../redux/actions/workAreaPageStateActions";
import UTILS from "../common/utils";

export class Masker extends Component {

  static mapStateToProps(reduxState) {

    const maskerState = reduxState.maskerState;
    return {
      displayMasks: maskerState.displayMasks,
      allMasks: maskerState.allMasks,
      highlightComponentSnippetUuid: maskerState.highlightComponentSnippetUuid,
    }
  }

  static mapDispatchToProps(dispatch) {
    return {
      pushSnippetUUIDToStack: (snippetUUID) => {
        dispatch(pushSnippetUUIDToStack(snippetUUID))
      },
    }
  }

  render() {
    let buffer = [];
    let self = this;
    if (this.props.displayMasks && this.props.highlightComponentSnippetUuid !== null) {
      Object.entries(this.props.allMasks).forEach(([key, maskState]) => {
        const onClick = (() => {
          self.props.pushSnippetUUIDToStack(key);
        });
        buffer.push(buildMask(maskState.boundingBox, maskState.depth + 10, key,
            this.props.highlightComponentSnippetUuid, onClick));
      });
    }
    return <div style={{position: "fixed", zIndex: 10}}>{buffer}</div>
  }
}

function buildMask(boundingBox, depth, snippetUUID, highlightComponentSnippetUuid, onClick) {
  const COLOR = "rgba(220, 220, 220, 0.1)";
  const HIGHLIGHTED_COLOR = "rgba(220, 220, 220, 0.4)";
  const color = snippetUUID === highlightComponentSnippetUuid ? HIGHLIGHTED_COLOR : COLOR;
  const BORDER = "2px solid #888";
  const HIGHLIGHTED_BORDER = "4px solid #FFCD32";
  const border = snippetUUID === highlightComponentSnippetUuid ? HIGHLIGHTED_BORDER : BORDER;
  return <div style={buildMaskStyle(boundingBox, depth, color, border)} onClick={onClick}></div>;
}

function buildMaskStyle(boundingBox, depth, color, border) {
  UTILS.assert(boundingBox);
  let style = {
    position: "fixed",
    backgroundColor: color,
    color: color,
    top: boundingBox.top + "px",
    height: boundingBox.height + "px",
    left: boundingBox.left + "px",
    width: boundingBox.width + "px",
    zIndex: depth,
  };
  if (border) {
    style.border = border;
    style.boxSizing = "border-box";
  }
  return style;
}

export default connect(Masker.mapStateToProps, Masker.mapDispatchToProps)(Masker);

import {
  createJSONFunctionImplementationFromFunction,
  evaluateJSONFunction,
  JSONFunctionImplementation
} from "./data_functions/DataFunction";
import Snippet from "../../common/Snippet";
import UTILS from "../../common/utils";
import SnippetTypes from "../../common/SnippetTypes";

/** @function
 * @name evaluateSnippetImplementation
 * @param {storeInstance} storeInstance
 * @description
 *
 * Returns a JSONFunctionImplementation for evalSnippet function.
 * If the snippet is a Data snippet it holds a combination of data items and
 * uuid's of snippets that are, in turn, recursively evaluated.
 *
 * Note:
 * 1 .evalSnippetImplementation assumes that the snippet does exist.
 * 2. evalSnippetImplementation returns undefined if snippet does not exist.
 */
function evaluateSnippetImplementation(storeInstance: any): JSONFunctionImplementation {
  const evalSnippetImplementation: JSONFunctionImplementation =
      createJSONFunctionImplementationFromFunction('evalSnippet',
          (snippetUUID: string) => {
            const snippet: Snippet =
                storeInstance.getState().persistentState.allSnippets[snippetUUID];
            UTILS.assert(snippet.type === SnippetTypes.DATA);
            if (!snippet) {
              return undefined;
            }
            return evaluateJSONFunction(JSON.parse(snippet.content),
                [evalSnippetImplementation]);
          });
  return evalSnippetImplementation;
}

export default evaluateSnippetImplementation;

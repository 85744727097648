import React from 'react';
// @ts-ignore
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {connect} from "react-redux";
import {AppContext} from "../../../../api/ArenaBlockAPI";
import CONSTANTS from "../../../../common/Constants";
import {clearAllMasks} from "../../../../redux/actions/maskerActions";
import UncontrolledBlocklyEditor
// @ts-ignore
  from "./blockly_editor/UncontrolledBlocklyEditor.tsx";
// @ts-ignore
import SimpleWSYIWYGEditor from "./SimpleWSYIWYGEditor.tsx";
import Snippet from '../../../../common/Snippet';
import getCurrentSnippet from "../../../../redux/logic/getCurrentSnippet";

class SnippetEditor extends React.Component {
  static contextType = AppContext;
  private blocklyEditor: React.RefObject<unknown>;

  constructor(props: any) {
    super(props);
    this.blocklyEditor = React.createRef();
  }

  static mapStateToProps(reduxState: any) {
    const workAreaPageState = reduxState.workAreaPageState;
    return {
      WYSIWYGIsContentContent: workAreaPageState.WYSIWYGIsContentContent,
      WYSIWYGEditorContentState: workAreaPageState.WYSIWYGEditorContentState,
      isSnippetEditorOpen: workAreaPageState.isSnippetEditorOpen,
      WYSIWYGEditorOpen: workAreaPageState.WYSIWYGEditorOpen,
      isDrawerOpen: workAreaPageState.isDrawerOpen,
      currentSnippet: getCurrentSnippet()
    }
  }

  static mapDispatchToProps(dispatch: any) {
    return {
      clearAllMasks: (snippet: Snippet) => {
        dispatch(clearAllMasks());
      },
    }
  }

  render() {
    const snippetEditorStyle = {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      maxHeight: '100%',
      overflow: 'auto',
    };
    const props: any = this.props;
    // @ts-ignore
    return <div style={snippetEditorStyle}>
      <UncontrolledBlocklyEditor
          ref={this.blocklyEditor}
          xml={props.currentSnippet ? props.currentSnippet.xml : undefined}/>
      <SimpleWSYIWYGEditor/>
    </div>
  }

  makeStyle() {
    const props: any = this.props;
    return {
      width: props.isSnippetEditorOpen ? CONSTANTS.SNIPPET_EDITOR_WIDTH_PERCENT + '%' : '0%',
      height: '100%',
      position: 'absolute',
      visibility: props.isSnippetEditorOpen ? 'visible' : 'hidden',
      display: 'flex',
      flexDirection: 'column',
    };
  }
}

export default connect(SnippetEditor.mapStateToProps, SnippetEditor.mapDispatchToProps)
(SnippetEditor);

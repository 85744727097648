import React from "react";

export default class ChartsToolBox extends React.Component {
  render() {
    return <category name="Charts">
      <category name="Line Chart">
        <block type="recharts_line_chart_inline"/>
        <block type="recharts_chart_width">
          <statement name="properties">
            <block type="data_primitive_number">
              <field name="value">500</field>
            </block>
          </statement>
        </block>
        <block type="recharts_chart_data">
        </block>
        <block type="recharts_chart_height">
          <statement name="properties">
            <block type="data_primitive_number">
              <field name="value">500</field>
            </block>
          </statement>
        </block>
        <block type="recharts_xaxis"/>
        <block type="recharts_yaxis"/>
        <category name="Line">
          <block type="recharts_line"/>
          <block type="recharts_line_stroke">
            <value name="value">
              <block type="deprecated_color_picker">
                <field name="field_colour">#ffd423</field>
              </block>
            </value>
          </block>
          <block type="recharts_line_type"/>
          <block type="recharts_dataKey"/>
        </category>
      </category>
      <category name="Area Chart">
        <block type="recharts_area_chart_inline"/>
      </category>
      <category name="Bar Chart">
        <block type="recharts_bar_chart_inline"/>
      </category>
      <category name="Pie Chart">
        <block type="recharts_pie_chart_inline"/>
      </category>
    </category>;
  }
}
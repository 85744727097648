/** @module redux/reducers/createVariableFormReducer */

interface CreateVariableFormValue {
  isOpen: boolean,
  varName: string,
  addToToolbox: boolean,
  addToWorkspace: boolean,
}

const initCreateVariableFormValue: CreateVariableFormValue = {
  isOpen: false,
  varName: '',
  addToToolbox: true,
  addToWorkspace: false,
};

/** @function
 * @memberof ReduxReducers
 * @name createVariableFormReducer
 * @description Manage all state variables that are used in createVariableForm.
 * Handles the following actions:<br>
 * SET_CREATE_ACTION_BLOCK_FORM.<br>
 * SET_CREATE_VARIABLE_FORM_IS_OPEN.<br>
 * CLEAR_CREATE_VARIABLE_FORM.<br>
 */

const createVariableFormReducer = (createVariableForm = initCreateVariableFormValue,
                                   action: any) => {
  switch (action.type) {
    case 'SET_CREATE_VARIABLE_FORM':
      return {...createVariableForm, ...action.payload.createVariableForm};
    case 'SET_CREATE_VARIABLE_FORM_IS_OPEN':
      return {...createVariableForm, ...{isOpen: action.payload.isOpen}};
    case 'CLEAR_CREATE_VARIABLE_FORM':
      return initCreateVariableFormValue;
    default:
      return createVariableForm;
  }
};

export default createVariableFormReducer;

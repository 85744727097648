import * as Blockly from 'blockly/core';
import BlocklyJS from 'blockly/javascript';
// @ts-ignore
import CONSTANTS from "./Constants";
import getSnippet from "../redux/logic/getSnippet";
import SnippetTypes from "./SnippetTypes";

const standalone = require("@babel/standalone");
const babelTransformReactPlugin = require("@babel/plugin-transform-react-jsx");

require('dotenv').config();
standalone.registerPlugin('trasnformReactPlugin', babelTransformReactPlugin);
standalone.registerPreset('@babel/preset-typescript', require("@babel/preset-typescript"));

/** @module UTILS */

const {v4: uuidv4} = require('uuid');

const UTILS = {

  evaluateJsx(code) {
    // Deprecated.
    UTILS.assert(false);
    code = standalone.transform(code, {
      plugins: [['trasnformReactPlugin', {"throwIfNamespace": false,}]]
    }).code;
    code.replace(/(\r\n|\n|\r)/gm, " ");  // remove white space
    return code;
  },

  evaluateTSX(code) {
    code = standalone.transform(code, {
      filename: 'file.tsx',
      "presets": [
        ["@babel/preset-typescript", {
          "isTSX": true,
          "allExtensions": true
        }],
      ],
      plugins: [['trasnformReactPlugin', {"throwIfNamespace": false,}]]
    }).code;
    code.replace(/(\r\n|\n|\r)/gm, " ");  // remove white space
    return code;
  },

  generateUUID() {
    return uuidv4();
  },

  assert(condition, msg) {
    if (!condition) {
      console.log("assertion.failed: " + msg);
      console.trace();
      throw new Error(msg || "Assertion failed");
    }
  },

  async asyncTimeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  },

  isJson(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false;
    }
    return true;
  },

  // Blockly
  xmlToWorkspace(workspace, xml) {
    Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(xml), workspace);
    return workspace;
  },

  workspaceToCode(workspace) {
    return BlocklyJS.workspaceToCode(workspace);
  },

  xmlToCode(xml) {
    let workspace = new Blockly.Workspace();
    workspace.clear();
    const res = UTILS.workspaceToCode(UTILS.xmlToWorkspace(workspace, xml));
    workspace.dispose();
    return res;
  },

  filterIds(str) {
    let res = "";
    let inIDdef = false;
    for (let i = 0; i < str.length; i++) {
      let ch = str.charAt(i);
      if (!inIDdef) {
        if (str.slice(i, i + 4) === 'id="') {
          inIDdef = true;
          i += 3;
        } else {
          res = res.concat(ch);
        }
      } else if (ch === '"')
        inIDdef = false;
    }
    return res;
  },

  /** @function
   * @name componentUUIDToVarSnippetUUID
   * @param {string} componentUUID
   * @description Given a snippetUUID for a UI component such as TEXT_FIELD, return the matching
   * var snippet UUID.
   * @returns {string}.
   */
  componentUUIDToVarSnippetUUID(componentUUID) {
    return `${componentUUID}_VAR`;
  },

  setDataVariableBlockType(varName) {
    return `setDataVariable_${varName}`;
  },

  declarationSnippetUUIDToImplementationBlockType(snippetUUID) {
    return snippetUUID + "_IMPL";
  },

  declarationSnippetUUIDToJSSnippetBlockType(snippetUUID) {
    return snippetUUID + "_CODE";
  },

  isSnippetImplementationBlockType(snippetUUID) {
    return snippetUUID.slice(-5) === '_IMPL';
  },

  implementationBlockTypeToDeclarationSnippetUUID(blockType) {
    UTILS.assert(blockType.length > 5);
    return blockType.slice(0, -5);
  },

  /** @function
   * @name loadComponentToGlobal
   * @param {string} snippetUUID The UUID of the snippet that is to be loaded into the global
   * context.
   * @description Wrapper for {@link module:UTILS#loadComponentSnippetToGlobal}
   */
  loadComponentToGlobal(snippetUUID) {
    UTILS.assert(typeof snippetUUID === 'string');
    const snippet = getSnippet(snippetUUID);
    UTILS.loadComponentSnippetToGlobal(snippet);
  },

  /** @function
   * @name loadComponentSnippetToGlobal
   * @param {Snippet} snippet The snippet that is to be loaded into the global
   * context.
   *
   * @description Given a snippet that describes a react component, generate the code it represents,
   * transform it and add it to the global context.
   */
  loadComponentSnippetToGlobal(snippet) {
    const snippetUUID = snippet.snippetUUID;
    UTILS.assert(typeof snippetUUID === "string", snippetUUID);
    const jsCode = snippet.content;
    const transformed = UTILS.evaluateTSX(jsCode).replace(/(\r\n|\n|\r)/gm, " ")
        .replace('__SNIPET_UUID_PLACEHOLDER__', snippetUUID);
    try {
      global[snippetUUID] = (new Function(`return (() =>` +
          `{return reactRedux.connect(BaseComponent.mapStateToProps, ` +
          `BaseComponent.mapDispatchToProps) (${transformed} );})();`)());
      // Important! Connect should be called only on derived classes and not in base class.
      // See: https://stackoverflow.com/questions/53497025/react-redux-and-inheritance
    } catch (e) {
      console.log(e);
      UTILS.assert(false, JSON.stringify({snippetUUID, transformed}));
    }
  },

  /** @function
   * @name getServerURL
   * @description If URL is passed as a variable through docker compose use it, else use the one
   * passed as a local environment variable. <br>
   * The use of 2 variables allows us to run locally without (or through) Docker.
   */
  getServerAPIURL() {
    UTILS.assert(process.env);
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    const REACT_APP_DOCKER_COMPOSE_SERVER_URL = process.env.REACT_APP_DOCKER_COMPOSE_SERVER_URL;
    const serverURL = REACT_APP_DOCKER_COMPOSE_SERVER_URL ? REACT_APP_DOCKER_COMPOSE_SERVER_URL :
        REACT_APP_SERVER_URL;
    UTILS.assert(serverURL);
    return serverURL;
  },

  getPublishURL() {
    UTILS.assert(process.env);
    const REACT_APP_DOCKER_COMPOSE_CLIENT_URL = process.env.REACT_APP_DOCKER_COMPOSE_CLIENT_URL;
    const REACT_APP_CLIENT_URL = process.env.REACT_APP_CLIENT_URL;
    const publishURL = REACT_APP_DOCKER_COMPOSE_CLIENT_URL ? REACT_APP_DOCKER_COMPOSE_CLIENT_URL :
        REACT_APP_CLIENT_URL;
    UTILS.assert(publishURL);
    return publishURL;
  },

  /** @function
   * @name getColorPalette
   * @returns {ColorPalette}.
   */
  getColorPalette() {
    return CONSTANTS.CLASSIC_COLOR;
  },

  getColorForSnippetType(snippetType) {
    switch (snippetType) {
      case SnippetTypes.ACTION:
        return UTILS.getColorPalette().ACTION;
      case SnippetTypes.HTML:
        return UTILS.getColorPalette().HTML;
      case SnippetTypes.DATA:
        return UTILS.getColorPalette().DATA;
      case SnippetTypes.STYLE:
        return UTILS.getColorPalette().STYLE;
      case SnippetTypes.REACT_COMPONENT:
        return UTILS.getColorPalette().UI;
      case SnippetTypes.PROJECT_ROOT:
        return UTILS.getColorPalette().EXAMPLES;
      default:
        UTILS.assert(false, snippetType);
    }
  },

  renderComponent(snippetUUID, params) {
    UTILS.assert(typeof snippetUUID === 'string');
    let jsCode = "function render() { return <" + snippetUUID + "></" + snippetUUID + ">;}";
    let transformed = UTILS.evaluateTSX(jsCode).replace(/(\r\n|\n|\r)/gm, " ");
    return (new Function(transformed + "return render();"))();
  }
};

export default UTILS;

import thunk from "redux-thunk";
import {applyMiddleware, createStore} from "redux";
import createSagaMiddleware from 'redux-saga'
import arenaSaga from "./redux/sagas/arenaSaga";
import combinedReducer from "./redux/reducers/reducers.ts";
import {composeWithDevTools} from "redux-devtools-extension/developmentOnly";

// The store is exported so that can accessed from pure functions, and not just via react
// components. Copied from https://daveceddia.com/access-redux-store-outside-react/
// Thunk is used for async actions.
// Devtools support both chrome and Firefox extensions.
// See: https://github.com/zalmoxisus/redux-devtools-extension

export function createArenaStore() {
  const sagaMiddleware = createSagaMiddleware();
  const middleware = [thunk, sagaMiddleware];
  const res = createStore(combinedReducer, composeWithDevTools(applyMiddleware(...middleware),));
  sagaMiddleware.run(arenaSaga);
  return res;
}

const store = createArenaStore();
export default store;

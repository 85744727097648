import Snippet from "../../common/Snippet";
import UTILS from "../../common/utils";
import {
  LoadingState,
  WorkAreaPageState
} from "../reducers/workAreaPageStateReducer";

export const setWorkAreaPageState = (workAreaPageState: WorkAreaPageState) => ({
  type: "SET_WORK_AREA_PAGE_STATE",
  payload: {workAreaPageState}
});

export const setWorkAreaLoadingState = (loadingState: LoadingState,
                                        loadingProgress: number) => ({
  type: "SET_WORK_AREA_LOADING_STATE",
  payload: {loadingState, loadingProgress},
});

export const closeWorkAreaPageDrawer = () => ({
  type: "CLOSE_WORK_AREA_PAGE_DRAWER",
});

export const setIsSnippetEditorOpen = (value: boolean) => ({
  type: "SET_IS_SNIPPET_EDITOR_OPEN",
  payload: {value}
});

export const setWYSIWYGEditorContentContent = (content: boolean,
                                               jsPrettify: true = true) => ({
  type: "SET_WYSIWYG_EDITOR_CONTENT_CONTENT",
  payload: {content, jsPrettify}
});

export const setWYSIWYGEditorXMLContent = (content: string) => ({
  type: "SET_WYSIWYG_EDITOR_XML_CONTENT",
  payload: {content}
});

export const setUpdateUncontrolledBlocklyEditor = (value: boolean) => ({
  type: "SET_UPDATE_UNCONTROLLED_BLOCKLY_EDITOR",
  payload: {value}
});

export function clearStackAndPushUUID(snippetUUID: string) {
  return {
    type: "CLEAR_STACK_AND_PUSH_SNIPPET_UUID",
    payload: {snippetUUID}
  }
}

export function pushSnippetUUIDToStack(snippetUUID: string) {
  return {
    type: "PUSH_SNIPPET_UUID_TO_STACK",
    payload: {snippetUUID}
  }
}

export const moveBackInStack = () => ({
  type: "MOVE_BACK_IN_STACK"
});

export const moveForwardInStack = () => ({
  type: "MOVE_FORWARD_IN_STACK"
});

export const updateWorkAreaPageStateFromSnippet = (snippet: Snippet) => {
  UTILS.assert(snippet !== undefined);
  return {
    type: "UPDATE_WORK_AREA_PAGE_STATE_FROM_SNIPPET",
    payload: {snippet}
  }
};

export const setShouldRenderCurrentSnippet = (value: boolean) => ({
  type: "SET_SHOULD_RENDER_CURRENT_SNIPPET",
  payload: {value}
});

export const setIsControllerDisabled = (value: boolean) => ({
  type: "SET_IS_CONTROLLER_DISABLED",
  payload: {value}
});

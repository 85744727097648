import * as Blockly from "blockly/core";
import extractSnippetUUIDFromCode from "../../extractSnippetUUIDFromCode";
import UTILS from "../../../common/utils";
import generateStyleCode from "../../generateStyleCode";

export function tabsImplementation(block) {
  // @ts-ignore
  let allTabsJsonLike = Blockly.JavaScript.statementToCode(block, 'allTabs');
  const leftSideComponents = Blockly.JavaScript.statementToCode(block,'left_side_components');
  const additionalComponents = Blockly.JavaScript.statementToCode(block,'additional_components');
  allTabsJsonLike = '[' + allTabsJsonLike.slice(0, -1) + ']';
  let varName = Blockly.JavaScript.statementToCode(block, 'active_tab');
  const allTabs = JSON.parse(allTabsJsonLike);
  const labelsArray = allTabs.map(e => e.label);
  const contentArray = allTabs.map(e => e.content);
  const varSnippetUUID = extractSnippetUUIDFromCode(varName);
  let code = "class TabsImplementation extends BaseComponent {";
  code += "Render() {";
  code += "this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';";
  code += "const self = this;";
  code += generateStyleCode(block);
  code += `const labelsColor = style.color ?  style.color : '#FFF';`;
  code += `const backgroundColor = style.backgroundColor ?  style.backgroundColor : '#3453E2';`;
  code += "const StyledTabs = withStyles({";
  code += `indicator: {backgroundColor: "${UTILS.getColorPalette().EXAMPLES}"}, `;
  code += "root: { style}})(Tabs);";
  code += "const StyledAppBar = withStyles({root: {style}})(AppBar);";
  code += `let selectedTab = 0;`;
  code +=
      `if ("${varSnippetUUID}") {selectedTab= context.evaluateDataSnippet("${varSnippetUUID}");};`;
  code += `selectedTab = isNaN(selectedTab)? 0 : selectedTab;`;
  code +=
      `if (selectedTab) {selectedTab= context.evaluateDataSnippet("${varSnippetUUID}");};`;
  code += `return <><StyledAppBar style={{ background:backgroundColor}} position={"static"}  ref={self.componentReference}>`;
  code += `<ToolBar>`;
  code += `<div style={{
    display: 'flex',
    alignItems: "center",
    width: "100%",
  }}>`;
  code += `${leftSideComponents}`;
  code += `<StyledTabs style={{color:labelsColor}} variant="scrollable" value={selectedTab}`;
  code +=
      ` onChange={(e, selectedTab) => {self.clearAllMasks(); context.setDataSnippet("${varSnippetUUID}", selectedTab);}}`;
  code += `>`;
  for (let label of labelsArray)
    code += `  <Tab label=${label}/>`;
  code += `</StyledTabs>`;
  code += `<div style={{flexGrow: 1}}/>${additionalComponents}</div></ToolBar></StyledAppBar>`;
  for (let i = 0; i < contentArray.length; i++)
    code += `{selectedTab===${i} && ${contentArray[i]}} `;
  code += `</>}}`;
  return code;
}

export function muiTab(block) {
  let content = Blockly.JavaScript.statementToCode(block, 'content');
  let label = Blockly.JavaScript.statementToCode(block, 'label',
      Blockly.JavaScript.ORDER_ATOMIC);
  label = label ? label : '""';
  content = `<>${content}</>`;
  return JSON.stringify({content, label}) + ',';
}

import * as Blockly from "blockly/core";
import extractSnippetUUIDFromCode from '../../extractSnippetUUIDFromCode';
import generateStyleCode from "../../generateStyleCode";

export function radioButtonsImplementation(block) {
  const title = Blockly.JavaScript.statementToCode(block, 'title');
  const {labelsCode, labelsValues} = (parseLabels.bind(this))();
  const labels = labelsCode.join('');
  let varName = Blockly.JavaScript.statementToCode(block, 'variable');
  const properties = Blockly.JavaScript.statementToCode(block, 'properties');
  const varSnippetUUID = extractSnippetUUIDFromCode(varName);
  let code = `class TextEditorImplementation extends BaseComponent {`;
  code += `render() {`;
  code += `let title = context.evaluateFJSONExpressionWithRedux(${title}) || '';`;
  code += `title = title.replace(/^"(.*)"$/, '$1');`;
  code += `let activeButtonValue = null;`;
  code += `if ("${varSnippetUUID}") {`;
  code += `activeButtonValue = context.evaluateDataSnippet("${varSnippetUUID}");`;
  if (labelsValues && labelsValues.length > 0) {
    code += `if(${JSON.stringify(labelsValues)}.indexOf(activeButtonValue)=== -1) {`;
    code += `  context.setDataSnippet("${varSnippetUUID}", ${JSON.stringify(labelsValues)}[0]);`;
    code += `}`;
  }
  code += `}`;
    code += generateStyleCode(block);
  code += `const color = style.color ||  '#3453E2';`;
  code += `const backgroundColor = style.backgroundColor ||  '#3453E2';`;
  code += `delete style["color"];`;
  code += `delete style["backgroundColor"];`;
  code += `const StyledRadio = withStyles({`;
  code += `root: {`;
  code += `},`;
    code += `colorSecondary: { color: backgroundColor, '&$checked': { color}},`;
  code += ` checked: {}  
    })(Radio);`;
  code += `return <FormControl><FormLabel id="demo-radio-buttons-group-label">`;
  code += `{title}</FormLabel>`;
  code += `<RadioGroup row name="radio-buttons-group"`;
  if (varSnippetUUID) {
    code +=
        ` onChange={(event) =>  {context.setDataSnippet("${varSnippetUUID}", event.target.value);}}`;
  }
  code += ` value={activeButtonValue}>`;
  code += ` ${properties}`;
  code += labels;
  code += `</RadioGroup>`;
  code += `</FormControl>}}`;
  return code;
}

export function radioButtonLabel(block) {
  const label = Blockly.JavaScript.statementToCode(block, 'label');
  return label ? `<FormControlLabel value=${label} control={<StyledRadio />} label=${label}/>` : '';
}

function parseLabels() {
  let labelsCode = [];
  let labelsValues = [];
  let currentBlock = this.getInputTargetBlock('labels');
  while (currentBlock) {
    const labelValue = Blockly.JavaScript.statementToCode(currentBlock, 'label').trim();
    if (labelValue) {
      labelsCode.push(Blockly.JavaScript[currentBlock.type].call(currentBlock, currentBlock));
      labelsValues.push(JSON.parse(labelValue));
    }
    currentBlock = currentBlock.getNextBlock();
  }
  return {labelsCode, labelsValues};
}

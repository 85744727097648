// @ts-ignore
import _CloneDeep from 'lodash/cloneDeep';
// @ts-ignore
import {ContentState, EditorState, convertFromHTML} from "draft-js";
import CONSTANTS from "../../common/Constants";
import SnippetTypes from "../../common/SnippetTypes";
import UTILS from "../../common/utils";
import prettier from "prettier/standalone";
import babylon from "prettier/parser-babel";
import Snippet from "../../common/Snippet";

const beautify = require('js-beautify').js;

export enum LoadingState {
  BLANK = 'BLANK',
  INITIALIZED = 'INITIALIZED',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
}

function prettifyXMLContent(str: string) {
  if (CONSTANTS.FILTER_IDS)
    str = UTILS.filterIds(str);
  return str;
}

function prettifyJSContent(str: string) {
  if (CONSTANTS.JS_BEAUTIFY)
    str = beautify(str);
  if (CONSTANTS.JS_PRETTIER)
    str = prettier.format(str, {
      parser: "babel",
      plugins: [babylon]
    });
  return str;
}

function isTransitionLegal(prev: LoadingState,
                           current: LoadingState): boolean {
  function stringifyPair(s0: LoadingState, s1: LoadingState): string {
    return JSON.stringify({s0, s1});
  }

  const legalTransitions = new Set();
  legalTransitions.add(stringifyPair(LoadingState.BLANK, LoadingState.BLANK));
  legalTransitions.add(stringifyPair(LoadingState.BLANK, LoadingState.INITIALIZED));
  legalTransitions.add(stringifyPair(LoadingState.INITIALIZED, LoadingState.LOADING));
  legalTransitions.add(stringifyPair(LoadingState.LOADING, LoadingState.LOADING));
  legalTransitions.add(stringifyPair(LoadingState.LOADING, LoadingState.LOADED));
  legalTransitions.add(stringifyPair(LoadingState.LOADED, LoadingState.BLANK));
  return legalTransitions.has(stringifyPair(prev, current));
}

export interface WorkAreaPageState {
  // Render project only if project was built once or is not developer
  actuallyRenderProject: boolean,
  blocklyEditorOpen: boolean,
  focusedInputComponent: any,
  isDrawerOpen: boolean,
  isSnippetEditorOpen: boolean,
  loadingState: LoadingState,
  loadingProgress: number,
  shouldRenderCurrentSnippet: boolean,
  snippetUUIDStack: Array<string>,
  snippetUUIDStackEnd: number,
  updateUncontrolledBlocklyEditor: boolean,
  WYSIWYGIsContentContent: boolean,
  WYSIWYGEditorOpen: boolean,
  WYSIWYGEditorContentState: any,
  WYSIWYGEditorXMLState: any
}

const initWorkAreaPageState: WorkAreaPageState = {
  actuallyRenderProject: true,  // Set false to avoid automatic rendering
  blocklyEditorOpen: true,
  focusedInputComponent: undefined,  // Ugly fix for MUI TextField losing focus.
  isDrawerOpen: false,
  isSnippetEditorOpen: true,
  loadingState: LoadingState.BLANK,
  loadingProgress: 0,
  snippetUUIDStack: [],
  snippetUUIDStackEnd: 0,  // Index of end of stack.
  shouldRenderCurrentSnippet: false,
  updateUncontrolledBlocklyEditor: true,
  WYSIWYGIsContentContent: true,  // otherwise xml
  WYSIWYGEditorOpen: false,
  WYSIWYGEditorContentState: undefined,
  WYSIWYGEditorXMLState: EditorState.createWithContent(
      ContentState.createFromText("Hello XML"))
};

const workAreaPageStateReducer = (workAreaPageState = initWorkAreaPageState, action: any)
    : WorkAreaPageState => {
  switch (action.type) {
    case 'PUSH_SNIPPET_UUID_TO_STACK': {
      let snippetUUIDStack = _CloneDeep(workAreaPageState.snippetUUIDStack);
      snippetUUIDStack = snippetUUIDStack.slice(0, workAreaPageState.snippetUUIDStackEnd);
      snippetUUIDStack.push(action.payload.snippetUUID);
      const snippetUUIDStackEnd = workAreaPageState.snippetUUIDStackEnd + 1;
      UTILS.assert(snippetUUIDStackEnd <= snippetUUIDStack.length);
      return {
        ...workAreaPageState,
        ...{
          snippetUUIDStack,
          snippetUUIDStackEnd,
          updateUncontrolledBlocklyEditor: true
        },
      };
    }
    case 'CLEAR_STACK_AND_PUSH_SNIPPET_UUID': {
      return {
        ...workAreaPageState, ...{
          snippetUUIDStack: [action.payload.snippetUUID],
          snippetUUIDStackEnd: 1,
          updateUncontrolledBlocklyEditor: true
        }
      };
    }
    case 'MOVE_BACK_IN_STACK': {
      const snippetUUIDStackEnd = workAreaPageState.snippetUUIDStackEnd === 0 ? 0 :
          workAreaPageState.snippetUUIDStackEnd - 1;
      UTILS.assert(snippetUUIDStackEnd > 0);
      return {
        ...workAreaPageState, ...{
          snippetUUIDStackEnd, blocklyEditorOpen: true,
          WYSIWYGEditorOpen: false,
          updateUncontrolledBlocklyEditor: true
        }
      };
    }
    case 'MOVE_FORWARD_IN_STACK': {
      const snippetUUIDStackEnd = workAreaPageState.snippetUUIDStackEnd + 1;
      UTILS.assert(snippetUUIDStackEnd > 0);
      return {...workAreaPageState, ...{snippetUUIDStackEnd}};
    }
    case 'SET_SHOULD_RENDER_CURRENT_SNIPPET':
      return {
        ...workAreaPageState, ...{
          shouldRenderCurrentSnippet: action.payload.value,
          updateUncontrolledBlocklyEditor: true,
        }
      };
    case 'SET_WORK_AREA_LOADING_STATE':
      UTILS.assert(isTransitionLegal(workAreaPageState.loadingState,
          action.payload.loadingState),
          `${workAreaPageState.loadingState} => ${action.payload.loadingState}`);
      if (action.payload.loadingState === LoadingState.BLANK)
        return initWorkAreaPageState;
      return {...workAreaPageState, ...action.payload};
    case 'SET_WORK_AREA_PAGE_STATE':
      return {...workAreaPageState, ...action.payload.workAreaPageState};
    case 'CLOSE_WORK_AREA_PAGE_DRAWER':
      return {...workAreaPageState, ...{isDrawerOpen: false}};
    case 'SET_IS_SNIPPET_EDITOR_OPEN':
      return {
        ...workAreaPageState, ...{
          isSnippetEditorOpen: action.payload.value
        }
      };
    case 'SET_IS_DEPLOYED_SESSION':
      return {
        ...workAreaPageState, ...{
          actuallyRenderProject: action.payload.isDeployedSession ?
              true : workAreaPageState.actuallyRenderProject,
          isSnippetEditorOpen: !action.payload.isDeployedSession
        }
      };
    case 'UPDATE_WORK_AREA_PAGE_STATE_FROM_SNIPPET':
      return action.payload.snippet ?
          {
            ...workAreaPageState,
            ...(workAreaPageStateFromSnippet(action.payload.snippet))
          } :
          workAreaPageState;
    case 'SET_WYSIWYG_EDITOR_CONTENT_CONTENT': {
      let state = undefined;
      if (action.payload.jsPrettify) {
        const content: string = prettifyJSContent(action.payload.content) || "";
        state = ContentState.createFromText(content);
      } else {
        const blocksFromHTML = convertFromHTML(action.payload.content);
        state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap,
        );
      }
      return {
        ...workAreaPageState,
        ...{
          WYSIWYGEditorContentState: EditorState.createWithContent(state)
        }
      }
    }
    case 'SET_WYSIWYG_EDITOR_XML_CONTENT':
      return {
        ...workAreaPageState,
        ...{
          WYSIWYGEditorXMLState: EditorState.createWithContent(
              ContentState.createFromText(prettifyXMLContent(action.payload.content)))
        }
      };
    case 'SET_UPDATE_UNCONTROLLED_BLOCKLY_EDITOR':
      return {
        ...workAreaPageState, ...{
          updateUncontrolledBlocklyEditor: action.payload.value
        }
      };
    default:
      return workAreaPageState;
  }
};

function workAreaPageStateFromSnippet(snippet: Snippet) {
  let res: {};
  UTILS.assert(Object.values(SnippetTypes).includes(snippet.type), snippet.type);
  const jsContent = [SnippetTypes.ACTION, SnippetTypes.REACT_COMPONENT,
    SnippetTypes.PROJECT_ROOT].includes(snippet.type);
  res = workAreaPageStateFromBlockBasedSnippet(snippet, jsContent);
  if (!snippet.isContentGeneratedFromXml) {
    // @ts-ignore
    res.WYSIWYGIsContentContent = true;
    // @ts-ignore
    res.blocklyEditorOpen = false;
    // @ts-ignore
    res.WYSIWYGEditorOpen = true;
  }
  return res;
}

function workAreaPageStateFromBlockBasedSnippet(snippet: Snippet,
                                                jsContent: boolean):
    { WYSIWYGEditorContentState: any; WYSIWYGEditorXMLState: any } {
  const content = jsContent ? prettifyJSContent(snippet.content) :
      snippet.content;
  const WYSIWYGEditorContentState = EditorState.createWithContent(
      ContentState.createFromText(content));
  const WYSIWYGEditorXMLState =
      EditorState.createWithContent(
          ContentState.createFromText(snippet.isContentGeneratedFromXml ?
              prettifyXMLContent(snippet.xml) : "This snippet is unlocked!"));
  return {
    WYSIWYGEditorContentState,
    WYSIWYGEditorXMLState
  }
}

export default workAreaPageStateReducer;

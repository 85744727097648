const GoogleGMailApiV1 = {

  async send(gapi, to, from, subject, message) {
    const str = [
      'Content-Type: text/html; charset="UTF-8"\n',
      "MINE-Version: 1.0\n",
      "Content-Transfer-Encoding: 7bit\n",
      `to: ${to} \n`,
      `from: ${from}\n`,
      `subject: ${subject}\n\n`,
      message
    ].join("");
    const raw = new Buffer.from(str)
        .toString("base64")
        .replace(/\+/g, "-")
        .replace(/\//g, "_");
    let res = undefined;
    var request = gapi.client.gmail.users.messages.send({
          userId: "me",
          resource: {
            raw: raw
          }
        }
    );
    try {
      res = new Promise((resolve, reject) => {
        request.execute((resp) => resolve(resp))
      });
    } catch (e) {
      console.log("ERROR", {e});
    }
    return res;
  },

    async sendHTML(gapi, to, from, subject, message) {
    const str = [
      'Content-Type: text/html; charset="UTF-8"\n',
      "MINE-Version: 1.0\n",
      "Content-Transfer-Encoding: base64\n",
      `to: ${to} \n`,
      `from: ${from} \n`,
      `subject: =?UTF-8?B?${subject}?= \n\n`,
        "<html><body>",
      message,
        "</html></body>"
    ].join("");
    const raw = new Buffer.from(str)
        .toString("base64")
        .replace(/\+/g, "-")
        .replace(/\//g, "_");
    let res = undefined;
    var request = gapi.client.gmail.users.messages.send({
          userId: "me",
          resource: {
            raw: raw
          }
        }
    );
    try {
      res = new Promise((resolve, reject) => {
        request.execute((resp) => resolve(resp))
      });
    } catch (e) {
      console.log("ERROR", {e});
    }
    return res;
  },
};

export default GoogleGMailApiV1;
/** @module redux/reducers/alertReducer */

import {ReduxAlertAction} from "../actions/actions";

export interface AlertOptions {
  alertContent: string,
  alertDurationMsc: number,
  alertSeverity: string,
  alertTitle: string
}

const initAlertOptions : AlertOptions = {
  alertContent: "",
  alertDurationMsc: 500,
  alertSeverity: "",
  alertTitle: ""
};

/** @function
 * @memberof ReduxReducers
 * @name alertReducer
 * @description  Manage the state variables that describe an alert.
 * component.
 * Handles the SET_ARENA_ALERT_OPTIONS action.
 */
const alertReducer = (arenaAlertOptions: AlertOptions = initAlertOptions,
                      action : ReduxAlertAction) : AlertOptions => {
  switch (action.type) {
    case 'SET_ARENA_ALERT_OPTIONS':
      return {...arenaAlertOptions, ...action.payload.arenaAlertOptions};
    default:
      return arenaAlertOptions;
  }
};

export default alertReducer;

import UTILS from "../common/utils";
import Project from "../common/Project";
import {projectToData} from "./BackendProject";
import store from "../store";

const axios = require('axios').default;

export default class ProjectCRUD {

  static async upsertProject(project: Project) {
    UTILS.assert(store.getState().sessionState.isLoggedIn);
    try {
      await ProjectCRUD.createProject(project)
    } catch (e) {
      await ProjectCRUD.updateProject(project);
    }
  }

  static async createProject(project: Project) {
    UTILS.assert(store.getState().sessionState.isLoggedIn);
    const SERVER_URL = UTILS.getServerAPIURL();
    return axios.post(SERVER_URL + '/projects',
        projectToData(project), {withCredentials: true});
  }

  static async deleteProject(projectUUID: string) {
    UTILS.assert(store.getState().sessionState.isLoggedIn);
    const SERVER_URL = UTILS.getServerAPIURL();
    return axios.delete(SERVER_URL + `/projects/${projectUUID}`,
        {withCredentials: true});
  }

  static async updateProject(project: Project) {
    UTILS.assert(store.getState().sessionState.isLoggedIn);
    const SERVER_URL = UTILS.getServerAPIURL();
    return axios.put(SERVER_URL +
        `/projects/${project.projectUUID}`,
        projectToData(project), {withCredentials: true});
  }
}

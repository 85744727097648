import React from "react";

export default class MarginsToolBox extends React.Component {
  render() {
    return <category name="Margins">
      <block type="set_margin" x="86" y="194">
        <field name="units">%</field>
        <statement name="value">
          <block type="data_primitive_number">
            <field name="value">8</field>
          </block>
        </statement>
      </block>
      <block type="set_margin_top">
      </block>
      <block type="set_margin_bottom">
      </block>
      <block type="set_margin_detailed">
        <field name="units">px</field>
        <statement name="top">
          <block type="data_primitive_number">
            <field name="value">4</field>
          </block>
        </statement>
        <statement name="left">
          <block type="data_primitive_number">
            <field name="value">8</field>
          </block>
        </statement>
        <statement name="bottom">
          <block type="data_primitive_number">
            <field name="value">8</field>
          </block>
        </statement>
        <statement name="right">
          <block type="data_primitive_number">
            <field name="value">8</field>
          </block>
        </statement>
      </block>
    </category>
  }
}
import * as Blockly from "blockly/core";
import generateStyleCode from "../../generateStyleCode";

/** @function
 * @name buttonImplementationGenerator
 * @returns Generate code for the mui_button_implementation block.
 */
export function buttonImplementationGenerator(block) {
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  let currentBlock = this.getInputTargetBlock('action');
  let actionCode = ``;
  while (currentBlock) {
    actionCode += `${Blockly.JavaScript[currentBlock.type].call(currentBlock, currentBlock)};`;
    currentBlock = currentBlock.getNextBlock();
  }
  const properties = Blockly.JavaScript.statementToCode(block, 'properties');
  let code = `class MUIButtonImplementation extends BaseComponent {`;
  code += `Render() {`;
  code += `this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';`;
  code += `const self = this;`;
  code += generateStyleCode(block);
  code += `const StyledButton = withStyles({`;
  code += `root: `;
  code += `style`;
  code += `,})(Button);`;
  code += `return <StyledButton ref={self.componentReference} variant="contained"` +
      ` onClick={(event) => {${actionCode}}} ${properties}>`;
  code += `{context.evaluateFJSONExpressionWithRedux(${content})}</StyledButton>}}`;
  return code;
}

/** @function
 * @name startIconPropertyImplementationGenerator
 * @returns Generate code for the start_icon_property block.
 */
export function startIconPropertyImplementationGenerator(block) {
  const iconType = block.getFieldValue('iconType');
  let code = iconType ? ` startIcon={<${iconType}/>}` : ``;
  return code;
}

/** @function
 * @name endIconPropertyImplementationGenerator
 * @returns Generate code for the end_icon_property block.
 */
export function endIconPropertyImplementationGenerator(block) {
  const iconType = block.getFieldValue('iconType');
  let code = iconType ? ` endIcon={<${iconType}/>}` : ``;
  return code;
}

/** @function
 * @name muiButtonVariantPropertyGenerator
 * @returns Generate code for the mui_button_variant_property block.
 */
export function muiButtonVariantPropertyGenerator(block) {
  const variant = block.getFieldValue('variant');
  let code = variant ? ` variant="${variant}"` : ``;
  return code;
}

import React from 'react';
import {connect} from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box';
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {setAboutForm} from "../../redux/actions/forms/aboutFormActions";
import {AboutFormValue} from "../../redux/reducers/forms/aboutFormReducer";

const useStyles = () => ({
  inline: {
    display: 'inline',
  },
});

type MyProps = {
  isOpen: boolean;
  setAboutForm: any;
};

type MyState = {};

// Responsible for setting the redux state that is associated with this form.
class AboutForm extends React.Component <MyProps, MyState> {

  constructor(props: MyProps) {
    super(props);
    this.state = {
      formError: false,
    };
  }

  // @ts-ignore
  static mapStateToProps(reduxState) {
    return {
      isOpen: reduxState.aboutForm.isOpen
    }
  }

  // @ts-ignore
  static mapDispatchToProps(dispatch) {
    return {
      setAboutForm: (aboutForm: AboutFormValue) => {
        dispatch(setAboutForm(aboutForm));
      },
    }
  }

  render() {
    return <Dialog open={this.props.isOpen}
                   fullWidth
                   onClose={() => this.props.setAboutForm({isOpen: false})}>
      <Box sx={{
        display: 'flex',
        flexDirection: "column",
        m: '12px',
        alignItems: 'center'
      }}>
        <Box sx={{
          mt: '20px',
        }}>
          <Typography variant='h4'>About Coding Arena</Typography>
        </Box>
        <Box sx={{
          mt: '20px',
          mb: '40px',
        }}>
          <Typography variant='h6'>Version 0.0.39</Typography>
        </Box>
      </Box>;
    </Dialog>
  }
}

export default connect(AboutForm.mapStateToProps,
    AboutForm.mapDispatchToProps)(withStyles(useStyles)(AboutForm));

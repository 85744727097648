// @ts-ignore
import draftToHtml from "draftjs-to-html";
// @ts-ignore
import {convertToRaw} from "draft-js";

const initAllVariablesValue = {
  userEditorsState: {},
};

// @ts-ignore
const setVariableValueReducer = (allVariables = initAllVariablesValue, action) => {
  switch (action.type) {
    case 'SET_USER_EDITOR_STATE': {
      const temp = {};
      // @ts-ignore
      temp[action.payload.editorName] = action.payload.state;
      let temp1 = {};
      // @ts-ignore
      temp1[action.payload.editorName] = draftToHtml(convertToRaw(
        action.payload.state.getCurrentContent()));
      return {
        ...temp1,
      ...{userEditorsState : {...{}, ...temp}}
      }
    }
    default:
      return allVariables;
  }
};

export default setVariableValueReducer;

import React from 'react';
import {connect} from "react-redux";
import {withStyles} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from "@material-ui/core/TextField/TextField";
import UTILS from "../../../common/utils";
import {clearModifyProjectForm, setModifyProjectForm} from
      "../../../redux/actions/forms/modifyProjectFormActions";
import {upsertProject} from "../../../redux/actions/persistentStateActions";
import {ModifyProjectFormValue} from
      "../../../redux/reducers/forms/modifyProjectFormReducer";
import Project from "../../../common/Project";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import getProject from '../../../redux/logic/getProject';

type MyProps = {
  isOpen: boolean,
  isProductionSession: boolean;
  modifyProjectForm: ModifyProjectFormValue,
  clearModifyProjectForm: any,
  setModifyProjectForm: any,
  upsertProject: any
};

type MyState = {
  formError: boolean;
};

const useStyles = () => ({
  inline: {
    display: 'inline',
  },
});

class ModifyProjectForm extends React.Component<MyProps, MyState> {

  constructor(props: MyProps) {
    super(props);
    this.state = {
      formError: false,
    };
    this.onFormModify = this.onFormModify.bind(this);
    this.onFormCancel = this.onFormCancel.bind(this);
  }

  // @ts-ignore
  static mapStateToProps(reduxState) {
    return {
      isOpen: reduxState.modifyProjectForm.isOpen,
      isProductionSession: reduxState.sessionState.isProductionSession,
      modifyProjectForm: reduxState.modifyProjectForm,
    }
  }

  // @ts-ignore
  static mapDispatchToProps(dispatch) {
    return {
      clearModifyProjectForm: () => {
        dispatch(clearModifyProjectForm());
      },
      // @ts-ignore
      setModifyProjectForm: (modifyProjectForm) => {
        dispatch(setModifyProjectForm(modifyProjectForm));
      },
      upsertProject: (project: Project) => {
        dispatch(upsertProject(project));
      },
    }
  }

  validateForm() {
    UTILS.assert(this.props.modifyProjectForm);
    if (this.props.modifyProjectForm.name.length === 0) {
      return false;
    }
    return true;
  }

  async onFormModify() {
    if (!this.validateForm()) {
      this.setState({
        formError: true,
      });
    } else {
      const project: Project | null =
          getProject(this.props.modifyProjectForm.projectUUID);
      UTILS.assert(project);
      (project as Project).isPublic = this.props.modifyProjectForm.isPublic;
      (project as Project).imageURL = this.props.modifyProjectForm.imageURL;
      (project as Project).name = this.props.modifyProjectForm.name;
      this.props.upsertProject(project as Project);
      this.props.setModifyProjectForm({
        isOpen: false
      });
      this.props.clearModifyProjectForm();
    }
  }

  onFormCancel() {
    this.props.clearModifyProjectForm();
    this.props.setModifyProjectForm({isOpen: false});
  }

  render() {
    const dialogTitle = 'Project properties';
    const self = this;
    return <Dialog open={this.props.isOpen}
                   fullWidth
                   onClose={self.onFormCancel}>
      <DialogTitle id="form-dialog-title">{dialogTitle}</DialogTitle>
      <DialogContent>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'flex-start',
        }}>
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: "flex-start",
            width: '100%',
            marginRight: '2%',
          }}>
            <TextField
                value={this.props.modifyProjectForm.name}
                error={this.state.formError}
                margin="dense"
                id="name"
                label="Project Name"
                style={{width: '100%'}}
                onKeyPress={(e) => {
                  if (e.key === 'Enter')
                    self.onFormModify();
                }}
                onChange={(event) => {
                  self.onProjectNameChange(event);
                }}
            />
            <TextField
                value={this.props.modifyProjectForm.imageURL}
                error={this.state.formError}
                margin="dense"
                id="image_url"
                label="Image URL"
                style={{width: '100%'}}
                onKeyPress={(e) => {
                  if (e.key === 'Enter')
                    self.onFormModify();
                }}
                onChange={(event) => {
                  self.onImagUrlChange(event);
                }}
            />
           <FormGroup>
              <FormControlLabel
                  label="Is public"
                  control={<Checkbox
                      checked={this.props.modifyProjectForm.isPublic}
                      id="is_public"
                      onChange={(event) => {
                        self.onCheckboxChange(event.target.checked);
                      }}
                  />}
              />
            </FormGroup>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={self.onFormModify} color="primary">Modify</Button>
        <Button onClick={self.onFormCancel} color="primary">Cancel</Button>
      </DialogActions>
    </Dialog>
  }

  onProjectNameChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    this.props.setModifyProjectForm({
      ...this.props.modifyProjectForm,
      ...{'name': event.target.value}
    });
    this.setState({
      formError: false,
    });
  }

  onImagUrlChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    this.props.setModifyProjectForm({
      ...this.props.modifyProjectForm,
      ...{'imageURL': event.target.value}
    });
    this.setState({
      formError: false,
    });
  }

  onCheckboxChange(checked: boolean) {
    this.props.setModifyProjectForm({
      ...this.props.modifyProjectForm,
      ...{'isPublic': checked}
    });
    this.setState({
      formError: false,
    });
  }
}

export default connect(ModifyProjectForm.mapStateToProps,
    ModifyProjectForm.mapDispatchToProps)(withStyles(useStyles)(ModifyProjectForm));

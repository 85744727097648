/**
 * @license
 *
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

/**
 * @fileoverview Blockly React Component.
 * @author samelh@google.com (Sam El-Husseini)
 */

import React from 'react';
import Blockly from 'blockly/core';
import locale from 'blockly/msg/en';
import 'blockly/blocks';
import UTILS from "../common/utils";
import {pluginInfo as ArenaConnectionsPluginInfo} from './ArenaBlockChecker';
import CustomRenderer from "./CustomRenderer";

Blockly.setLocale(locale);

export default class BlocklyComponent extends React.Component {
  constructor(props) {
    super(props);
    this.blocklyDiv = React.createRef();
    this.toolbox = React.createRef();
  }

  get workspace() {
    return this.primaryWorkspace;
  }

  componentDidMount() {
    const {initialXml, children, ...rest} = this.props;
    Blockly.blockRendering.unregister('custom_renderer');
    Blockly.blockRendering.register('custom_renderer', CustomRenderer);
    this.primaryWorkspace = Blockly.inject(
        this.blocklyDiv.current,
        {
          renderer: 'custom_renderer',
          toolbox: this.toolbox.current,
          plugins: {
            ...ArenaConnectionsPluginInfo,
          },
          ...rest
        },
    );
    if (initialXml) {
      Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(initialXml), this.primaryWorkspace);
    }
  }

  setXml(xml) {
    Blockly.Xml.domToWorkspace(Blockly.Xml.textToDom(xml), this.primaryWorkspace);
  }

  render() {
    if (this.workspace && this.workspace.getToolbox()) {
      let toolbox = this.workspace.getToolbox();
      toolbox.setVisible(this.props.displayToolbox);
      for (const [categoryId, content] of Object.entries(this.props.toolBoxItems)) {
        const toolboxItem = this.workspace.getToolbox().getToolboxItemById(categoryId);
        UTILS.assert(toolboxItem, `toolboxItem: ${toolboxItem} categoryId: ${categoryId}`);
        toolboxItem.updateFlyoutContents(content);
      }
      this.workspace.getToolbox().refreshSelection();
    }
    const {children} = this.props;
    return <div style={{height: '100%', width: '100%'}}>
      <div ref={this.blocklyDiv} id="blocklyDiv" style={{height: '100%', width: '100%'}}/>
      <xml xmlns="https://developers.google.com/blockly/xml" is="blockly"
           style={{display: 'none'}} ref={this.toolbox}>
        {children}
      </xml>
    </div>
  }
}

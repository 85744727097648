// @ts-nocheck
import React from "react";
import UTILS from "../../../common/utils";
import ToolboxCategory from "../../../common/ToolboxCategory";

export default class ProjectsToolBox extends React.Component {
  render() {
    return <category name="Projects"
                     toolboxitemid={ToolboxCategory.PROJECTS}
                     colour={UTILS.getColorPalette().EXAMPLES}>
    </category>
  }
}

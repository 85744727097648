/** @module redux/reducers/loginFormReducer */

export interface HelpFormValue {
  isOpen: boolean,
}

const initHelpFormValue: HelpFormValue = {
  isOpen: false,
};

/** @function
 * @memberof ReduxReducers
 * @name helpFormReducer
 * @description Manage all state variables that are used in helpForm.
 * Handles the following action:<br>
 * SET_HELP_FORM.<br>
 */
// @ts-ignore
const helpFormReducer = (createHelpForm : HelpFormValue = initHelpFormValue, action) => {
  switch (action.type) {
    case 'SET_HELP_FORM':
      return {...createHelpForm, ...action.payload.createHelpForm};
    default:
      return createHelpForm;
  }
};

// @ts-ignore
export default helpFormReducer;

import React from "react";
import {Component} from "react";
import UTILS from "../../../common/utils";
import store from "../../../store";
import ChartsToolBox from "./ChartsToolbox";
import MaterialUIToolBox from "./MaterialUIToolBox";
import ReactRouterToolBox from "./ReactRouterToolBox";

export default class UiComponentsToolBox extends Component {
  render() {
    const sessionState = store.getState().sessionState;
    const isProductionSession = sessionState.isProductionSession;
    return <category name="UI" colour={UTILS.getColorPalette().UI}>
      <MaterialUIToolBox/>
      {isProductionSession ? '' : (
          <category name="Other">
            <ChartsToolBox/>
            <ReactRouterToolBox/>
          </category>
      )}
    </category>
  }
}


import * as Blockly from "blockly/core";
import generateStyleCode from "../../generateStyleCode";

/** @function
 * @name muiTypographyImplementation
 * @returns generated MUI Typography component code.
 */
export function muiTypographyImplementation (block) {
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  const variant = block.getFieldValue('variant');
  let code = `class MUITypographyImplementation extends BaseComponent {`;
  code += `Render() {`;
  code += `const self = this;`;
  code += `this.SNIPPET_UUID = '__SNIPPET_UUID_PLACEHOLDER__';`;
  code += generateStyleCode(block);
  code += `const StyledTypography = withStyles({root: style})(Typography);`;
  code += `return <StyledTypography ref={self.componentReference} variant='${variant}' `;
  code += `>`;
  code += `{context.evaluateFJSONExpressionWithRedux(${content})}`;
  code += `</StyledTypography>}}`;
  return code;
}

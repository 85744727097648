import {takeEvery, all} from 'redux-saga/effects';
import {
  injectBlocksSaga, injectBlocksAndUpsertSnippetSaga,
  callWorkAreaPageStateFromCurrentSnippetSaga,
  upsertProjectSaga, upsertSnippetSaga, deleteProjectSaga, upsertSnippetsArraySaga
} from './persistantStateSagas';
import {logOutSaga} from './loginSagas';
import {updateWorkArea} from './workAreaPageStateSaga.ts';

/**
 * @namespace ReduxSagas
 */
function* arenaSaga() {
  yield all([
    // persistentStateSagas
    takeEvery("INJECT_BLOCKS_FOR_SNIPPET", injectBlocksSaga),
    takeEvery("DELETE_PROJECT", deleteProjectSaga),
    takeEvery("UPSERT_SNIPPET", upsertSnippetSaga),
    takeEvery("UPSERT_SNIPPETS_ARRAY", upsertSnippetsArraySaga),
    takeEvery("UPSERT_PROJECT", upsertProjectSaga),
    takeEvery("INJECT_BLOCKS_AND_CREATE_SNIPPET", injectBlocksAndUpsertSnippetSaga),
    takeEvery("PUSH_SNIPPET_UUID_TO_STACK", callWorkAreaPageStateFromCurrentSnippetSaga),
    takeEvery("CLEAR_STACK_AND_PUSH_SNIPPET_UUID",
        callWorkAreaPageStateFromCurrentSnippetSaga),
    takeEvery("MOVE_BACK_IN_STACK", callWorkAreaPageStateFromCurrentSnippetSaga),
    takeEvery("MOVE_FORWARD_IN_STACK", callWorkAreaPageStateFromCurrentSnippetSaga),
    takeEvery("UPSERT_SNIPPET", callWorkAreaPageStateFromCurrentSnippetSaga),
    takeEvery("UPSERT_SNIPPETS_ARRAY", callWorkAreaPageStateFromCurrentSnippetSaga),
    takeEvery("LOG_OUT", logOutSaga),
    // workAreaPageStateSaga
    takeEvery("PUSH_SNIPPET_UUID_TO_STACK", updateWorkArea),
    takeEvery("CLEAR_STACK_AND_PUSH_SNIPPET_UUID", updateWorkArea),
    takeEvery("MOVE_BACK_IN_STACK", updateWorkArea),
    takeEvery("MOVE_FORWARD_IN_STACK", updateWorkArea),
    takeEvery("UPSERT_SNIPPET", updateWorkArea),
    takeEvery("UPSERT_SNIPPETS_ARRAY", updateWorkArea),
  ]);
}

export default arenaSaga;

import React from "react";
import {Block} from "../../../Blockly";
import ToolboxCategory from "../../../common/ToolboxCategory";

export default class ReactRouterToolBox extends React.Component {
  render() {
    return <category name="Router"
                     toolboxitemid={ToolboxCategory.UI_ROUTER}>
      <category name="Params">
        <Block type="react_router"/>
        <Block type="react_router_exact"/>
        <Block type="react_router_to"/>
        <Block type="react_router_path"/>
        <Block type="react_router_link"/>
        <Block type="react_router_route"/>
        <Block type="react_router_switch_declaration"/>
        <Block type="react_router_switch_implementation"/>
      </category>
    </category>
  }
}

import React from 'react';
import {connect} from "react-redux";
import Dialog from '@material-ui/core/Dialog';
import Box from '@material-ui/core/Box'
import {withStyles} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {setHelpForm} from "../../../redux/actions/forms/helpFormActions";
import {HelpFormValue} from "../../../redux/reducers/forms/helpFormReducer";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import LockIcon from '@material-ui/icons/Lock';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PublishIcon from '@material-ui/icons/Publish';

const useStyles = () => ({
  inline: {
    display: 'inline',
  },
});

type MyProps = {
  isOpen: boolean;
  isProductionSession: boolean;
  setHelpForm: any;
};

type MyState = {};

// Responsible for setting the redux state that is associated with this form.
class HelpForm extends React.Component <MyProps, MyState> {

  constructor(props: MyProps) {
    super(props);
    this.state = {
      formError: false,
    };
  }

  // @ts-ignore
  static mapStateToProps(reduxState) {
    return {
      isOpen: reduxState.helpForm.isOpen,
      isProductionSession: reduxState.sessionState.isProductionSession
    }
  }

  // @ts-ignore
  static mapDispatchToProps(dispatch) {
    return {
      setHelpForm: (helpForm: HelpFormValue) => {
        dispatch(setHelpForm(helpForm));
      },
    }
  }

  render() {
    const isProductionSession = this.props.isProductionSession;
    return <Dialog open={this.props.isOpen}
                   fullWidth
                   onClose={() => this.props.setHelpForm({isOpen: false})}>
      <Box sx={{
        display: 'flex',
        flexDirection: "column",
        m: '12px',
        alignItems: 'center'
      }}>
        <Box sx={{
          mt: '20px',
        }}>
          <Typography variant='h5'>All you need to know</Typography>
        </Box>
        <Box sx={{
          mt: '20px',
          mb: '40px',
        }}>
          {!isProductionSession &&
          <Typography variant='h6'>Build + Publish</Typography>}
          {isProductionSession && <Typography variant='h6'>Build</Typography>}
          <Box sx={{display: 'flex', flexDirection: "row",}}>
            <PlayArrowIcon/><Typography variant='body1'> - Build
            project</Typography>
          </Box>
          {!isProductionSession &&
          <Box sx={{display: 'flex', flexDirection: "row",}}>
            <PublishIcon/><Typography variant='body1'> - Publish
            project</Typography>
          </Box>}
          {!isProductionSession &&
          <Box sx={{display: 'flex', flexDirection: "row",}}>
            <LockIcon/><Typography variant='body1'> - Edit snippet
            as code rather than blocks</Typography>
          </Box>}
          <br/>
          <Typography variant='h6'>Navigation</Typography>
          <Typography variant='body1'><b>double-click a block with a dark border</b> - Expands
            the clicked block</Typography>
          {!isProductionSession &&
          <Typography variant='body1'><b> ⌥ + mouse-click on snippet declaration block</b> - Expands
            the clicked block</Typography>}
          <Box sx={{display: 'flex', flexDirection: "row",}}>
            <ArrowBackIcon/><Typography variant='body1'> - Navigate back one
            snippet</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: "row",}}>
            <ArrowForwardIcon/><Typography variant='body1'> - Navigate forward one
            snippet</Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  }
}

export default connect(HelpForm.mapStateToProps,
    HelpForm.mapDispatchToProps)(withStyles(useStyles)(HelpForm));

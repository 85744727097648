import React from "react";
import { Block } from "../../../Blockly";
import ToolboxCategory from "../../../common/ToolboxCategory";
import UTILS from "../../../common/utils";
import store from "../../../store";
import TextToolBox from "./TextToolBox";

export default class ActionsToolBox extends React.Component {
  render() {
    const sessionState = store.getState().sessionState;
    const isProductionSession = sessionState.isProductionSession;
    return <category name="Actions" toolboxitemid={ToolboxCategory.ACTIONS}
      colour={UTILS.getColorPalette().ACTION}>
      {isProductionSession ? '' : (
        <TextToolBox />)}
      {isProductionSession ? '' : (
        <category name="Data" toolboxitemid={ToolboxCategory.ACTION_DATA}>
          <block type="get_from_datagrid_row_action" />
          <block type="get_from_object_action" />
        </category>
      )}
      {isProductionSession ? '' : (
        <category name="Misc">
          <block type="js_comment" />
          {isProductionSession ? '' : (
            <block type="open_window_action">
              <statement name="href">
                <block type="https_link">
                  <field
                    name="content">www.w3schools.com/jsref/tryit.asp?filename=tryjsref_win_open
                  </field>
                </block>
              </statement>
              <statement name="name">
                <block type="open_window_action_name">
                  <field name="name">_blank</field>
                </block>
              </statement>
            </block>
          )}
          {isProductionSession ? '' : (
            <Block type="open_window_action_name" />
          )}
        </category>
      )}
      {isProductionSession ? '' : (
        <category name="Services">
          <category name="Google">
            <category name="Docs">
              <Block type="google_docs_create_file" />
              <Block type="google_docs_batch_update_implementation" />
            </category>
            <category name="Drive">
              <Block type="google_drive_copy_file" />
              <Block type="google_drive_rename_file" />
            </category>
          </category>
        </category>
      )}
    </category>
  }
}

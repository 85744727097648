import SnippetTypes from "../common/SnippetTypes";
import UTILS from "../common/utils";
import downloadProjectSnippets, {downloadSnippetChildrenOfRoot} from "./downloadProjectSnippets";
import Snippet from "../common/Snippet";
import addSnippets, {SnippetTarget} from "../common/addSnippets";
import buildSnippet from "../common/buildSnippet";
import CONSTANTS from "../common/Constants";
import Project from "../common/Project";
import getProject from "../redux/logic/getProject";

export default async function initProjectSnippets(projectUUID: string) {
  const project: Project | null = getProject(projectUUID);
  UTILS.assert(project !== null);
  let projectSnippets: Snippet[] | undefined =
      await downloadProjectSnippets(projectUUID);
  let orderedSnippets: Snippet[] = projectSnippets ? projectSnippets : [];
  // @ts-ignore
  const workspace = global.context.workspace;
  UTILS.assert(workspace);
  const targets = [SnippetTarget.REDUX, SnippetTarget.GLOBAL,
    SnippetTarget.BLOCKLY];
  if (orderedSnippets.length > 0) {
    //let rootSnippet = orderedSnippets[0];
    //rootSnippet.xml = rootSnippet.xml.replace("mui_box_implementation", "mui_root_box_implementation"); 
    orderedSnippets.reverse();
    await addSnippets(orderedSnippets, true, true,
        targets, workspace, null);
  }
}

export async function initTemplateSnippetsFromBackend() {
  const ROOT_DATA_TEMPLATE_SNIPPET = 'AA2E2A53ED2544B0A2A40C876AB4FC60';
  const ROOT_MUI_TEMPLATE_SNIPPET = 'AA2E2A53ED2544B0A2A40C876AB4FC61';
  const ROOT_ACTION_TEMPLATE_SNIPPET = 'AA2E2A53ED2544B0A2A40C876AB4FC62';
  const ROOT_STYLE_SNIPPET = 'AA2E2A53ED2544B0A2A40C876AB4FC63';
  const ROOT_CORE_UI_SNIPPET = 'AA2E2A53ED2544B0A2A40C876AB4FC64';
  const ROOT_CORE_ACTION_SNIPPET = 'F0E667AA6C5748EFB4BE0014DE31B0DF';
  const ROOT_CORE_DATA_SNIPPET = 'AB136BD318E64435A7675357710CEB18';
  await initSnippetChildrenFromBackend(ROOT_MUI_TEMPLATE_SNIPPET, false);
  await initSnippetChildrenFromBackend(ROOT_DATA_TEMPLATE_SNIPPET, true);
  await initSnippetChildrenFromBackend(ROOT_ACTION_TEMPLATE_SNIPPET, false);
  await initSnippetChildrenFromBackend(ROOT_STYLE_SNIPPET, false);
  await initSnippetChildrenFromBackend(ROOT_CORE_UI_SNIPPET, false);
  await initSnippetChildrenFromBackend(ROOT_CORE_ACTION_SNIPPET, false);
  await initSnippetChildrenFromBackend(ROOT_CORE_DATA_SNIPPET, false);
}

async function initSnippetChildrenFromBackend(ROOT_TEMPLATE_SNIPPET: string, generateContent: boolean) {
  let snippetsArray: Snippet[] =
      await downloadSnippetChildrenOfRoot(ROOT_TEMPLATE_SNIPPET) || [];
  snippetsArray = snippetsArray.filter((snippet) => {
    return snippet.snippetUUID !== ROOT_TEMPLATE_SNIPPET
  });
  addSnippets(snippetsArray, generateContent, false,
      [SnippetTarget.REDUX], undefined, null);
}

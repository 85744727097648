import store from "../../store";
import Snippet from "../../common/Snippet";
import UTILS from "../../common/utils";

export default function getCurrentSnippet(storeInstance: any = store):
    Snippet | null {
  let res = null;
  const workAreaPageState = storeInstance.getState().workAreaPageState;
  const snippetUUIDStackEnd = workAreaPageState.snippetUUIDStackEnd;
  const snippetUUIDStack = workAreaPageState.snippetUUIDStack;
  if (snippetUUIDStackEnd > 0 &&
      snippetUUIDStackEnd <= snippetUUIDStack.length) {
    const currentSnippetUUID: string =
        snippetUUIDStack[snippetUUIDStackEnd - 1];
    const allSnippets = storeInstance.getState().persistentState.allSnippets;
    if (currentSnippetUUID in allSnippets)
      res = allSnippets[currentSnippetUUID];
  }
  UTILS.assert(res !== undefined);
  return res;
}

export function getCurrentSnippetUUID(storeInstance: any = store):
    string | null {
  const snippet = getCurrentSnippet(storeInstance);
  return snippet === null ? null : snippet.snippetUUID;
}


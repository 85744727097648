export enum HTTPMethod {
  GET,
  POST,
  PUT,
  DELETE
}

export interface HttpResult {
  status: number,
  json: string
}

export async function httpRequest(httpMethod: HTTPMethod, url: URL,
                                  body = null): Promise<HttpResult> {
  const methodString: string = HTTPMethod[httpMethod];

  interface Init {  // See https://stackoverflow.com/questions/12710905/how-do-i-dynamically-assign-properties-to-an-object-in-typescript
    body?: any,
    method: string,
    headers: any
  }

  let init: Init = {
    method: methodString,
    headers: {'Content-Type': 'application/json'},
  };
  if (body)
    init.body = JSON.stringify(body);
  const response = await fetch(url.toString(), init);
  const json = response.ok ? await response.json() : null;
  return {
    status: response.status,
    json
  };
}

import * as Blockly from "blockly/core";

 export function muiCardImplementation(block) {
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  const title = Blockly.JavaScript.statementToCode(block, 'title');
  const subheader = Blockly.JavaScript.statementToCode(block, 'subtitle');
  const image = Blockly.JavaScript.statementToCode(block,
      'src');
  const actions = Blockly.JavaScript.statementToCode(block, 'actions');
  let code = "class MUICardImplementation extends BaseComponent {";
  code += "Render() {";
  code += "this.SNIPPET_UUID = '__SNIPET_UUID_PLACEHOLDER__';";
  code += "const self = this;";
  code += `const StyledCard = withStyles({root: {}})(Card);`;
  code += `const title = context.evaluateFJSONExpressionWithRedux(${title});`;
  code += `const subheader = context.evaluateFJSONExpressionWithRedux(${subheader});`;
  code += "return <StyledCard ref={self.componentReference}><CardActionArea>";
  code += `<CardMedia `;
  if (image)
    code += `component="img" image=${image} height="140" `;
  code += `/>`;
  code += `<CardContent>${content}</CardContent>`;
  code += "<CardHeader ";
  code += `title = {title}`;
  code += `subheader = {subheader}`;
  code += "></CardHeader>";
  code += `<CardActions>${actions}</CardActions>`;
  code += "</CardActionArea></StyledCard>";
  code += "}}";
  return code;
}

 export function muiCardIMedia(block) {
  const image = Blockly.JavaScript.valueToCode(block,
      'src', Blockly.JavaScript.ORDER_ATOMIC);
  let code = "";
  if (image) {
    code += `component="img" image=${image} `;
    code += `height="140" `;
  }
  return code;
}

import Snippet from "./Snippet";
import store from "../store";
import {
  addItemToToolbox,
  upsertSnippetsArray
} from "../redux/actions/persistentStateActions";
import {injectBlocks} from "../Blockly/BlocklyLogic/injectBlocks";
import UTILS from "./utils";
import BlocklyUtils from "../Blockly/BlocklyLogic/BlocklyUtils";
import generateContentFromSnippet from "../common/generateContentFromSnippet";
import extractSnippetUUIDFromXml from "./extractSnippetUUIDFromXml";
import SnippetTypes from "./SnippetTypes";
import ToolboxCategory from "./ToolboxCategory";

export enum SnippetTarget {
  REDUX = 'REDUX',
  TOOLBOX = 'TOOLBOX',
  BLOCKLY = 'BLOCKLY',
  WORKSPACE = 'WORKSPACE',
  GLOBAL = 'GLOBAL',
}

export default async function addSnippets(snippetsArray: Array<Snippet>,
                                          generateContent: boolean,
                                          extractPrerequisitesFromXml: boolean,
                                          targets: Array<SnippetTarget>,
                                          workspace: any,
                                          toolBoxCategory: ToolboxCategory | null)
    : Promise<Array<string>> {
  const actuallyRenderProject =
      store.getState().workAreaPageState.actuallyRenderProject;
  let newBlockIdArray = new Array<string>();
  for (const snippet of snippetsArray) {
    if (targets.includes(SnippetTarget.BLOCKLY)) {
      injectBlocks(snippet.snippetUUID, snippet.type, snippet.name);
    }
    if (snippet.isContentGeneratedFromXml && extractPrerequisitesFromXml && actuallyRenderProject) {
      const xmlPrerequisites = extractSnippetUUIDFromXml(snippet.xml);
      snippet.prerequisites = xmlPrerequisites;
    }
    if (snippet.isContentGeneratedFromXml && actuallyRenderProject &&
        generateContent) {
      let content: string | undefined = generateContentFromSnippet(snippet);
      if (content === undefined) {
        console.error("Was not able to generateContentFromSnippet", snippet.type);
        console.error(snippet.xml);
        content = '';
      }
      snippet.content = content as string;
    }
    if (targets.includes(SnippetTarget.GLOBAL) && actuallyRenderProject &&
        ((snippet.type === SnippetTypes.REACT_COMPONENT) ||
            (snippet.type === SnippetTypes.PROJECT_ROOT))) {
      UTILS.loadComponentSnippetToGlobal(snippet);
    }
    if (targets.includes(SnippetTarget.WORKSPACE)) {
      newBlockIdArray.push(
          BlocklyUtils.insertBlockInstanceIntoWorkspace(workspace,
              snippet.snippetUUID)[0]);
    }
    if (targets.includes(SnippetTarget.TOOLBOX)) {
      UTILS.assert(toolBoxCategory);
      store.dispatch(addItemToToolbox(snippet.snippetUUID,
          toolBoxCategory as ToolboxCategory));
    }
  }
  if (targets.includes(SnippetTarget.REDUX)) {
    store.dispatch(upsertSnippetsArray(snippetsArray, true));
  }
  return newBlockIdArray;
}

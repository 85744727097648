import React from "react";
import ToolboxCategory from "../../../common/ToolboxCategory";
import UTILS from "../../../common/utils";
import store from "../../../store";

export default class DataToolBoox extends React.Component {

  render() {
    const sessionState = store.getState().sessionState;
    const isProductionSession = sessionState.isProductionSession;
    return <category name="Data" colour={UTILS.getColorPalette().DATA}>
      <category name="Atomic"
        toolboxitemid={ToolboxCategory.DATA_PRIMITIVES} />
      {isProductionSession ? '' : (
        <category name="Image">
          <block type="img_clock" />
          <block type="img_complicated" />
          <block type="img_lizard" />
          <block type="react_logo" />
          <block type="img_surfers" />
          <block type="img_james_webb" />
          <block type="img_arena" />
          <block type="img_codex_shape" />
        </category>
      )}
      {isProductionSession ? '' :
        (<category name="Codex">
          <block type="csv_codex_connections" />
          <block type="csv_codex_features" />
        </category>)}
      {isProductionSession ? '' :
        (<category name="Link">
          <block type="http_link" />
          <block type="https_link" />
        </category>)}
      <category name="Snippets" toolboxitemid={ToolboxCategory.DATA_SNIPPETS}>
      </category>
      <category name="Rich Text" toolboxitemid={ToolboxCategory.STYLED_TEXT_SNIPPETS} />
    </category>
  }
}

import * as Blockly from "blockly/core";
import extractSnippetUUIDFromCode from "../extractSnippetUUIDFromCode";
import generateStyleCode from "../generateStyleCode";

/** @function
 * @name dataGridImplementation
 * @description Generator for the mui_data_grid_implementation block.
 * @returns string
 */
export function dataGridImplementation(block) {
  const variableCode = Blockly.JavaScript.statementToCode(block, 'variable');
  const selectedRowSnippetUUID = extractSnippetUUIDFromCode(variableCode);
  const properties = Blockly.JavaScript.statementToCode(block, 'properties');
  const rows = Blockly.JavaScript.statementToCode(block, 'rows');
  const columns = `[${Blockly.JavaScript.statementToCode(block, 'columns')}]`;
  let action = Blockly.JavaScript.statementToCode(block, 'action');
  let code = "class MUIDataGridImplementation1 extends BaseComponent {";
  code += `Render() {`;
  code +=
    `const rows = context.getDataGridRows(context.evaluateFJSONExpressionWithRedux(${rows}));`;
  code += generateStyleCode(block);
  code += `const StyledDataGrid= withStyles({`;
  code += `root: `;
  code += `style,`;
  code += ` })(DataGrid);`;
  code += `return `;
  code += ` <StyledDataGrid `;
  code += ` rows={rows}`;
  code += ` columns={${columns}}`;
  if (selectedRowSnippetUUID) {
    code += ` onRowClick={(e)=>{`;
    code += `const row = e.row;`;
    code += `context.injectBlocksAndUpsertDataSnippet(row, "${selectedRowSnippetUUID}");`;
    code += action;
    code += `}}`;
  }
  code += `${properties} />`;
  code += `}}`;
  return code;
}

/** @function
 * @name dataGridColumn
 * @description Generator for the mui_data_grid_column block.
 * @returns string
 */
export function dataGridColumn(block) {
  const name = Blockly.JavaScript.statementToCode(block, 'name');
  return `{"field": ${name}, "headerName": ${name}, flex: 1},`;
}

/** @function
 * @name dataGridColumnDetailed
 * @description Generator for the mui_data_grid_column_detailed block.
 * @returns string
 */
export function dataGridColumnDetailed(block) {
  const name = Blockly.JavaScript.statementToCode(block, 'name');
  const content = Blockly.JavaScript.statementToCode(block, 'content');
  let code = `{"field": ${name}, "headerName": ${name}, flex: 1, renderCell:`;
  code += `(params) => {`;
  code += `context.setTempVar(context.reduceObject(params.row, Object.getOwnPropertyNames(params.row)));`;
  code += ` return`;
  code += ` ${content}`;
  code += ` }},`;
  return code;
}

/** @function
 * @name dataGridColumnDetailed
 * @description Generator for the mui_data_grid_column_detailed block.
 * @returns string
 */
export function muiDataGridRowHeightPropertyGenerator(block) {
  const value = Blockly.JavaScript.statementToCode(block, 'value');
  return value ? ` rowHeight={context.evaluateFJSONExpressionWithRedux(${value})}` : ``;
}


import * as Blockly from "blockly/core";

export function styleColor(block) {
  const value = Blockly.JavaScript.statementToCode(block, 'value');
  return value ? `{color:context.evaluateFJSONExpressionWithRedux(${value})}` : '{}';
}

export function styleBackgroundColor(block) {
  const value = Blockly.JavaScript.statementToCode(block, 'value');
  return value ? `{backgroundColor:context.evaluateFJSONExpressionWithRedux(${value})}` : '{}';
}

export function styleColorPicker(block) {
  return JSON.stringify(block.getFieldValue('field_colour'));
}

export function styleGreyPicker(block) {
  return JSON.stringify(block.getFieldValue('field_colour'));
}

import {AreaChart, Area} from 'recharts';
import {BarChart, Bar} from 'recharts';
import {LineChart, Line, CartesianGrid, XAxis, YAxis} from 'recharts';
import {PieChart, Pie} from 'recharts';
import {BrowserView, MobileView} from 'react-device-detect';

export default function loadPackages() {
  global.React = require('react');
  global.reactRedux = require('react-redux');
  global.actions = require('./redux/actions/actions');
  // React Router
  const reactRouterDom = require('react-router-dom');
  global.Switch = reactRouterDom.Switch;
  global.Route = reactRouterDom.Route;
  global.Router = reactRouterDom.BrowserRouter;
  global.Link = reactRouterDom.Link;
  global.alasql = require('alasql');
  global.SizeMe = require('react-sizeme').SizeMe;
  global.withSize = require('react-sizeme').withSize;
  // Misc
  global.ReactHtmlParser = require('react-html-parser').default;
  global.BrowserView = BrowserView;
  global.MobileView = MobileView;
  // Material UI
  global.TextField = require('@material-ui/core/TextField').default;
  global.Typography = require('@material-ui/core/Typography').default;
  global.AppBar = require('@material-ui/core/AppBar').default;
  global.Box = require('@material-ui/core/Box').default;
  global.Button = require('@material-ui/core/Button').default;
  global.Card = require('@material-ui/core/Card').default;
  global.CardHeader = require('@material-ui/core/CardHeader').default;
  global.CardMedia = require('@material-ui/core/CardMedia').default;
  global.CardContent = require('@material-ui/core/CardContent').default;
  global.CardActions = require('@material-ui/core/CardActions').default;
  global.CardActionArea = require('@material-ui/core/CardActionArea').default;
  global.Checkbox = require('@material-ui/core/Checkbox').default;
  global.Dialog = require('@material-ui/core/Dialog').default;
  global.DialogActions = require('@material-ui/core/DialogActions').default;
  global.DialogContent = require('@material-ui/core/DialogContent').default;
  global.DialogContentText = require('@material-ui/core/DialogContentText').default;
  global.DialogTitle = require('@material-ui/core/DialogTitle').default;
  global.Radio = require('@material-ui/core/Radio').default;
  global.RadioGroup = require('@material-ui/core/RadioGroup').default;
  global.Fab = require("@material-ui/core/Fab").default;
  global.FormControl = require('@material-ui/core/FormControl').default;
  global.FormControlLabel = require('@material-ui/core/FormControlLabel').default;
  global.FormLabel = require('@material-ui/core/FormLabel').default;
  global.Grid = require('@material-ui/core/Grid').default;
  global.GridList = require('@material-ui/core/GridList').default;
  global.GridListTile = require('@material-ui/core/GridListTile').default;
  global.GridListTileBar = require('@material-ui/core/GridListTileBar').default;
  global.ImageList = require('@material-ui/core/ImageList').default;
  global.ImageListItem = require('@material-ui/core/ImageListItem').default;
  global.Paper = require('@material-ui/core/Paper').default;
  global.Radio = require('@material-ui/core/Radio').default;
  global.RadioGroup = require('@material-ui/core/RadioGroup').default;
  global.Slider = require('@material-ui/core/Slider').default;
  global.Switch = require('@material-ui/core/Switch').default;
  global.Tab = require('@material-ui/core/Tab').default;
  global.Tabs = require('@material-ui/core/Tabs').default;
  global.ToolBar = require('@material-ui/core/Toolbar').default;
  global.withStyles = require('@material-ui/core/styles').withStyles;
  global.Tooltip = require("@material-ui/core/Tooltip/Tooltip").default;
  global.DataGrid = require("@material-ui/data-grid").DataGrid;
  global.HomeIcon = require("@material-ui/icons/Home").default;
  global.EditIcon = require("@material-ui/icons/Edit").default;
  global.PlayArrowIcon = require("@material-ui/icons/PlayArrow").default;
  global.ClearIcon = require("@material-ui/icons/Clear").default;
  global.CheckIcon = require("@material-ui/icons/Check").default;
  global.SendIcon = require("@material-ui/icons/Send").default;
  global.EmailIcon = require("@material-ui/icons/Email").default;
  global.ThreeDRotation = require("@material-ui/icons/ThreeDRotation").default;
  global.VolumeDown = require("@material-ui/icons/VolumeDown").default;
  global.VolumeUp = require("@material-ui/icons/VolumeUp").default;
  global.AnnouncementIcon = require("@material-ui/icons/Announcement").default;
  global.CodelIcon = require("@material-ui/icons/Code").default;
  global.makeStyles = require('@material-ui/core/styles').makeStyles;
  // Arena
  global.BaseComponent = require('./common/BaseComponent.ts').default;
  global.WYSIWYGEditor = require('./components/ui/WYSIWYGEditor').default;
  // Recharts
  global.CartesianGrid = CartesianGrid;
  global.XAxis = XAxis;
  global.YAxis = YAxis;
  global.AreaChart = AreaChart;
  global.Area = Area;
  global.BarChart = BarChart;
  global.Bar = Bar;
  global.LineChart = LineChart;
  global.Line = Line;
  global.PieChart = PieChart;
  global.Pie = Pie;
}

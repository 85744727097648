import React from "react";
import ToolboxCategory from "../../../../../common/ToolboxCategory";

export default class HTMLDivToolBox extends React.Component {
  render() {
    return <category name="div" toolboxitemid={ToolboxCategory.HTML_DIV}>
      <block type="html_div">
        <statement name="body">
          <block type="string_block">
            <field name="content">Hello World!</field>
          </block>
        </statement>
      </block>
      <block type="html_div_with_style">
        <statement name="style">
        </statement>
        <statement name="body">
          <block type="string_block">
            <field name="content">Hello World!</field>
          </block>
        </statement>
      </block>
    </category>
  }
}
import * as Blockly from "blockly/core";

/** @function
 * @name setMargin
 * @returns a string that once evaluated will hold the margins described by this block.
 */
export function setMargin(block) {
  let value = Blockly.JavaScript.statementToCode(block, 'value') || "0";
  if (!value)
    return '{}';
  return `{margin: (context.evaluateFJSONExpressionWithRedux("${value}") + "${block.getFieldValue(
      'units')}")}`;
}

/** @function
 * @name setMarginDetailed
 * @returns a string that once evaluated will hold the margins described by this block.
 * The underlying block describes equal margins on all 4 sides.
 */
export function setMarginDetailed(block) {
  let top = Blockly.JavaScript.statementToCode(block, 'top') || "0";
  let left = Blockly.JavaScript.statementToCode(block, 'left') || "0";
  let bottom = Blockly.JavaScript.statementToCode(block, 'bottom') || "0";
  let right = Blockly.JavaScript.statementToCode(block, 'right') || "0";
  return `{marginTop: (context.evaluateFJSONExpressionWithRedux("${top}") + "${block.getFieldValue(
      'units')}"),` +
      `marginLeft: (context.evaluateFJSONExpressionWithRedux("${left}") + "${block.getFieldValue(
          'units')}"),` +
      `marginBottom: (context.evaluateFJSONExpressionWithRedux("${bottom}") + "${block.getFieldValue(
          'units')}"),` +
      `marginRight: (context.evaluateFJSONExpressionWithRedux("${right}") + "${block.getFieldValue(
          'units')}")}`;
}

/** @function
 * @name setMarginTop
 * @returns a string that once evaluated will hold the top margin described by this block.
 */
export function setMarginTop(block) {
  let top = Blockly.JavaScript.statementToCode(block, 'top') || "0";
  return `{marginTop: (context.evaluateFJSONExpressionWithRedux("${top}") + "${block.getFieldValue(
      'units')}")}`
}

/** @function
 * @name setMarginBottom
 * @returns a string that once evaluated will hold the bottom margin described by this block.
 */
export function setMarginBottom(block) {
  let bottom = Blockly.JavaScript.statementToCode(block, 'bottom') || "0";
  return `{marginBottom: (context.evaluateFJSONExpressionWithRedux("${bottom}") + "${block.getFieldValue(
      'units')}")}`;
}

// @ts-ignore
import {v4 as uuidv4} from 'uuid';

import addSnippets, {SnippetTarget} from "../../../common/addSnippets";
import buildProject from '../../../common/buildProject';
import Snippet from "../../../common/Snippet";
import Project from "../../../common/Project";
import UTILS from "../../../common/utils";
import {upsertProject} from "../../../redux/actions/persistentStateActions";
import getProject from "../../../redux/logic/getProject";
import {cloneSnippetsArray} from "../../../redux/logic/deepCloneSnippetAndAddToRedux";
import downloadProjectSnippets
  from "../../../server_interface/downloadProjectSnippets";
import ProjectCRUD from "../../../server_interface/ProjectCRUD";
import store from "../../../store";

/** @function
 * @name cloneProject
 * @description
 * Clone a project. Upsert the project and the cloned snippets to
 * redux store and to the Backend.
 */
export default async function cloneProject(clonedProjectName: string,
                                           srcProjectUUID: string) {
  let project: Project | null = getProject(srcProjectUUID);
  UTILS.assert(project, srcProjectUUID);
  const snippets: Array<Snippet> | undefined =
      await downloadProjectSnippets(srcProjectUUID);
  UTILS.assert(snippets, srcProjectUUID);
  const clonedSnippets: Array<Snippet> =
      cloneSnippetsArray((snippets as Array<Snippet>).reverse());
  UTILS.assert(clonedSnippets.length > 0);
  const rootSnippetIndex: number = clonedSnippets.length - 1;
  clonedSnippets[rootSnippetIndex].name =
      clonedSnippets[rootSnippetIndex].name.replace(
          'TEMPLATE_PROJECT_NAME', clonedProjectName);
  await addSnippets((clonedSnippets as Array<Snippet>), false,
      true,
      [SnippetTarget.REDUX], null, null);  // REDUX + BE
  const rootSnippetUUID = clonedSnippets[rootSnippetIndex].snippetUUID;
  const newProject = buildProject(uuidv4(), clonedProjectName, rootSnippetUUID,
  (project as Project).imageURL, false, (project as Project).userUUID);
  store.dispatch(upsertProject(newProject));
}
